/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { FaPlusSquare } from "react-icons/fa";
import { AiFillMinusSquare } from "react-icons/ai";
import { MdUpload } from "react-icons/md";

import { useFieldArray } from "react-hook-form";
import InputField from "../../../InputField";
import { nepaliNumber } from "../../../../_helpers/methods";
import { fetchWrapper } from "../../../../_helpers/fetchWrapper";

const Table = ({
  data,
  language,
  register,
  control,
  setValue,
  value = [],
  category,
  entity,
  isPublic,
}) => {
  const headings = data.data.headings;
  const headingLength = headings.length;
  const [files, setFiles] = useState({});

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: data.id,
  });

  useEffect(() => {
    if (value.length === 0) {
      if (fields.length < (Number(data.data.minRow) || 2)) {
        append({});
      }
    } else {
      if (fields.length < value.length) {
        append({});
      }
    }
  }, [append, data.data.minRow, fields, value]);

  if (isPublic && entity === "private") {
    return null;
  }
  return (
    <div className="w-100 my-2">
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            {headings.map((heading, idx) => (
              <th key={idx}>{heading.name}</th>
            ))}
            <th
              css={css`
                width: 75px;
              `}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {fields.map(({ id, ...rest }, index) => (
            <tr key={id}>
              {Array.from({ length: headingLength }).map((_, fieldIndex) => {
                let elementId = `${data.id
                  }[${index}].${fieldIndex}___${headings[fieldIndex].name
                    .split(".")
                    .join("####")}`;
                let elementNoHashParseId = `${data.id}[${index}].${fieldIndex}___${headings[fieldIndex].name}`;
                let tableDataValue =
                  value[index] &&
                  (value[index][
                    `${fieldIndex}___${headings[fieldIndex].name
                      .split(".")
                      .join("####")}`
                  ] ||
                    value[index][
                    `${fieldIndex}___${headings[fieldIndex].name}`
                    ]);
                return (
                  <td key={fieldIndex} className="p-2 m-0">
                    {headings[fieldIndex].type !== "select" ? (
                      headings[fieldIndex].type === "file" ? (
                        <>
                          <label
                            htmlFor={`file-upload-table-${index}`}
                            role="button"
                            style={{ width: "max-content" }}
                            className="btn btn-primary text-white px-2"
                          >
                            <MdUpload />
                          </label>
                          {files[`file_${index}`] && (
                            <p>{files[`file_${index}`].name}</p>
                          )}
                          <input
                            type="file"
                            hidden
                            id={`file-upload-table-${index}`}
                            onChange={async (e) => {
                              try {
                                const formData = new FormData();
                                formData.append("file", e.target.files[0]);
                                let tempFiles = { ...files };
                                tempFiles[`file_${index}`] = e.target.files[0];
                                setFiles(tempFiles);

                                const response = await fetchWrapper.post(
                                  "/add-documents/",
                                  formData,
                                  true
                                );
                                setValue(
                                  `${elementId}__fileupload__`,
                                  response?.data?.file ??
                                  "https://dev-certification-api.ideabreed.net/media/nep_logo_TyWnpAe_MbrDTkl_ClPUVM0_Rb0wsqR_M2Cc7DC.png"
                                );
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                            accept="image/*"
                          />
                          <input
                            type="text"
                            {...register(`${elementId}__fileupload__`)}
                            hidden
                          />
                        </>
                      ) : fieldIndex === 0 ? (
                        <InputField
                          className="table-input"
                          type={headings[fieldIndex].type}
                          language={language}
                          register={register}
                          elementId={elementId}
                          readOnly
                          value={
                            category === "English Format"
                              ? index + 1
                              : nepaliNumber(index + 1)
                          }
                          setValue={setValue}
                        />
                      ) : (
                        <InputField
                          className="table-input"
                          type={headings[fieldIndex].type}
                          language={language}
                          register={register}
                          elementId={elementId}
                          value={tableDataValue}
                          setValue={setValue}
                        />
                      )
                    ) : (
                      <select {...register(elementNoHashParseId)}>
                        {headings[fieldIndex].options.map((option) => (
                          <option
                            key={option.value}
                            value={option.value}
                            selected={tableDataValue === option.value}
                          >
                            {option.value}
                          </option>
                        ))}
                      </select>
                    )}
                  </td>
                );
              })}
              {index + 1 > (Number(data.data.minRow) || 2) ? (
                <td
                  onClick={() => {
                    swap(index, fields.length - 1);
                    remove(fields.length - 1);
                  }}
                  css={css`
                    cursor: pointer;
                    width: 75px;
                    text-align: center !important;
                    svg {
                      :hover {
                        color: #fc1a66 !important;
                      }
                    }
                  `}
                >
                  <AiFillMinusSquare color="#e91e63" size="28" />
                </td>
              ) : (
                <td></td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {Number(data.data.maxRow) > fields.length && (
        <div
          className="d-flex justify-content-end"
          onClick={() => append({})}
          css={css`
            cursor: pointer;
          `}
        >
          <FaPlusSquare color="blue" size="24" />
        </div>
      )}
    </div>
  );
};

export default Table;
