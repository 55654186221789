import moment from 'moment';
import NepaliDate from 'nepali-date-converter';
import React from 'react';
import { dynamicValidator } from '../../../../_helpers/dynamicValidator';
import InputField from '../../../InputField';

const Date = ({
	label,
	language,
	register,
	errors = {},
	elementId,
	value,
	setValue,
	properties = {},
	isPublic,
	entity,
}) => {
	if (isPublic && entity === 'private') {
		return null;
	}
	let [y, m, d] = ['', '', ''];
	let lang = elementId.split('_')[elementId.split('_').length - 1];
	if (lang === 'en') {
		language = 'english';
	}
	if (elementId?.startsWith('date_today')) {
		if (lang && lang === 'en') {
			[y, m, d] = new moment().format('YYYY/MM/DD').split('/');
			setValue(`${elementId}.year`, y);
			setValue(`${elementId}.month`, m);
			setValue(`${elementId}.day`, d);
		} else {
			[y, m, d] = new NepaliDate().format('YYYY/MM/DD', 'np').split('/');
			setValue(`${elementId}.year`, y);
			setValue(`${elementId}.month`, m);
			setValue(`${elementId}.day`, d);
		}
	}

	const yearValidation = dynamicValidator(
		{ year: true, ...(properties?.year || {}) },

		language
	);

	const monthValidation = dynamicValidator(
		{ month: true, ...(properties?.month || {}) },

		language
	);

	const dayValidation = dynamicValidator(
		{ day: true, ...(properties?.day || {}) },

		language
	);
	return (
		<div className="d-flex align-items-center py-2">
			<label className="mr-2" htmlFor="">
				{label} &nbsp;:{' '}
			</label>
			<div className="input-calendar first">
				<InputField
					type={'number'}
					language={language}
					register={register}
					setValue={setValue}
					elementId={`${elementId}.year`}
					value={value?.year ? value.year : y}
					placeholder="YYYY"
					maxLength="4"
					minLength="4"
					validation={yearValidation}
					className={` date1LOC year   ${properties?.year?.isRequired ? 'textbox_req' : ''}  ${errors[elementId] && errors[elementId]['year'] ? 'is-invalid' : ''
						}`}
					style={{
						width: '58px',
						marginRight: '0.4rem',
					}}
					disabled={properties.readOnly}
				/>
			</div>
			<div className="input-calendar">
				<InputField
					type={'number'}
					language={language}
					register={register}
					setValue={setValue}
					elementId={`${elementId}.month`}
					value={value?.month ? value.month : m}
					placeholder="MM"
					validation={monthValidation}
					maxLength="2"
					minLength="2"
					className={` date1LOC month   ${properties?.month?.isRequired ? 'textbox_req' : ''}  ${errors[elementId] && errors[elementId]['month'] ? 'is-invalid' : ''
						}`}
					style={{
						width: '38px',
						marginRight: '0.4rem',
					}}
					disabled={properties.readOnly}
				/>
			</div>
			<div className="input-calendar">
				<InputField
					type={'number'}
					language={language}
					register={register}
					setValue={setValue}
					elementId={`${elementId}.day`}
					value={value?.day ? value.day : d}
					placeholder="DD"
					maxLength="2"
					minLength="2"
					validation={dayValidation}
					className={` date1LOC day   ${properties?.day?.isRequired ? 'textbox_req' : ''}  ${errors[elementId] && errors[elementId]['day'] ? 'is-invalid' : ''
						}`}
					style={{
						width: '38px',
					}}
					disabled={properties.readOnly}
				/>
			</div>
		</div>
	);
};

export default Date;
