/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputField from "../../../components/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { applicantValidationSchema } from "../../../_helpers/schema-validation";
import { InputFieldStyleOverwrite } from "../Ward/AddWard";
import { useState } from "react";
import Province from "../../../_elements/Province";
import District from "../../../_elements/District";
import { useDispatch, useSelector } from "react-redux";
import { createApplicant } from "../../../_redux/slices/applicants";

const AddDocumentType = ({ hideApplicantForm }) => {
  const language = useSelector(state => state.language.language);
  const {
    register,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(applicantValidationSchema),
  });
  // const handleAddApplicant = () => {
  //   createApplicant();
  // };

  const dispatch = useDispatch();
  const [province, setprovince] = useState();
  const [district, setDistrict] = useState();

  const handleProvinceChange = (provinceVal) => {
    setprovince(provinceVal);
  };

  const handleDistrictChange = (districtVal) => {
    console.log(districtVal);
    setDistrict(districtVal);
  };
  const onSubmit = (data) => {
    alert("SUbmitted");
    console.log(data);
    dispatch(createApplicant(data, hideApplicantForm));
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Card.Header className="card-header-custom">
          <h3>Create Applicant</h3>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group controlId="formBasicNepName">
                <Form.Label className="mb-0"> नाम</Form.Label>
                <div className="d-flex justify-content-between">
                  <InputField
                    css={InputFieldStyleOverwrite}
                    type="text"
                    className="textbox_req"
                    language={language}
                    classNameName={`input`}
                    elementId="first_name_np"
                    placeholder="पहीलो नाम"
                    maxLength={50}
                    register={register}
                    isRequired={true}
                    setValue={setValue}
                  />
                  <InputField
                    css={InputFieldStyleOverwrite}
                    type="text"
                    className="textbox_req"
                    language={language}
                    classNameName={`input`}
                    elementId="middle_name_np"
                    placeholder="बीचको नाम"
                    maxLength={50}
                    register={register}
                    isRequired={true}
                    setValue={setValue}
                  />
                  <InputField
                    css={InputFieldStyleOverwrite}
                    type="text"
                    className="textbox_req"
                    language={language}
                    classNameName={`input`}
                    elementId="last_name_np"
                    placeholder="थर"
                    maxLength={50}
                    register={register}
                    isRequired={true}
                    setValue={setValue}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group controlId="formBasicEnName">
                <Form.Label className="mb-0">Name</Form.Label>
                <div className="d-flex justify-content-between">
                  <InputField
                    css={InputFieldStyleOverwrite}
                    type="text"
                    className="textbox_req"
                    language={language}
                    classNameName={`input`}
                    elementId="first_name_en"
                    placeholder="First Name"
                    maxLength={50}
                    register={register}
                    isRequired={true}
                    setValue={setValue}
                  />
                  <InputField
                    css={InputFieldStyleOverwrite}
                    type="text"
                    className="textbox_req"
                    language={language}
                    classNameName={`input`}
                    elementId="middle_name_en"
                    placeholder="Middle Name"
                    maxLength={50}
                    register={register}
                    isRequired={true}
                    setValue={setValue}
                  />
                  <InputField
                    css={InputFieldStyleOverwrite}
                    type="text"
                    className="textbox_req"
                    language={language}
                    classNameName={`input`}
                    elementId="last_name_en"
                    placeholder="Last Name"
                    maxLength={50}
                    register={register}
                    isRequired={true}
                    setValue={setValue}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="mb-0">email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="email"
                  {...register("email")}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group controlId="formBasicPassword">
                <Form.Label className="mb-0">password</Form.Label>
                <Form.Control
                  css={InputFieldStyleOverwrite}
                  type="password"
                  placeholder="password"
                  {...register("password")}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group controlId="formBasicPhone">
                <Form.Label className="mb-0">फोन</Form.Label>
                <InputField
                  css={InputFieldStyleOverwrite}
                  type="number"
                  className="textbox_req"
                  language={language}
                  classNameName={`input`}
                  elementId="phone"
                  placeholder="Enter contact number"
                  maxLength={10}
                  register={register}
                  isRequired={true}
                  setValue={setValue}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group controlId="formBasicCitizenNumber">
                <Form.Label className="mb-0">citizenship number</Form.Label>
                <Form.Control
                  {...register("citizenship_number")}
                  type="text"
                  placeholder="Enter citizenship_number"
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group controlId="formBasicCitizenIssueDistrict">
                <Form.Label className="mb-0">
                  citizenship issue district
                </Form.Label>
                <Form.Control
                  {...register("citizenship_issue_district")}
                  type="text"
                  placeholder="Enter citizenship_issue_district"
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group controlId="formBasicCitizenID">
                <Form.Label className="mb-0">citizenship id</Form.Label>
                <Form.Control
                  {...register("citizenship_id")}
                  type="text"
                  placeholder="Enter citizenship_id"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group controlId="formBasicAddress">
                <Form.Label className="mb-0">ठेगाना</Form.Label>
                <Form.Control
                  {...register("address")}
                  type="text"
                  placeholder="Enter address"
                />
              </Form.Group>
            </Col>

            <Col>
              <Province
                elementId={"province"}
                register={register}
                province={province}
                handleProvinceChange={handleProvinceChange}
              />
            </Col>
            <Col>
              {console.log(province, 'province')}
              <District
                elementId={"district"}
                register={register}
                province={province}
                district={district}
                handleDistrictChange={handleDistrictChange}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group controlId="formBasicWard">
                <Form.Label className="mb-0">ward</Form.Label>
                <Form.Control
                  {...register("ward")}
                  type="text"
                  placeholder="Enter ward"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group>
                <Form.Label className="mb-0">is_nepali</Form.Label>
                <input {...register("is_nepali")} type="checkbox" />
              </Form.Group>
            </Col>
            <Col md={12} className="mb-3">
              <Form.Group>
                <Form.Label className="mb-0">is_KYC_verified</Form.Label>
                <input {...register("is_KYC_verified")} type="checkbox" />
              </Form.Group>
            </Col>
            <Col md={12} className="mb-3">
              <Form.Group>
                <Form.Label className="mb-0">is_approved</Form.Label>
                <input {...register("is_approved")} type="checkbox" />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <Card.Footer className="d-flex justify-content-end align-items-center">
          <Button
            variant="secondary"
            className="mr-2"
            onClick={hideApplicantForm}
          >
            रद्द गर्नुहोस्
          </Button>
          <Button type="submit" variant="success">
            पेश गर्नुहोस्
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default AddDocumentType;

// {
//   "phone" : "90000",v
//   "email" : "nikesh@gmail.com", v
//   "password" : "nikesh",
//   "first_name_np" : "nikesh",v
//   "middle_name_np" : null,v
//   "last_name_np" : "pakuwal",v
//   "first_name_en" : "nikesh",v
//   "middle_name_en" : null,v
//   "last_name_en" : "pakuwal",v
//   "citizenship_number": null,v
//   "citizenship_issue_district": null,v
//   "citizenship_id": null,v
//   "image": null,
//   "address": null,v
//   "province": null,v
//   "district": null,v
//   "ward": null, v
//   "is_KYC_verified": false,
//   "is_approved": false,
//   "is_nepali": false,
// }
