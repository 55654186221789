/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import NepaliDate from "nepali-date-converter";
import { Fragment } from "react";
import { Card } from "react-bootstrap";
import { StyledStatusCard } from "./Styles/StyledStatusCard";
import { nepaliNumberConverter } from "../../_helpers/methods";

function StatusCard({ isSifarishFound, status, remarks }) {
  let message;
  switch (status?.paper_status) {
    case "Approved":
      message =
        "कृपया आफ्नो सिफारिस प्राप्त गर्न कार्यालयमा सम्पर्क गर्नुहोस्।";
      break;
    case "Pending":
      message =
        "तपाईंको सिफारिस अनुरोध विचाराधीन स्थितीमा छ, कृपया केहि समयपछि पुन: प्रयास गर्नुहोस्।";
      break;
    case "Rejected":
      message = (
        <div className="text-left">
          तपाईको सिफारिस अनुरोध अस्वीकृत भएको छ | निम्न लिखित कारणहरुको सही
          विवरण भरी पुन: पेश गर्नुहोस् |
          <p
            className="mt-2 p-2"
            style={{
              textAlign: "left",
              backgroundColor: "#f4f6f9",
            }}
          >
            <span style={{ color: "#646464" }}>कैफियत:</span> <br />
            <span className="px-2">{remarks}</span>
          </p>
        </div>
      );
      break;
    default:
      message = null;
  }
  return (
    <StyledStatusCard>
      <Card.Body>
        <div className="logo_div">
          <img
            src="/assets/images/nep_logo.png"
            alt="logo"
            width="100"
            className="shadow-light rounded-circle"
          />
          <h3
            css={css`
              margin-top: 1rem;
            `}
          >
            सिफारिस अनुरोधको स्थिती
          </h3>
        </div>

        {isSifarishFound ? (
          <Fragment>
            <div className="d-flex justify-content-end">
              {status?.fiscal_year__title && (
                <span>
                  आर्थिक वर्ष:{" "}
                  {nepaliNumberConverter(status?.fiscal_year__title)}
                </span>
              )}
            </div>
            <div className="createdAt_div">
              {status?.created_at ? (
                <span>
                  जारी मिति:{" "}
                  {new NepaliDate(new Date(status?.created_at)).format(
                    "YYYY/MM/DD",
                    "np"
                  )}
                </span>
              ) : null}
            </div>
            <div className="createdAt_div">
              टोकन: <strong>{status?.token}</strong>
            </div>
            <div className="text-center mt-3">
              <span className="status_span">
                Status:{" "}
                <span className={`${status?.paper_status}`}>
                  {status?.paper_status}
                </span>
              </span>
            </div>
            <div className="text-center">
              <span>{message}</span>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="text-center my-3">
              <span className="status_span mt-2 text-primary">
                {" "}
                तपाईले खोज्नुभएको सिफारिस भेटिएन ।
              </span>
            </div>
          </Fragment>
        )}
      </Card.Body>
    </StyledStatusCard>
  );
}

export default StatusCard;
