/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import React, {
	useEffect,
	useState,
	useRef,
	Fragment,
	useCallback,
} from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchWrapper } from '../_helpers/fetchWrapper';
import { detailExtractor } from '../_helpers/methods';
import { AiOutlinePrinter } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import { FaDownload, FaEye } from 'react-icons/fa';
import { actionButtonStyleOverwrite } from './Papers/Verified';
import { generatePreview } from '../_helpers/methods';
import PreviewModel from '../components/Form/PreviewModel';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

const ApplicantPreview = () => {
	const { id } = useParams();
	const [applicantDetail, setApplicantDetail] = useState(null);
	const nagarpalika = useSelector((state) => state.nagarpalika.nagarpalika);
	const user = useSelector((state) => state.users.user);
	const [paper, setPaper] = useState(null);
	const printWholeRef = useRef(null);
	const [showFile, setShowFile] = useState(false);

	const [previewData, setPreviewData] = useState(null);
	const [showPreview, setShowPreview] = useState(false);
	const [paperDetail, setPaperDetail] = useState(null);
	const [previewFormData, setPreviewFormData] = useState(null);

	const handleHideModal = () => setShowFile(false);

	useEffect(() => {
		const getPaper = async () => {
			let response = await fetchWrapper.get(`/paper/${id}/`);
			setPaper(response?.data);
		};
		try {
			getPaper();
		} catch (error) { }
	}, [id]);

	useEffect(() => {
		if (paper) {
			setApplicantDetail(detailExtractor(paper?.form_values, paper?.values));
		}
	}, [paper]);

	const handleOpenFile = (link) => {
		setShowFile(link);
	};

	const handlePreviewPaper = useCallback(
		async (paper, fy) => {
			setPaperDetail(paper.values);
			setPaper(paper);
			const formData = {
				...paper.form_values,
				renewed: paper?.renewed_date_bs,
			};
			setPreviewFormData(formData);
			let templateWithPreviewData;
			if (paper.values?.preview) {
				templateWithPreviewData = paper.values;
			} else {
				const response = await fetchWrapper.get(`/template/${paper.template}/`);
				templateWithPreviewData = response.data;
			}
			const tempPreviewData = generatePreview(
				templateWithPreviewData,
				formData,
				user,
				fy,
				paper?.extra
			);
			setPreviewData(tempPreviewData);
			setShowPreview(true);
		},
		[user]
	);

	const printRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => {
			return printRef.current;
		},
		onAfterPrint: async () => {
			try {
				//Need to detect if the print is cancelled, if cancelled do not increment count
				// dispatch(incrementPaperCountHandler(paper.id));
			} catch (err) {
				console.log(err, 'error after printing');
			}
		},
	});

	const handlePrintPreview = (id) => {
		const body = {
			_save_and_print: true,
		};
		return fetchWrapper
			.patch(`/paper/${paper.id}/`, body)
			.then((res) => {
				setPaper((prev) => ({ ...prev, issue_id: res?.data?.issue_id }));
				handlePrint();
			})
			.catch((err) => {
				// dispatch(stopUiLoading());
				let errorMessage = err.error?.error?.non_field_error || err.message;
				errorMessage && toast.error(errorMessage[0]);
				return false;
			});
	};

	return (
		<>
			<div ref={printWholeRef}>
				<Card>
					<Card.Header
						className="d-flex py-4 mb-4"
						style={{ borderWidth: '3px', borderColor: '#6677ef' }}
					>
						<Col sm={2} style={{ display: 'grid', placeContent: 'center' }}>
							<img
								src={nagarpalika?.main_logo ?? '/assets/images/nep_logo.png'}
								style={{
									width: '150px',
									objectFit: 'cover',
									objectPosition: 'center',
								}}
								alt="nep logo"
							/>
						</Col>
						<Col
							sm={8}
							style={{ displhandleHideModalay: 'grid', placeContent: 'center' }}
						>
							<h1 className="text-danger text-center">
								{nagarpalika?.name_np}
							</h1>
							<h2 className="text-danger text-center">
								{nagarpalika?.district_np} जिल्ला
							</h2>
							<h3
								className="text-danger text-center"
								style={{ fontSize: '1.2rem' }}
							>
								{nagarpalika?.address_np}
							</h3>
						</Col>
						<Col sm={2} style={{ display: 'grid', placeContent: 'center' }}>
							<img
								src={
									nagarpalika?.campaign_logo ?? '/assets/images/nep_logo.png'
								}
								style={{
									width: '150px',
									objectFit: 'cover',
									objectPosition: 'center',
								}}
								alt="nep logo"
							/>
						</Col>
					</Card.Header>
					<Card.Body className="position-relative" as={Row}>
						<div
							css={css`
								position: absolute;
								top: 0;
								right: 1.5rem;
								.btn-print:hover {
									background-color: #ccc;
								}
								@media print {
									.btn-print {
										display: none;
									}
								}
							`}
						>
							<ReactToPrint
								trigger={() => (
									<span
										title="print"
										role="button"
										className="btn btn-ghost btn-print"
									>
										<AiOutlinePrinter fontSize={28} />
									</span>
								)}
								content={() => printRef.current}
							/>
						</div>
						<Col md={12} className="mb-4">
							<div dangerouslySetInnerHTML={{ __html: applicantDetail }}></div>
						</Col>
					</Card.Body>
				</Card>
				<Card>
					<Card.Header>
						<h2 className="text-primary">नविकरण विवरण</h2>
					</Card.Header>
					<Card.Body>
						<div className="content-container">
							<table
								className="table table-bordered"
								id="table_resize"
								border="0"
								cellpadding="0"
								cellspacing="0"
							>
								<tr>
									<th style={{ fontWeight: 'bold' }}>आर्थिक वर्ष</th>
									<th style={{ fontWeight: 'bold' }}>नविकरण गरिएको मिति</th>
									<th style={{ fontWeight: 'bold' }}>नविकरण कायम रहने मिति</th>
									<th style={{ fontWeight: 'bold' }}>नविकरण रकम</th>
									<th style={{ fontWeight: 'bold' }}>नविकरण जरिवाना रकम</th>
									<th style={{ fontWeight: 'bold' }}>नविकरण दस्तुर रसिद नं.</th>
									{paper?.extra?.template_data?.isSansthaTemplate && (
										<>
											<th style={{ fontWeight: 'bold' }}>
												कार्यसमितिको निर्णय
											</th>
											<th style={{ fontWeight: 'bold' }}>अडिट रिपोर्ट</th>
											<th style={{ fontWeight: 'bold' }}>साधारण सभा निर्णय</th>
											<th style={{ fontWeight: 'bold' }}>वार्डको सिफारिस</th>
										</>
									)}
									<th style={{ fontWeight: 'bold' }}>कार्यहरु</th>
								</tr>
								<>
									{paper?.renewed_history?.length > 0 ? (
										paper?.renewed_history?.map((renew) => (
											<tr>
												<td>{renew?.fiscalyear_title}</td>
												<td>{renew?.form_values?.date}</td>
												<td>{renew?.form_values?.expiry_date}</td>
												<td>रु. {renew?.form_values?.renew_charge}</td>
												<td>रु. {renew?.form_values?.renew_fine}</td>
												<td>{renew?.form_values?.renew_bill}</td>
												{paper?.extra?.template_data?.isSansthaTemplate && (
													<Fragment>
														<td>
															<div className="d-flex justify-content-center py-2">
																<img
																	src={renew?.form_values?.executive_decision}
																	className="logo shadow"
																	role="button"
																	title="click to open"
																	onClick={() =>
																		handleOpenFile(
																			renew?.form_values?.executive_decision
																		)
																	}
																	alt=""
																/>
															</div>
														</td>
														<td>
															<div className="d-flex justify-content-center py-2">
																<img
																	src={renew?.form_values?.audit_report}
																	className="logo shadow"
																	role="button"
																	title="click to open"
																	onClick={() =>
																		handleOpenFile(
																			renew?.form_values?.audit_report
																		)
																	}
																	alt=""
																/>
															</div>
														</td>
														<td>
															<div className="d-flex justify-content-center py-2">
																<img
																	src={renew?.form_values?.general_decision}
																	className="logo shadow"
																	role="button"
																	title="click to open"
																	onClick={() =>
																		handleOpenFile(
																			renew?.form_values?.general_decision
																		)
																	}
																	alt=""
																/>
															</div>
														</td>
														<td>
															<div className="d-flex justify-content-center py-2">
																<img
																	src={renew?.form_values?.ward_sifaris}
																	className="logo shadow"
																	role="button"
																	title="click to open"
																	onClick={() =>
																		handleOpenFile(
																			renew?.form_values?.ward_sifaris
																		)
																	}
																	alt=""
																/>
															</div>
														</td>
													</Fragment>
												)}
												<td>
													<Button
														css={actionButtonStyleOverwrite}
														onClick={() =>
															handlePreviewPaper(paper, {
																label: renew?.fiscalyear_title,
															})
														}
													>
														<FaEye />
													</Button>
												</td>
											</tr>
										))
									) : (
										<td
											colSpan={
												paper?.extra?.template_data?.isSansthaTemplate ? 11 : 6
											}
										>
											<p className="text-center py-1">
												<i>माफ गर्नुहोस्, नविकरण सम्बन्धी विवरण फेला परेन।</i>
											</p>
										</td>
									)}
								</>
							</table>
						</div>
					</Card.Body>
				</Card>
			</div>

			<FilePreviewModal showFile={showFile} hideModal={handleHideModal} />
			{showPreview && (
				<PreviewModel
					handleClose={() => setShowPreview(false)}
					show={showPreview}
					previewData={previewData}
					previewFormData={previewFormData}
					innerRef={printRef}
					config={paperDetail.config}
					paperExtra={paper.extra}
					meta={paperDetail.meta}
					preview={true}
					handlePrint={handlePrintPreview}
					issueId={paper?.issue_id}
					documents={paper?.documents}
					issueCount={paper?.count}
					darta_number={paper?.darta_number}
					templateHeader={paper?.values?.header}
				/>
			)}
		</>
	);
};

export default ApplicantPreview;

const FilePreviewModal = ({ showFile, hideModal }) => {
	const handleDownload = () => {
		const a = document.createElement('a');
		a.href = showFile;
		a.download = 'fie';
		a.setAttribute('target', '_blank');
		a.setAttribute('download', 'true');
		a.click();
	};
	return (
		<Modal show={showFile} onHide={hideModal} size="lg">
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body className="position-relative d-flex align-items-center justify-content-center">
				<div
					css={css`
						width: 100%;
						position: absolute;
						top: 24px;
						z-index: 1;
						height: 43px;
						:hover {
							button {
								opacity: 1;
							}
						}
					`}
				>
					<button
						css={css`
							position: absolute;
							right: 24px;
							padding: 8px;
							background: white;
							border-radius: 5px;
							opacity: 0.3;
							transition: 300ms ease-in-out;
							:active {
								background: #d2d2d2;
							}
						`}
						onClick={handleDownload}
					>
						<FaDownload className="mr-1" />
					</button>
				</div>

				<img src={showFile} alt="" className="w-100" />
			</Modal.Body>
		</Modal>
	);
};
