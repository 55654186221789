/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import React, { useEffect, useState, useMemo } from 'react';

// components
import { DropDownLink, SidebarLink } from './SubMenu';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

// icons
import { IoCopy } from 'react-icons/io5';
import { VscLoading } from 'react-icons/vsc';
import { fetchTempCategory } from '../../_redux/slices/template';
import { connect, useSelector } from 'react-redux';

// use this component if categories are not required

// function PaperTemplateRenderer({ fetchTempCategory }) {
// 	const [isLoading, setIsLoading] = useState(false);
// 	const templatesCategory = useSelector((state) => state?.templateCategories?.templatesCategory);
// 	const [subNav, setSubNav] = useState('');

// 	const templates = useMemo(() => {
// 		let temps = [];
// 		if (templatesCategory?.length > 0) {
// 			templatesCategory?.forEach((templt) => {
// 				temps = [...temps, ...templt?.templates];
// 			});
// 		}
// 		return temps;
// 	}, [templatesCategory]);

// 	useEffect(() => {
// 		const getSidebarData = async () => {
// 			try {
// 				setIsLoading(true);
// 				fetchTempCategory();
// 				setIsLoading(false);
// 			} catch (err) {
// 				setIsLoading(false);
// 				console.log(err);
// 			}
// 		};

// 		getSidebarData();
// 	}, [fetchTempCategory]);

// 	return (
// 		<div>
// 			{isLoading ? (
// 				<div className="d-flex justify-content-center ">
// 					<VscLoading className="spin" />
// 				</div>
// 			) : (
// 				<React.Fragment>
// 					<hr
// 						css={css`
// 							margin: 0 auto;
// 							width: 90%;
// 							border-top-color: #dedede !important;
// 						`}
// 					/>

// 					{templates?.map((item) => (
// 						<SidebarLink
// 							to={`/template-single/${item.id}`}
// 							onClick={() => item.subNav && setSubNav(!subNav)}
// 							activeClassName="navbar__link--active"
// 							exact={true}
// 						>
// 							<div className="menu-items">
// 								<div>
// 									<IoCopy />
// 								</div>
// 								<span>{item.title}</span>
// 							</div>
// 							<div className="chevron">
// 								{item.subNav && subNav ? item.iconOpened : item.subNav ? item.iconClosed : null}
// 							</div>
// 						</SidebarLink>
// 					))}
// 					<hr
// 						css={css`
// 							margin: 0 auto;
// 							width: 90%;
// 							border-top-color: #dedede !important;
// 						`}
// 					/>
// 				</React.Fragment>
// 			)}
// 		</div>
// 	);
// }

function PaperTemplateRenderer({ fetchTempCategory }) {
	const [isLoading, setIsLoading] = useState(false);
	const templatesCategory = useSelector(
		(state) => state?.templateCategories?.templatesCategory
	);
	const [subNav, setSubNav] = useState('');

	useEffect(() => {
		const getSidebarData = async () => {
			try {
				setIsLoading(true);
				fetchTempCategory();
				setIsLoading(false);
			} catch (err) {
				setIsLoading(false);
				console.log(err);
			}
		};

		getSidebarData();
	}, [fetchTempCategory]);

	return (
		<div>
			{isLoading ? (
				<div className="d-flex justify-content-center ">
					<VscLoading className="spin" />
				</div>
			) : (
				templatesCategory?.map((category) => {
					return (
						<React.Fragment key={category.id}>
							<SidebarLink
								to={'#'}
								onClick={() => {
									if (category.title === subNav) {
										setSubNav('');
									} else {
										setSubNav(category.title);
									}
								}}
							>
								<div className="menu-items">
									{/* <img src={category.icon} alt={category.title} /> */}
									<IoCopy />
									<span>{category.title}</span>
								</div>
								<div className="chevron">
									{subNav === category.title ? (
										<FaChevronUp />
									) : (
										<FaChevronDown />
									)}
								</div>
							</SidebarLink>
							{subNav === category.title ? (
								<div>
									{category.templates.map((template) => {
										return (
											<DropDownLink
												to={`/template-single/${template.id}`}
												key={template.id}
												activeClassName="navbar__link--active"
												className="templateSubNav"
												exact={true}
											>
												{template.title}
											</DropDownLink>
										);
									})}
								</div>
							) : null}
						</React.Fragment>
					);
				})
			)}
		</div>
	);
}

export default connect(null, { fetchTempCategory })(PaperTemplateRenderer);
