import Date from './Date';
import FingerPrint from './FingerPrint';
import Logo from './Logo';
import Subject from './Subject';
import Paragraph from './Paragraph';
import Heading from './Heading';
import Table from './Table';
import Signature from './Signature';
import HorizontalLine from './HorizontalLine';
import Photo from './Photo';
import Partition from './Partition';
import TextInput from './TextInput';
import FormGroup from './_new/FormGroup';

function Elements({
	elements,
	language,
	register,
	control,
	errors,
	setValue,
	isRequired = false,
	values = {},
	category,
	isPublic,
}) {
	const element = (ele) => {
		console.log(ele.key);
		switch (ele.key) {
			case 'Date':
				return (
					<Date
						key={ele.key}
						data={ele}
						language={language}
						register={register}
						values={values}
						setValue={setValue}
					/>
				);

			case 'Heading':
				return <Heading key={ele.key} data={ele} values={values} />;

			case 'FingerPrint':
				return (
					<FingerPrint
						key={ele.key}
						data={ele}
						register={register}
						setValue={setValue}
					/>
				);

			case 'FormGroup':
				return (
					<FormGroup
						key={`${ele.title}_${ele.id}`}
						data={ele}
						register={register}
						errors={errors}
						values={values}
						setValue={setValue}
						language={language}
						control={control}
						systemLanguage={language}
						category={category}
						isPublic={isPublic}
						entity={ele.entity}
					/>
				);

			case 'HorizontalLine':
				return <HorizontalLine key={ele.key} data={ele} />;

			case 'Logo':
				return <Logo key={ele.key} data={ele} />;

			case 'Paragraph':
				return (
					<Paragraph
						key={ele.key}
						data={ele}
						language={language}
						register={register}
						isRequired={isRequired}
						setValue={setValue}
					/>
				);

			case 'Partition':
				return <Partition key={ele.key} data={ele} language={language} />;

			case 'Photo':
				return <Photo key={ele.key} data={ele} language={language} />;

			case 'Signature':
				return (
					<Signature
						key={ele.key}
						data={ele}
						language={language}
						setValue={setValue}
					/>
				);

			case 'Subject':
				return (
					<Subject
						key={ele.key}
						data={ele}
						language={language}
						setValue={setValue}
					/>
				);

			case 'Table':
				return (
					<Table
						key={ele.key}
						data={ele}
						language={language}
						register={register}
						control={control}
						setValue={setValue}
					/>
				);

			case 'TextInput':
				return (
					<TextInput
						key={ele.key}
						data={ele}
						language={language}
						register={register}
						setValue={setValue}
					/>
				);

			default:
				return null;
		}
	};
	return elements && elements.length > 0
		? elements.map((ele) => element(ele))
		: null;
}

export default Elements;
