/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';
import AddWardUser from './AddWardUser';
import {
	activateKawaWardUser,
	fetchWardUsers,
	removeWardUser,
} from '../../../_redux/slices/ward';
import { connect } from 'react-redux';
import { BsCheckCircle } from 'react-icons/bs';
import './styles/WardUser.scss';
import { RiUserReceived2Fill } from 'react-icons/ri';
import CustomTable from '../../../components/Table';
import { useMemo } from 'react';
import { hasSSO } from '../../../_helpers/methods';

const actionButtonStyleOverwrite = css`
	font-size: 12px;
	padding: 0.5rem 0.5rem !important;
	display: inline-grid;
	place-content: center;
`;

const WardUser = ({
	match,
	fetchWardUsers,
	wardUsers,
	removeWardUser,
	activateKawaWardUser,
}) => {
	const [showModal, setShowModal] = useState(null);
	const [userDetail, setUserDetail] = useState(null);
	const [showAdd, setShowAdd] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [wardKawa, setWardKawa] = useState(null);

	useEffect(() => {
		const asyncFetchWardUser = async () => {
			setIsLoading(true);
			await fetchWardUsers(match.params.id);
			setIsLoading(false);
		};
		asyncFetchWardUser();
	}, [fetchWardUsers, match.params.id]);

	const editUser = useCallback(
		(item) => {
			let tempItem = {
				...item,
				eventType: 'edit',
			};
			setUserDetail(tempItem);
			setShowAdd(!showAdd);
		},
		[showAdd]
	);

	useEffect(() => {
		if (wardUsers?.users) {
			let wardKawaUser = wardUsers?.users?.filter((user) => user.is_active);
			if (wardKawaUser?.length > 0) {
				setWardKawa(wardKawaUser[0]);
			}
		}
	}, [wardUsers?.users]);

	const headers = useMemo(() => {
		if (wardUsers?.users?.length > 0) {
			return [
				{
					key: 'fullname_np',
					title: 'नाम',
					render: (value) => (
						<>
							{value.is_active ? (
								<span className="kawa_indicator">
									<RiUserReceived2Fill color="#6171e4" /> 'का.वा'
								</span>
							) : null}
							{value.fullname_np || value.fullname_en}
						</>
					),
				},
				{
					key: 'email',
					title: 'ईमेल',
				},
				{
					key: 'phone_np',
					title: 'फोन',
				},
				{
					key: 'post_np',
					title: 'पद',
				},
				...(!hasSSO()
					? [
						{
							key: 'actions',
							title: 'कार्यहरू',
							render: (value) => (
								<>
									<Button
										variant="info"
										css={actionButtonStyleOverwrite}
										className="mr-1"
										onClick={() => editUser(value)}
									>
										<FaEdit />
									</Button>
									<Button
										variant="danger"
										css={actionButtonStyleOverwrite}
										className="mr-1"
										onClick={() =>
											setShowModal({
												type: 'delete',
												body: {
													profile_id: value.id,
												},
											})
										}
									>
										<FaTrash />
									</Button>

									{value.role !== 'ward_kawa' ? null : wardKawa ? null : (
										<Button
											variant="secondary"
											css={actionButtonStyleOverwrite}
											onClick={() => {
												setShowModal({
													type: 'kawa',
													body: {
														profile_id: value.id,
														status: 'active',
													},
												});
											}}
										>
											<BsCheckCircle className="mr-1" />
											का.वा सक्रिय गर्नुहोस्
										</Button>
									)}
									{value.is_active ? (
										<Button
											variant="secondary"
											css={actionButtonStyleOverwrite}
											onClick={() => {
												setShowModal({
													type: 'kawa',
													body: {
														profile_id: value.id,
														status: 'inactive',
													},
												});
											}}
										>
											<BsCheckCircle className="mr-1" /> का.वा निष्क्रिय
											पार्नुहोस्
										</Button>
									) : null}
								</>
							),
						},
					]
					: []),
			];
		}
		return [];
	}, [wardUsers?.users, editUser, wardKawa]);

	return (
		<Fragment>
			{!hasSSO() && showAdd && (
				<Row className="mt-1">
					<Col md={12}>
						<AddWardUser
							showAdd={showAdd}
							setShowAdd={setShowAdd}
							userDetail={userDetail}
							setUserDetail={setUserDetail}
							id={match.params.id}
						/>
					</Col>
				</Row>
			)}
			<Row className="mt-1">
				<Col md={12}>
					<Card
						css={css`
							border-radius: 8px;
							box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
						`}
					>
						<Card.Header className="card-header-custom px-3">
							<h2>{wardUsers?.name_np || wardUsers?.name_en}</h2>
							{!hasSSO() && (
								<Button variant="primary" onClick={() => setShowAdd(!showAdd)}>
									<FaPlusCircle />
									&nbsp; नयाँ प्रयोगकर्ता
								</Button>
							)}
						</Card.Header>
						<Card.Body className="py-0 px-3">
							<CustomTable
								headers={headers}
								data={wardUsers?.users?.length > 0 ? wardUsers?.users : []}
								isLoading={isLoading}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Modal
				show={Boolean(showModal)}
				onHide={() => setShowModal(null)}
				centered
			>
				<Modal.Header>
					<h3>{showModal?.type === 'kawa' ? 'का.वा अपडेट' : 'User delete'}</h3>
				</Modal.Header>
				<Modal.Body>
					<p className="h5 font-weight-lighter">
						{showModal?.type === 'kawa'
							? 'के तपाइँ यो का.वा अपडेट गर्न चाहानुहुन्छ?'
							: 'के तपाइँ यो user हटाउन चाहानुहुन्छ?'}
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="btn btn-secondary"
						onClick={() => {
							setShowModal(null);
						}}
					>
						रद्द गर्नुहोस्
					</Button>
					<Button
						className="btn btn-danger"
						onClick={async () => {
							if (showModal?.type === 'delete') {
								removeWardUser(showModal.body.profile_id);
								setShowModal(null);
							} else if (showModal?.type === 'kawa') {
								await activateKawaWardUser(showModal?.body);
								if (showModal?.body.status === 'inactive') {
									setWardKawa(null);
								}
								setShowModal(null);
							}
						}}
					>
						सुनिश्चित गर्नुहोस्
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	wardUsers: state.wards?.wardUsers,
});

const mapDispatchToProps = {
	fetchWardUsers,
	removeWardUser,
	activateKawaWardUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(WardUser);
