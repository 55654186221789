/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// import { data } from "../../_datas";
import { VscLoading } from 'react-icons/vsc';
import { setFormTemplates, setTemplateList } from '../../_redux/slices/formTemplates';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';

import { FaSearch } from 'react-icons/fa';
import PaginationComp from '../../components/Pagination';
import { colorClass } from '../Dashboard';
import InputField from '../../components/InputField';
import { languageSelector } from '../../_redux/slices/languages';
import TemplateCard from '../../components/TemplateCard/TemplateCard';

const Templates = ({ setFormTemplates, setTemplateList }) => {
	const templates = useSelector((state) => state.formTemplates.templates);
	const count = useSelector((state) => state.formTemplates.count);
	const isLoading = useSelector((state) => state.formTemplates.loading);
	const { pageNum } = useParams();
	const history = useHistory();
	const [page, setPage] = useState(pageNum);
	const [searchTitle, setSearchTitle] = useState('');
	const { language } = useSelector(languageSelector);
	useEffect(() => {
		setTemplateList(page, searchTitle);
	}, [setTemplateList, searchTitle, page]);

	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};

	return (
		<Fragment>
			<Row className="mt-2">
				<Col md={12}>
					<Card>
						<Card.Header className="card-header-custom">
							<h2>सबै प्रमाणपत्रको ढाँचा</h2>
							<div
								className="card-header-form"
								css={css`
									width: 320px;
								`}
							>
								<form>
									<div className="input-group">
										<InputField
											css={css`
												padding: 0 0.8rem !important;
											`}
											className="form-control custom-search"
											placeholder={language === 'english' ? 'Enter template name' : 'ढाँचा खोज्न टाईप गर्नुहोस'}
											language={language}
											getInputData={(value) => {
												setSearchTitle(value);
											}}
										/>
										<div className="input-group-btn">
											<button
												className="btn btn-primary input-search-icon"
												css={css`
													cursor: default !important;
												`}
												onClick={(e) => {
													e.preventDefault();
												}}
											>
												<FaSearch />
											</button>
										</div>
									</div>
								</form>
							</div>
						</Card.Header>
						<Card.Body className="p-2">
							{isLoading ? (
								<div
									css={css`
										display: flex;
										justify-content: center;
										align-items: center;
										height: 200px;
									`}
								>
									<VscLoading className="spin" fontSize="32" />
								</div>
							) : (
								<Row className="mt-3">
									{templates?.length > 0 ? (
										templates.map((template, index = 1) => (
											<TemplateCard
												title={template.title}
												id={template.id}
												key={template.id}
												idx={index % colorClass.length}
												charge={template.charge}
												additional_info={template.additional_info}
												categoryIcon={template.category_icon}
												icon={template.icon}
											/>
										))
									) : (
										<Col>
											<div
												className="my-4"
												css={css`
													display: flex;
													justify-content: center;
												`}
											>
												<h2>ढाँचा पाईएन</h2>
											</div>
										</Col>
									)}
								</Row>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<PaginationComp page={page} total={count} setPage={handleSetPage} perPage={20} />
			{/*
      <div className="top-section">
        <h4 className="main-title">सबै सिफारिसका ढाँचा</h4>
        <form>
          <div className="input-group">
            <input type="text" className="form-control" placeholder="Search" />
            <div className="input-group-btn">
              <button className="btn btn-primary">
                <FaSearch />
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="content-section">

        <Row className="mt-3">
          {isLoading ? (
            <div className="w-100 d-flex justify-content-center align-items-center height-75vh">
              <VscLoading className="spin" fontSize="38" />
            </div>
          ) : (
            templates &&
            templates.map((data) => (
              <Col md={2} key={data.id}>
                <Link to={`/template-single/${data.id}`}>
                  <div className="card template-card">
                    <div className="card-body text-center">
                      <FaBriefcase size="32" color="6677ef" />
                    </div>
                    <h5 className="card-footer text-center text-white" style={{ backgroundColor: "#6777ef"}}>
                      {data.title}
                    </h5>
                  </div>
                </Link>
              </Col>
            ))
          )}
        </Row>
      </div>
            */}
		</Fragment>
	);
};

export default connect(null, { setFormTemplates, setTemplateList })(Templates);
