import NepaliDate from "nepali-date-converter";
import { englishNumberConverter, nepaliNumberConverter } from "./methods";

export const dynamicValidator = (properties = {}, language = "english") => {
  let validation = {};

  if (properties.isRequired) {
    validation.required = properties.isRequired;
  }

  let validates = {};

  if (properties.length) {
    validates.length = (val) => {
      if (!Boolean(val.length)) {
        return true;
      }
      if (val.length === properties.length) {
        return true;
      }
      return false;
    };
  }

  // work in progress
  // if (!properties.language) {
  //   validates.language = (val) => {
  //     let englishRegex = /(([A-Za-z0-9])||[-__])+/g;
  //     if (language === "english") {
  //       return englishRegex.test(val);
  //     } else {
  //       return englishRegex.test(val);
  //     }
  //   };
  // }

  if (properties.isEmail) {
    validates.email = (val) => {
      const emailValidationRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{​​​​​​​​​​1,3}​​​​​​​​​​\.[0-9]{​​​​​​​​​​1,3}​​​​​​​​​​\.[0-9]{​​​​​​​​​​1,3}​​​​​​​​​​\.[0-9]{​​​​​​​​​​1,3}​​​​​​​​​​\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{​​​​​​​​​​2,}​​​​​​​​​​))$/;
      return emailValidationRegex.test(String(val).toLowerCase());
    };
  }

  // FOR DATE
  if (properties.year) {
    if (properties.preventFuture) {
      validates.preventFutureYear = (v) => {
        if (language === "english") {
          let todayYear = new Date().getFullYear();
          let englishNumber = englishNumberConverter(v);
          return englishNumber <= parseInt(todayYear);
        } else {
          let todayYear = new NepaliDate().format("YYYY");
          return nepaliNumberConverter(v) <= nepaliNumberConverter(todayYear);
        }
      };
    }
  }

  if (properties.month) {
    validates.month = (v) => {
      if (language === "english") {
        let englishNumber = englishNumberConverter(v);
        return (
          (englishNumber > 0 && englishNumber < 13) || englishNumber === ""
        );
      } else {
        let nepaliNumber = nepaliNumberConverter(v);
        console.log(nepaliNumber, "date date");
        return (
          (nepaliNumber > "००" && nepaliNumber < "१३") || nepaliNumber === ""
        );
      }
    };
    // if (properties.preventFuture) {
    //   validates.preventFutureMonth = (v) => {
    //     let todayMonth = getTodayMonth();
    //     if (language === "english") {
    //       return englishNumber <= parseInt(todayMonth);
    //     } else {
    //       return nepaliNumberConverter(v) <= nepaliNumberConverter(todayMonth);
    //     }
    //   };
    // }
  }

  if (properties.day) {
    validates.day = (v) => {
      if (language === "english") {
        let englishNumber = englishNumberConverter(v);
        return (
          (englishNumber > 0 && englishNumber < 33) || englishNumber === ""
        );
      } else {
        let nepaliNumber = nepaliNumberConverter(v);
        return (
          (nepaliNumber > "००" && nepaliNumber < "३३") || nepaliNumber === ""
        );
      }
    };
    // if (properties.preventFuture) {
    //   validates.preventFutureDay = (v) => {
    //     let todayDay = getTodayDay();

    //     if (language === "english") {
    //       return englishNumber <= parseInt(todayDay);
    //     } else {
    //       return nepaliNumberConverter(v) <= nepaliNumberConverter(todayDay);
    //     }
    //   };
    // }
  }

  validation.validate = validates;

  return validation;
};
