import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleChangeLanguage } from '../_redux/slices/languages';

//
import { FaRegKeyboard } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 *
 */
export default function LanguageSelector() {
	const dispatch = useDispatch();
	const language = useSelector((state) => state.language.language);

	const handleToggleLanguage = useCallback(() => {
		if (language === 'english') {
			dispatch(handleChangeLanguage({ language: 'nepali' }));
		} else {
			dispatch(handleChangeLanguage({ language: 'english' }));
		}
	}, [language, dispatch]);

	useEffect(() => {
		const handleLanguageChangeShortcut = (event) => {
			if (event.keyCode === 76 && event.ctrlKey) {
				event.preventDefault();
				handleToggleLanguage();
			}
		};
		document.addEventListener('keydown', handleLanguageChangeShortcut);

		return () => document.removeEventListener('keydown', handleLanguageChangeShortcut);
	}, [handleToggleLanguage]);

	return (
		<OverlayTrigger
			key={'left'}
			placement={'left'}
			delay={{ show: 450, hide: 100 }}
			overlay={
				<Tooltip id={`tooltip-left`}>
					{language === 'nepali' ? 'इनपुट भाषा परिवर्तन गर्न क्लिक गर्नुहोस्।' : 'Click to change input language'}
				</Tooltip>
			}
		>
			<button className="language-btn btn px-2 py-1 border rounded" onClick={handleToggleLanguage}>
				<FaRegKeyboard fontSize={18} className="mr-2" /> {language === 'nepali' ? 'NE' : 'EN'}
			</button>
		</OverlayTrigger>
	);
}
