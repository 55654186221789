import { Fragment } from "react";
import { Card, Col, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { FaIdBadge } from "react-icons/fa";
import { FiInfo } from "react-icons/fi";
import { Link } from "react-router-dom";
import { colorClass } from "../../pages/Dashboard";
import { nepaliNumberConverter } from "../../_helpers/methods";

import "./TempalateCard.scss";

function TemplateCard({
  title,
  id,
  templateUrl = "/template-single/",
  key,
  categoryIcon,
  icon,
  idx,
  charge,
  additional_info,
}) {
  let isAdditionalInfoString = false;
  let isAdditionalInfoArray = false;
  if (typeof additional_info === "string") {
    isAdditionalInfoString = true;
  }
  if (Array.isArray(additional_info)) {
    isAdditionalInfoArray = true;
  }

  const minValidLengthOfIconUrl = 16;
  let templateIcon = null;
  if (icon?.length > minValidLengthOfIconUrl) {
    templateIcon = icon;
  } else if (categoryIcon?.length > minValidLengthOfIconUrl) {
    templateIcon = categoryIcon;
  }

  const popover = (
    <Popover id="popover-basic" className="template-card-popover">
      <Popover.Title as="h3">
        <b>आवश्यक विवरण</b>
      </Popover.Title>
      <Popover.Content className="content">
        {additional_info ? (
          <Fragment>
            {isAdditionalInfoString ? (
              additional_info
            ) : isAdditionalInfoArray ? (
              additional_info.map(({ title, description }) => {
                if (Array.isArray(description)) {
                  return (
                    <div key={title} className="mb-2">
                      <span className="font-weight-bold">{title} :- </span>
                      <div>
                        <ol>
                          {description.map((document, idx) => (
                            <li key={idx} className="detail">
                              {nepaliNumberConverter(String(idx + 1))}.{" "}
                              {typeof document === "string" && document}
                            </li>
                          ))}
                        </ol>
                      </div>
                    </div>
                  );
                }
                return (
                  <div key={title} className="mb-2">
                    <span className="font-weight-bold">{title} :- </span>
                    <span className="detail">{description}</span>
                  </div>
                );
              })
            ) : (
              <div className="text-danger">
                Information is updating, please check later
              </div>
            )}
          </Fragment>
        ) : (
          <div>No data found</div>
        )}
      </Popover.Content>
    </Popover>
  );

  return (
    <Col md={3} className="mb-3 template-card">
      <Card className={`${colorClass[idx]} custom-card mb-1 py-2 `}>
        {additional_info && (
          <div className="d-flex justify-content-end align-items-center ">
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              overlay={popover}
            >
              <button className="btn-info-popover d-flex align-items-center">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 150, hide: 100 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      आवश्यक विवरण
                    </Tooltip>
                  )}
                >
                  <FiInfo
                    size="18px"
                    strokeWidth={"3"}
                    className={`${colorClass[idx]} i-icon`}
                  />
                </OverlayTrigger>
              </button>
            </OverlayTrigger>
          </div>
        )}
        <Link to={`${templateUrl}${id}`}>
          <Card.Body className="p-0">
            <div className="d-flex align-items-center tempalte-card-content">
              {templateIcon ? (
                <div className="tempate-card-icon-container">
                  <img src={templateIcon} alt="icon" className="icon" />
                </div>
              ) : (
                <FaIdBadge size="48" className=" mr-3" />
              )}
              <h5 className="text-center">{title}</h5>
            </div>
          </Card.Body>
        </Link>
      </Card>
    </Col>
  );
}

export default TemplateCard;
