import styled from '@emotion/styled';
import React from 'react';
import StatCard from '../_elements/StatCard';
import { fetchWrapper } from '../_helpers/fetchWrapper';
import Chart from 'react-apexcharts';
import { englishToNepaliNumber as np } from 'nepali-number';
import { Row } from 'react-bootstrap';
import { RiStackFill } from 'react-icons/ri';
import { GiPapers } from 'react-icons/gi';
import { FaBuilding } from 'react-icons/fa';
import { IoCopy } from 'react-icons/io5';
import { nepaliNumber, nepaliNumberConverter } from '../_helpers/methods';
import { useSelector } from 'react-redux';

function OverviewDashboard() {
	const [chartType, setChartType] = React.useState('ward');
	const [stats, setStats] = React.useState({});
	const activeFiscalYear = useSelector((state) => state.fiscal.activeFiscalYear);

	const [chartData, setChartData] = React.useState({
		template_wise_count: [],
		ward_wise_graph_data: [],
		category_wise_graph_data: [],
	});

	React.useEffect(() => {
		const getDashBoardInfo = async () => {
			try {
				let response = await fetchWrapper.get('/dashboard-stats');
				setStats(response.data);
			} catch (err) {
				console.log(err);
			}
		};
		getDashBoardInfo();
	}, []);

	React.useEffect(() => {
		const getChartData = async () => {
			try {
				let response = await fetchWrapper.get(
					`/dashboard-graph/?choice=${chartType}`
				);
				let results = response?.data
					? response?.data
					: {
						template_wise_count: [],
						ward_wise_graph_data: [],
						category_wise_graph_data: [],
					};
				let sortedWardWiseGraphData = results?.ward_wise_graph_data?.sort(
					(a, b) => (a.name_np > b.name_np ? 1 : -1)
				);
				results.ward_wise_graph_data = sortedWardWiseGraphData;
				setChartData(() => results);
			} catch (error) {
				console.log(error);
			}
		};
		getChartData();
	}, [chartType]);

	const options = React.useMemo(() => {
		let categories = [];
		if (chartType === 'ward') {
			categories = chartData?.ward_wise_graph_data?.map((item) => item.name_np);
		} else if (chartType === 'category') {
			categories = chartData?.template_wise_count?.map((item) => item.title.split(' '));
		} else {
			categories = [];
		}
		return {
			title: {
				text: 'संघ संस्था दर्ता प्रणाली',
				offsetX: 0,
				style: {
					fontSize: '24px',
				}
			},
			subtitle: {
				text: ' आ.व. : ' + nepaliNumberConverter(activeFiscalYear?.title),
				offsetX: 0,
				style: {
					fontSize: '14px',
				}
			},
			chart: {
				type: 'bar',
			},
			xaxis: {
				categories: categories,
			},
			yaxis: {
				labels: {
					formatter: (val) => nepaliNumber(Math.ceil(val)),
				},
			},
			dataLabels: {
				formatter: (val) => nepaliNumber(val),
			},
		};
	}, [chartType, activeFiscalYear?.title, chartData?.ward_wise_graph_data, chartData?.template_wise_count]);

	const series = React.useMemo(() => {
		let data = [];
		if (chartType === 'ward') {
			data = chartData?.ward_wise_graph_data?.map((item) => item.paper_count);
		} else if (chartType === 'category') {
			data = chartData?.template_wise_count?.map((item) => item.paper_count);
		} else {
			data = [];
		}
		return [
			{
				name: 'जारी भएका प्रमाण पत्र',
				data: data,
			},
		];
	}, [chartData, chartType]);

	return (
		<div className="container-fluid">
			<Row className="mb-2">
				<StatCard
					title="प्रमाण पत्रको ढाँचा"
					number={stats?.templates || stats?.template_count}
					icon={<RiStackFill />}
					path="templates/1"
				/>
				<StatCard
					title="शाखा"
					number={stats?.sakha || stats?.total_sakha}
					icon={<FaBuilding />}
					path="settings/allusers/1"
				/>
				<StatCard
					title="जारी भएका प्रमाण पत्र"
					number={stats?.papers || stats?.total_paper}
					icon={<GiPapers />}
					path="papers/verified/1"
				/>
			</Row>
			<StyledChartsContainer className="shadow-md card flex-row">
				<div className="p-3 col-8 rounded-5">
					<div>
						<button
							className={`btn rounded-0 ${chartType === 'ward' ? 'btn-primary' : 'btn-outline-primary'
								}`}
							onClick={() => setChartType('ward')}
						>
							शाखा
						</button>
						<button
							className={`btn rounded-0 ${chartType === 'category' ? 'btn-primary' : 'btn-outline-primary'
								}`}
							onClick={() => setChartType('category')}
						>
							प्रमाण पत्रको प्रकार
						</button>
					</div>
					<div className='py-2'>
						<Chart options={options} series={series} type="bar" key={options} />
					</div>
				</div>
				<div className="p-3 col-4 ">
					<h4 className="mb-4">प्रमाण पत्रको ढाँचा</h4>
					<div className="overflow-auto" style={{ height: '500px' }}>
						{chartData?.template_wise_count?.map((template) => (
							<div
								key={template.title}
								className="bg-light p-2 d-flex justify-content-between align-items-center mb-2"
							>
								<div classname="d-flex align-items-center">
									<IoCopy className="mr-2" fontSize={18} />
									<span>{template.title}</span>
								</div>
								<span className="count-badge rounded-circle bg-primary text-white">
									{np(template.paper_count)}
								</span>
							</div>
						))}
					</div>
				</div>
			</StyledChartsContainer>
		</div>
	);
}

export default OverviewDashboard;

const StyledChartsContainer = styled.div`
	height: max-content;
	background-color: white;
	margin-inline: -0.4rem !important ;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
	border-radius: 8px;

	.chart-btn {
		border: none;
		background: transparent;
		margin-inline: 1rem;
		&:focus,
		&:active,
		&:hover {
			outline: none;
		}
	}
	.count-badge {
		min-width: 3rem;
		aspect-ratio: 1 / 1;
		display: grid;
		place-content: center;
	}
`;
