import React from "react";
import { useSelector } from "react-redux";
import { getPalikaKey } from "../_helpers/methods";
import { FOOTER_DETAILS } from "../constant";

function DefaultFooter({ isEnglish }) {
  const user = useSelector((state) => state.users.user);
  const palikaKey = getPalikaKey(
    user.nagarpalika_info.name_en || user.nagarpalika_info.nagarpalika_name_en
  );

  const palikaData = isEnglish
    ? FOOTER_DETAILS[palikaKey]?.data?.en
    : FOOTER_DETAILS[palikaKey]?.data?.np;
  const palikaQr = FOOTER_DETAILS[palikaKey]?.qr;

  return (
    <div className="page-footer text-primary border border-2 border-primary rounded">
      <div className="page-footer-text">
        {palikaData?.map((info) => {
          if (info.type === "link") {
            return (
              <span>
                {info.label}: <a href={info.value}>{info.value}</a>
              </span>
            );
          } else {
            return (
              <span>
                {info.label}: {info.value}
              </span>
            );
          }
        })}
      </div>

      {palikaQr && (
        <div className="qr-code">
          <img src={palikaQr} alt="qr code" />
        </div>
      )}
    </div>
  );
}

export default DefaultFooter;
