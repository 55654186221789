/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';
import {
	fetchDocumentType,
	deleteDocumentType,
} from '../../../_redux/slices/documents';
import { connect, useSelector } from 'react-redux';
import AddDocumentType from './AddDocumentType';
import PaginationComp from '../../../components/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import CustomTable from '../../../components/Table';
import { hasSSO } from '../../../_helpers/methods';

const actionButtonStyleOverwrite = css`
	font-size: 12px;
	padding: 0.5rem 0.5rem !important;
	display: inline-grid;
	place-content: center;
`;

const DocumentsType = ({
	fetchDocumentType,
	documentsType,
	count,
	deleteDocumentType,
}) => {
	const history = useHistory();
	const { pageNum } = useParams();

	const loading = useSelector((state) => state.documents.loading);

	const [showAdd, setShowAdd] = useState(false);
	const [documentDetail, setDocumentDetail] = useState(null);
	const [page, setPage] = useState(pageNum);

	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};

	useEffect(() => {
		fetchDocumentType(page);
	}, [fetchDocumentType, page]);

	const handleEdit = (document) => {
		let tempDocu = { ...document };
		tempDocu.eventType = 'edit';
		setDocumentDetail(tempDocu);
		setShowAdd(true);
	};
	const [show, setShow] = useState(false);

	const handleDeleteDocument = async () => {
		await deleteDocumentType(show);
		fetchDocumentType(page);
		setShow(false);
	};

	const headers = useMemo(() => {
		if (documentsType?.length > 0) {
			return [
				{
					key: 'title',
					title: 'शीर्षक',
					headerStyleOverwrite: css`
						width: 50%;
					`,
				},
				{
					key: 'type',
					title: 'प्रकार',
					render: (value) => value.type === 'sifarish' && 'प्रमाण पत्र',
				},
				...(!hasSSO()
					? [
							{
								key: 'actions',
								title: 'कार्यहरू',
								render: (value) => (
									<>
										<Button
											variant="info"
											css={actionButtonStyleOverwrite}
											className="mr-1"
											onClick={() => handleEdit(value)}
										>
											<FaEdit />
										</Button>
										<Button
											variant="danger"
											css={actionButtonStyleOverwrite}
											onClick={() => setShow(value.id)}
										>
											<FaTrash />
										</Button>
									</>
								),
							},
					  ]
					: []),
			];
		}
		return [];
	}, [documentsType]);

	return (
		<Fragment>
			{!hasSSO() && showAdd && (
				<Row className="mt-2">
					<Col md={12}>
						<AddDocumentType
							showAdd={showAdd}
							setShowAdd={setShowAdd}
							documentDetail={documentDetail}
							setDocumentDetail={setDocumentDetail}
						/>
					</Col>
				</Row>
			)}
			<Row className="mt-1">
				<Col md={12}>
					<Card
						css={css`
							border-radius: 8px;
							box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
						`}
					>
						<Card.Header className="card-header-custom px-3">
							<h2>कागजात विवरण</h2>
							{!hasSSO() && (
								<Button
									variant="primary"
									onClick={() => {
										setShowAdd(true);
										setDocumentDetail(null);
									}}
								>
									<FaPlusCircle />
									&nbsp; नयाँ कागजात विवरण
								</Button>
							)}
						</Card.Header>
						<Card.Body className="px-3 pt-0 pb-3">
							<CustomTable
								headers={headers}
								data={documentsType?.length > 0 ? documentsType : []}
								isLoading={loading}
							/>
							<PaginationComp
								page={page}
								total={count}
								setPage={handleSetPage}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Modal show={Boolean(show)} onHide={() => setShow(false)} centered>
				<Modal.Header>
					<h3>कागजात विवरण</h3>
				</Modal.Header>
				<Modal.Body>
					<p className="h5 font-weight-lighter">
						{' '}
						के तपाइँ यो कागजात विवरण हटाउन चाहानुहुन्छ?{' '}
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button className="btn btn-danger" onClick={handleDeleteDocument}>
						सुनिश्चित गर्नुहोस्
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};
const mapStateToProps = (state) => ({
	documentsType: state.documents.documentsType,
	count: state.documents.count,
});

const mapDispatchToProps = {
	fetchDocumentType,
	deleteDocumentType,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsType);
