import React, { Fragment, useEffect, useState } from 'react';
import { VscLoading } from 'react-icons/vsc';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormElements from '../../components/Form';
import { setFormTemplate } from '../../_redux/slices/formTemplates';
import { clearForm } from '../../_redux/slices/paper';

const TemplateForm = ({ setFormTemplate, clearForm }) => {
	const { id } = useParams();
	const isLoading = useSelector((state) => state.formTemplates.loading);
	const [formData, setFormData] = useState(null);

	useEffect(() => {
		const getFormTemplateById = async () => {
			try {
				let fetchedData = await setFormTemplate(id);
				if (fetchedData) {
					setFormData(fetchedData);
				} else {
					setFormData(null);
				}
			} catch (err) {
				console.log(err);
				setFormData(null);
			}
		};
		getFormTemplateById();
	}, [id, setFormTemplate]);
	useEffect(() => {
		clearForm();
	}, [clearForm]);
	return (
		<Fragment>
			{isLoading ? (
				<div className="w-100 d-flex justify-content-center align-items-center height-75vh">
					<VscLoading className="spin" fontSize="38" />
				</div>
			) : (
				<Fragment>
					{formData && <FormElements data={formData} />}
				</Fragment>
			)}
		</Fragment>
	);
};

export default connect(null, { setFormTemplate, clearForm })(TemplateForm);
