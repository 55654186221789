import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  language: "nepali", // english , nepali
  fontType: "traditional", //traditional , romanized
};

const languageSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload;
    }
  },
});

// Actions generated from the slice
const { changeLanguage } = languageSlice.actions;

// export user selector to get the slice in any component
export const languageSelector = (state) => state.language;

// export The reducer
const languageReducer = languageSlice.reducer;

export default languageReducer;

export const handleChangeLanguage =
  ({ language = "" }) =>
    (dispatch) => {
      localStorage.setItem('language', language);
      dispatch(changeLanguage(language));
    };
