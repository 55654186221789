/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Table } from 'react-bootstrap';
import { VscLoading } from 'react-icons/vsc';

const CustomTable = ({ headers = [], data = [], isLoading, tableStyleOverwrite, tableClassName }) => {
	return (
		<div>
			<div className="table-responsive">
				<Table striped hover responsive css={tableStyleOverwrite} className={['', tableClassName].join(' ')}>
					<thead>
						<tr>
							{headers?.map((head) => (
								<th
									key={head.key}
									{...(head?.headerStyleOverwrite ? { css: head?.headerStyleOverwrite } : {})}
									className={['p-2', head?.className ? head?.className : ''].join(' ')}
								>
									{head?.title}
								</th>
							))}
						</tr>
					</thead>
					{!isLoading && data?.length === 0 && (
						<tbody>
							<tr>
								<td colSpan={headers?.length}>
									<div style={{ height: '150px', display: 'grid', placeContent: 'center' }}>
										<p className="text-center text-secondary">कुनै परिणाम फेला परेन</p>
									</div>
								</td>
							</tr>
						</tbody>
					)}
					{isLoading ? (
						<tbody>
							<tr>
								<td colSpan={headers?.length}>
									<div style={{ height: '100px', display: 'grid', placeContent: 'center' }}>
										<VscLoading fontSize={40} className="spin" />
									</div>
								</td>
							</tr>
						</tbody>
					) : (
						<tbody>
							{data?.map((d, i) => (
								<tr key={data?.id ? data?.id : i}>
									{headers?.map((head) => (
										<td
											key={head.key}
											{...(head?.dataStyleOverwrite ? { css: head?.dataStyleOverwrite } : {})}
											className={['px-2', head?.dataClassName ? head.dataClassName : ''].join(' ')}
										>
											{head?.render ? (
												head?.render(d, i)
											) : d[head.key] ? (
												d[head.key]
											) : (
												<small className="text-secondary">N/A</small>
											)}
										</td>
									))}
								</tr>
							))}
						</tbody>
					)}
				</Table>
			</div>
		</div>
	);
};

export default CustomTable;
