import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table,
} from "react-bootstrap";
import { FaEdit, FaPlusCircle, FaTrash } from "react-icons/fa";
import BackButton from "../../../_elements/BackButton";
import MayorForm from "./MayorForm";

import { fetchMayors, deleteMayor } from "../../../_redux/slices/mayors";
import { connect, useSelector } from "react-redux";
import { VscLoading } from "react-icons/vsc";

const MayorTab = ({ fetchMayors, mayors, deleteMayor }) => {
  const [showAdd, setShowAdd] = useState(false);
  const [mayorDetail, setMayorDetail] = useState(null);
  const [show, setShow] = useState([null, false]);
  const loading = useSelector((state) => state.mayors.loading);

  const handleDeleteMayor = () => {
    deleteMayor(show[0]);
    setShow([null, false]);
  };
  useEffect(() => {
    fetchMayors();
  }, [fetchMayors]);

  const handleEdit = (detail) => {
    let tempDetail = {
      ...detail,
      eventType: "edit",
    };
    setMayorDetail(tempDetail);
    setShowAdd(true);
  };

  return (
    <Fragment>
      <Row className="mt-2">
        <Col md={12}>
          <BackButton />
        </Col>
      </Row>
      {showAdd && (
        <Row className="mt-2">
          <Col md={12}>
            <MayorForm
              showAdd={showAdd}
              mayorDetail={mayorDetail}
              setMayorDetail={setMayorDetail}
              setShowAdd={setShowAdd}
            />
          </Col>
        </Row>
      )}
      <Row className="mt-2">
        <Col md={12}>
          <Card>
            <Card.Header className="card-header-custom">
              <h2>मेयर</h2>
              <Button
                variant="primary"
                onClick={() => {
                  setShowAdd(true);
                  setMayorDetail(null);
                }}
              >
                <FaPlusCircle />
                &nbsp; नयाँ मेयर
              </Button>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      {/* <th>role</th> */}
                      <th>नाम</th>
                      <th>नगरपालिका</th>
                      <th>स्थाई ठेगाना</th>
                      <th>फोन</th>
                      <th className="text-center">कार्यहरू</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        {/* <th>role</th> */}
                        <th></th>
                        <th></th>
                        <th>
                          <VscLoading className="spin" fontSize="48" />
                        </th>
                        <th></th>
                        <th></th>
                      </tr>
                    ) : mayors?.length > 0 ? (
                      mayors.map((mayor) => (
                        <tr>
                          {/* <td>{mayor.role}</td> */}
                          <td>{mayor.fullname_np}</td>
                          <td>{mayor.nagarpalika_info.name_np}</td>
                          <td>{mayor.address_np}</td>
                          <td>{mayor.phone_np}</td>
                          <td className="text-center">
                            <Button
                              variant="info"
                              className="mr-1"
                              onClick={() => handleEdit(mayor)}
                            >
                              <FaEdit />
                            </Button>
                            <Button
                              variant="danger"
                              className="mr-1"
                              onClick={() => setShow([mayor.id, true])}
                            >
                              <FaTrash />
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6 text-center">No mayor</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={show[1]} onHide={() => setShow([null, false])} centered>
        <ModalBody>
          <p className="h5 font-weight-lighter">
            {" "}
            के तपाइँ यो मेयर हटाउन चाहानुहुन्छ?{" "}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-danger" onClick={handleDeleteMayor}>
            सुनिश्चित गर्नुहोस्
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  mayors: state.mayors.mayors,
});

const mapDispatchToProps = {
  fetchMayors,
  deleteMayor,
};

export default connect(mapStateToProps, mapDispatchToProps)(MayorTab);
