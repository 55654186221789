import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './CitizensHeaderStyles.scss';
import LanguageSelector from '../../_elements/LanguageSelector';

function CitizensHeader() {
  const nagarpalika = useSelector((state) => state.nagarpalika.nagarpalika);
  return (
    <div className="citizenHeaderStyles">
      <div className="header">
        <Container>
          <div className="header-inner">
            <div className="header-left ">
              <img src="/assets/images/nep_logo.png" className="logo-sm" alt="" />
              <div className="d-flex flex-column">
                <h5 className="text-white mb-0">संघ संस्था दर्ता  प्रणाली</h5>
                <h6 className="text-white mb-0">{nagarpalika?.name_np}</h6>
              </div>
            </div>

            <div className="header-right">
              <LanguageSelector />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default CitizensHeader;
