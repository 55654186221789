/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

//
import { languageSelector } from "../../_redux/slices/languages";
import { NagarpalikaDetail } from "../../components/NagarpalikaDetail/NagarpalikaDetail";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { VscLoading } from "react-icons/vsc";
import BackButton from '../../_elements/BackButton';

/**
 * 
 */
export default function Verification({ showBackbutton }) {
    const [showStatus, setShowStatus] = useState(false);
    const [paper, setPaper] = useState(false);
    const [issueId, setIssueId] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const nagarpalika = useSelector((state) => state?.nagarpalika?.nagarpalika);
    const { language } = useSelector(languageSelector);
    const [isStatusCheckSuccess, setIsStatusCheckSuccess] = useState(false);

    const handleStatusCheck = async (e) => {
        if (!issueId) return;
        let trimedId = issueId?.trim();
        e.preventDefault();
        try {
            setIsLoading(true);
            const response = await fetchWrapper.post(`/paper/verify/`, {
                issue_id: trimedId
            });
            let result = response?.data;
            if (result) {
                setPaper(result);
                setIsStatusCheckSuccess(true);
            } else {
                toast.error('Paper not found');
                setIsStatusCheckSuccess(false);
            }
        } catch (error) {
            if (error?.error?.error?.non_field_error) {
                toast.error(error?.error?.error?.non_field_error[0]);
                setIsStatusCheckSuccess(false);
            }
        } finally {
            setShowStatus(true);
            setIsLoading(false);
        }
    };
    let applicantName;
    if (language === 'english' && paper) {
        applicantName = paper?.applicant_info?.name_en;
    } else {
        applicantName = paper?.applicant_info?.name_np;
    }

    return (
        <Container fluid>
            <div className="citizen-status-check-container">
                {
                    showBackbutton &&
                    <div className='container mb-4'>
                        <BackButton />
                    </div>
                }
                <div className="container-fluid">
                    <Row>
                        <Col sm={showStatus ? 6 : 12}>
                            <Card style={{ width: showStatus ? '80%' : '33%', marginInline: 'auto', maxWidth: '565px' }} className="border shadow">
                                <Card.Body>
                                    <NagarpalikaDetail dark />

                                    <Form onSubmit={(e) => handleStatusCheck(e)}>
                                        <h4 className='brand_primary mb-3 d-block mt-3'>प्रमाण पत्र प्रमाणिकरण परीक्षक</h4>
                                        <p datatype="verification-check">
                                            प्रमाण पत्र प्रमाणित छ कि छैन भनेर जाँच गर्न कृपया प्रमाण पत्र E.N: कोड तलको इनपुटफिल्डमा
                                            राख्नुहोस र Submit बटनमा क्लिक गर्नुहोस्।
                                        </p>

                                        <div className="mt-3 d-flex align-items-center">
                                            <Form.Control
                                                className="status-check-input"
                                                type="text"
                                                placeholder="XXXXXX-XXXX-XXX-XXXXXX-XXX"
                                                onChange={(e) => setIssueId(e.target.value)}
                                                required
                                            ></Form.Control>

                                            <button className="btn btn-status-check" type="submit" disabled={isLoading}>
                                                {isLoading ? <VscLoading className="spin" /> : 'Submit'}
                                            </button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>

                        {showStatus && (
                            <Col sm={6}>
                                <Card className="status-verification-card shadow border" style={{ width: '80%', marginInline: 'auto' }}>
                                    <Card.Body>
                                        <div>
                                            <NagarpalikaDetail dark />
                                        </div>

                                        {isStatusCheckSuccess ? (
                                            <Fragment>
                                                <p
                                                    css={css`
															font-size: 18px;
															padding: 1.5rem 0rem;
														`}
                                                >
                                                    <h3
                                                        className="text-success text-center mb-4 mt- 5"
                                                        css={css`
																text-decoration: underline;
															`}
                                                    >
                                                        प्रमाणित सिफारिस
                                                    </h3>

                                                    <div className='text-center'>
                                                        <strong>{nagarpalika?.name_np}</strong>ले <strong>{applicantName}</strong> ब्यक्ति/संस्थालाइ{' '}
                                                        <strong>{paper?.paper_title}</strong> सम्बन्धी प्रमाण पत्र प्रदान गरेको छ।
                                                    </div>

                                                </p>
                                            </Fragment>
                                        ) : (
                                            <p
                                                css={css`
															font-size: 18px;
														`}
                                            >
                                                <h3 className="text-center text-danger mt-5 mb-4">प्रमाण पत्र प्रमाणीकरण असफल</h3>

                                                <div className='text-center'>
                                                    तपाईले खोज्नुभएको प्रमाण पत्र भेटिएन । कृपया थप जानकारीको लागि{' '}
                                                    <strong>{nagarpalika?.name_np}</strong>
                                                    मा सम्पर्क गर्नुहोला ।
                                                </div>
                                            </p>
                                        )}
                                    </Card.Body>

                                    <Card.Footer className="brand_bg text-white text-center pt-4 mt-4">
                                        <div className='mb-3'>
                                            <h5 >{nagarpalika?.name_np}को कार्यालय</h5>
                                            <h6 className="footer-address">{nagarpalika?.address_np}</h6>
                                        </div>

                                        <div>
                                            <h6 className="more-info">थप जानकारीको लागि</h6>
                                            <p className="">
                                                नगरपालिकाको नम्बर: <strong>{nagarpalika?.phone_np}</strong>
                                            </p>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </div>
            </div>
        </Container>
    )
}