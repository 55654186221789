/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useState } from 'react';
import { Form, Card, Button } from 'react-bootstrap';
import { VscLoading } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import BackButton from '../../_elements/BackButton';
import StatusCard from './StatusCard';

const SifarishStatus = () => {
	const [showStatus, setShowStatus] = useState(false);
	const [status, setStatus] = useState(false);
	const [remarks, setRemarks] = useState(false);
	const [token, setToken] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSifarishFound, setIsSifarishFound] = useState(false);

	const handleStatusCheck = async (e) => {
		try {
			setIsLoading(true);
			e.preventDefault();
			const response = await fetchWrapper.get(`/public/check-paper-status?token=${token}`, false, true);
			if (response?.data.length) {
				setIsSifarishFound(true);
				setStatus(response?.data[0]);
				setRemarks(response?.data[0]?.remarks);
			} else {
				setIsSifarishFound(false);
			}
			setShowStatus(!showStatus);

			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			console.log(err);
			setIsSifarishFound(false);
			toast.error('Something went wrong! please try again later.');
		}
	};
	return (
		<div
			className="login-register"
			css={css`
				height: 100vh;
				padding: 2% 5%;
			`}
		>
			<div className="login-brand text-center mb-2">
				<img src="/assets/images/nep_logo.png" alt="logo" width="100" className="shadow-light rounded-circle" />
				<h3>संघ संस्था दर्ता  प्रणाली</h3>
				<p>प्रविधिमुखी सार्वजनिक सेवा प्रवाहको सुनिश्चितताको प्रयास</p>{' '}
			</div>
			<div className="d-flex">
				<Card
					css={css`
						width: 460px;
						height: fit-content;
						margin: 1rem auto;
						box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
						border-radius: 5px;
						border-top: 2px solid #6777ef;

						@media screen and (max-width: 600px) {
							width: 100%;
						}
					`}
				>
					<Card.Header>
						<div className="text-center">
							<h2 className="text-primary mb-0">सिफारिस अनुरोधको स्थिती</h2>
						</div>
					</Card.Header>
					<Card.Body>
						<Form
							css={css`
								display: flex;
								flex-direction: column;
							`}
							onSubmit={(e) => handleStatusCheck(e)}
						>
							<div
								css={css`
									display: flex;
									justify-content: center;
									text-align: center;
								`}
							>
								कृपया तपाईंको सिफारिस अनुरोध कोड राख्नुहोस ।
							</div>
							<div
								css={css`
									display: flex;
									justify-content: center;
									text-align: center;
								`}
							>
								<Form.Control
									css={css`
										width: 285px;
										font-size: 18px;
										margin: 0.5rem 0;
									`}
									type="text"
									placeholder="Enter request code"
									onChange={(e) => setToken(e.target.value)}
									required
								></Form.Control>
							</div>
							<div
								css={css`
									display: flex;
									justify-content: center;
									text-align: center;
								`}
							>
								<Button
									variant="success"
									css={css`
										width: 140px;
									`}
									className="mt-2"
									type="submit"
									disabled={isLoading}
								>
									{isLoading ? <VscLoading className="spin" /> : 'Submit'}
								</Button>
							</div>
							<BackButton />
						</Form>
					</Card.Body>
				</Card>
				{showStatus && <StatusCard isSifarishFound={isSifarishFound} status={status} remarks={remarks} />}
			</div>
		</div>
	);
};

export default SifarishStatus;
