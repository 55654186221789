/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import { Button, Container, Row, Form, Col } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { logValidationSchema } from '../../_helpers/schema-validation';
import { loginUser, loginUserWIthSSO } from '../../_redux/slices/users';
import { connect, useSelector } from 'react-redux';
import Loader from '../../_elements/Loader';
import { Fragment } from 'react';
import { eye, formInput } from '../../layout/ChangePasswordModal';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import Versioning from '../../components/Versioning';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { USER_PROFILE_TOKEN_NAME } from '../../constant';
import AppTitle from './LoginHead';
import { VscLoading } from 'react-icons/vsc';

const Login = ({ loginUser, loginUserWIthSSO }) => {
	const [isSSOLoading, setIsSSOLoading] = useState(false);
	const sso =
		process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === 'true' ||
		process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === true;
	const ssoTitle = process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO_TITLE;
	const history = useHistory();
	const location = useLocation();
	const token = new URLSearchParams(location.search).get('token');
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(logValidationSchema),
	});
	const nagarpalika = useSelector((state) => state.nagarpalika.nagarpalika);

	useEffect(() => {
		const getTokens = async () => {
			try {
				const response = await fetchWrapper.get(`/oauth/login/?token=${token}`);
				loginUserWIthSSO(response.data, history);
			} catch (error) { }
		};
		try {
			setIsSSOLoading(true);
			if (token) {
				localStorage.setItem(USER_PROFILE_TOKEN_NAME, token);
				getTokens();
			}
		} catch (error) {}
		setIsSSOLoading(false);
	}, [token, history, loginUserWIthSSO]);

	const { loading } = useSelector((state) => state.users);
	const onSubmit = async (data) => {
		// login api
		loginUser(data, history);
	};
	const [showPassword, setShowPassword] = useState(false);

	const handleSSO = () => {
		const redirectLink = document.createElement('a');
		let link = `${process.env.REACT_APP_SSO_URL}/o/consent/${process.env.REACT_APP_APPLICATION_ID}`;
		// redirectLink.target = "_blank";
		if (process.env.NODE_ENV === 'development') {
			link += `?development=true`;
		}
		redirectLink.href = link;
		redirectLink.click();
	};

	// Needs refactor
	// useEffect(() => {
	// 	if (sso) {
	// 		const showLoginPage = localStorage.getItem('showLoginPage');
	// 		if (!showLoginPage) {
	// 			localStorage.setItem('showLoginPage', 'true');
	// 			handleSSO();
	// 		}
	// 	}
	// }, [sso]);

	return (
		<Fragment>
			{isSSOLoading ? (
				<Loader />
			) : (
				<section className="section login-page">
					<Container fluid>
						<Row className="justify-content-center">
							<Col
								as={Row}
								md={5}
								className=""
								style={{ backgroundColor: '#F3F7FA', height: '100vh' }}
							>
								<div className="login-heading col-12">
									<div className="d-flex justify-content-between align-items-center col-12">
										<div className="login-logo">
											{nagarpalika?.main_logo && (
												<img
													src={nagarpalika?.main_logo}
													alt="main_logo"
													onError={(event) =>
														(event.target.style.display = 'none')
													}
												/>
											)}
										</div>
										<div className="login-header text-center">
											<h5>{nagarpalika?.name_np}</h5>
											<h5>{nagarpalika?.name_en}</h5>
											<h4 className="mt-2 mb-1">{nagarpalika?.address_np}</h4>
											<h4>{nagarpalika?.address_en}</h4>
											<h4 className="mt-2">{nagarpalika?.slogan_np}</h4>
										</div>
										<div className="login-logo">
											{nagarpalika?.campaign_logo && (
												<img
													src={nagarpalika?.campaign_logo}
													alt="campaign_logo"
													onError={(event) =>
														(event.target.style.display = 'none')
													}
												/>
											)}
										</div>
									</div>
									<hr />
								</div>

								<div className="col-12" style={{ height: 'max-content' }}>
									<div className="col-9 mx-auto text-center mb-3">
										<Link to="/public/certificate/verification">
											<button className="btn btn-black btn-lg">
												दर्ता प्रमाणिकरण हेर्नुहोस
											</button>
										</Link>
									</div>
								</div>

								<div
									className="col-9 mx-auto mt-5"
									style={{ height: 'max-content' }}
								>
									<div className="footer-powered col-12">
										<div className="powered-by">
											<p>Powered by</p>
										</div>
										<div className="d-flex justify-content-around">
											{process.env.REACT_APP_POWERED_BY_GIZ === 'true' && (
												<img
													src="/assets/images/giz.png"
													className="powered"
													alt=""
												/>
											)}

											<img
												src="/assets/images/ideabreedLogo.png"
												className="powered"
												alt=""
											/>
										</div>
									</div>
								</div>
							</Col>

							<Col
								md={7}
								className="d-flex flex-column justify-content-center align-items-center bg-white col-md-7"
							>
								<div className="col-6">
									<AppTitle />

									<div>
										<div className="p-3">
											<form
												method="POST"
												action="#"
												className="needs-validation"
												novalidate=""
												onSubmit={handleSubmit(onSubmit)}
											>
												<div className="form-group">
													<label
														htmlFor="username"
														css={css`
															margin-bottom: 0;
														`}
													>
														ईमेल
													</label>
													<input
														id="username"
														type="username"
														className="form-control"
														name="username"
														tabindex="1"
														required
														autofocus
														{...register('email')}
													/>
													<div className="invalid-feedback">
														कृपया तपाईंको ईमेल भर्नुहोस्
													</div>
													<p className="text-small text-danger">
														{errors.username?.message}
													</p>
												</div>

												<div className="form-group">
													<div className="d-flex justify-content-between w-100">
														<span for="password" className="control-label">
															पासवर्ड
														</span>

														<Link to="/forgot-password" className="text-small">
															पासवर्ड भुल्नु भयो?
														</Link>
													</div>
													<div className="position-relative">
														<Form.Control
															css={formInput}
															type={showPassword ? 'text' : 'password'}
															name="password"
															{...register('password')}
															tabindex="2"
														/>
														<div css={eye}>
															{showPassword ? (
																<AiFillEyeInvisible
																	css={css`
																		color: gray;
																	`}
																	onClick={() => setShowPassword(false)}
																/>
															) : (
																<AiFillEye
																	css={css`
																		color: gray;
																	`}
																	onClick={() => setShowPassword(true)}
																/>
															)}
														</div>
													</div>
													<div className="invalid-feedback">
														कृपया तपाईको पासवर्ड भर्नुहोस्
													</div>
													<p className="text-small text-danger">
														{errors.password?.message}
													</p>
												</div>

												<div className="mb-3">
													<Button
														type="submit"
														className="btn btn-primary btn-lg btn-block"
														tabindex="4"
														disabled={loading}
													>
														{loading ? <VscLoading className="spin" /> : 'लगइन'}
													</Button>
												</div>
												{sso && (
													<Fragment>
														<div
															className="mb-2 text-center"
															css={css`
																color: #818181;
																font-weight: bold;
															`}
														>
															अथवा
														</div>

														<div className="form-group">
															<Button
																type="button"
																className="btn btn-secondary btn-lg btn-block"
																onClick={handleSSO}
															>
																{ssoTitle || 'Login with SSO'}
															</Button>
														</div>
													</Fragment>
												)}
											</form>
										</div>
									</div>

									<div className="simple-footer text-muted">
										Copyright &copy; {new Date().getFullYear()}{' '}
										<a
											href="https://ideabreed.net/"
											target="_blank"
											rel="noreferrer"
										>
											Idea Breed IT Solution Pvt. Ltd.
										</a>
										<Versioning color="#8a8080" />
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			)}
		</Fragment>
	);
};

export default connect(null, { loginUser, loginUserWIthSSO })(Login);
