/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import InputField from '../../../components/InputField';
import { postWardUser, patchWardUser } from '../../../_redux/slices/ward';
import { InputFieldStyleOverwrite } from '../Ward/AddWard';
import { nepaliNumberConverter } from '../../../_helpers/methods';
import { languageSelector } from '../../../_redux/slices/languages';
import { useEffect } from 'react';
import Password from '../../../_elements/Password';
import { toast } from 'react-toastify';

const AddWardUser = ({ showAdd, setShowAdd, postWardUser, patchWardUser, userDetail, setUserDetail, id }) => {
	const { register, handleSubmit, setValue } = useForm({
		mode: 'onBlur',
		defaultValues: {},
	});
	const { language } = useSelector(languageSelector);

	useEffect(() => {
		setValue('fullname_np', userDetail?.fullname_np);
	}, [setValue, userDetail?.fullname_np]);

	useEffect(() => {
		setValue('fullname_en', userDetail?.fullname_en);
	}, [setValue, userDetail?.fullname_en]);

	useEffect(() => {
		setValue('password', userDetail?.password);
	}, [setValue, userDetail?.password]);

	useEffect(() => {
		setValue('phone_en', userDetail?.phone_en);
	}, [setValue, userDetail?.phone_en]);

	useEffect(() => {
		setValue('email', userDetail?.email);
	}, [setValue, userDetail?.email]);

	useEffect(() => {
		setValue('role', userDetail?.role);
	}, [setValue, userDetail?.role]);

	useEffect(() => {
		setValue('post_np', userDetail?.post_np);
	}, [setValue, userDetail?.post_np]);

	const hideUserForm = () => {
		setShowAdd(false);
	};
	const onSubmit = (data) => {
		if (data.phone_en.length !== 10) {
			return toast.error('Phone number is invalid');
		}
		data.phone_np = nepaliNumberConverter(data.phone_en);
		userDetail ? patchWardUser(data, userDetail.id, setShowAdd, showAdd) : postWardUser(data, hideUserForm, id);
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
			<Card
				css={css`
					border-radius: 8px;
					box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
					margin-bottom: 1rem;
				`}
			>
				<Card.Header className="card-header-custom">
					<h3>{userDetail?.eventType === 'edit' ? 'प्रयोगकर्ता सम्पादन गर्नुहोस्' : ' नयाँ प्रयोगकर्ता'}</h3>
				</Card.Header>
				<Card.Body>
					<Row>
						<Col md={4} className="mb-3">
							<Form.Group controlId="formBasicEmail">
								<Form.Label className="mb-0">नाम (नेपालीमा)</Form.Label>
								<InputField
									css={InputFieldStyleOverwrite}
									type="text"
									className={`textbox_req`}
									language={'nepali'}
									classNameName={`input`}
									elementId={'fullname_np'}
									placeholder={'पूरा नाम भर्नुहोस्'}
									maxLength={50}
									register={register}
									isRequired={true}
									setValue={setValue}
									autoComplete={'nope'}
									defaultValue={userDetail && userDetail.fullname_np}
								/>
							</Form.Group>
						</Col>
						<Col md={4} className="mb-3">
							<Form.Group controlId="formBasicEmail">
								<Form.Label className="mb-0">नाम (अंग्रेजीमा)</Form.Label>
								<InputField
									css={InputFieldStyleOverwrite}
									type="text"
									className={`textbox_req`}
									language={'english'}
									classNameName={`input`}
									elementId={'fullname_en'}
									placeholder={'Enter full name'}
									maxLength={50}
									register={register}
									isRequired={true}
									setValue={setValue}
									autoComplete={'nope'}
									defaultValue={userDetail && userDetail.fullname_en}
								/>
							</Form.Group>
						</Col>
						<Col md={4} className="mb-3">
							<Form.Group controlId="formBasicPhone">
								<Form.Label className="mb-0">Phone No.</Form.Label>
								<Form.Control
									css={InputFieldStyleOverwrite}
									type="number"
									className={`textbox_req`}
									placeholder="Enter phone number "
									maxLength="10"
									{...register('phone_en', {
										required: true,
										maxLength: 10,
									})}
									defaultValue={userDetail && userDetail.phone_en}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={4} className="mb-3">
							<Form.Group controlId="formBasicEmail">
								<Form.Label className="mb-0">ईमेल</Form.Label>
								<InputField
									css={InputFieldStyleOverwrite}
									type="text"
									className={`textbox_req`}
									language={'english'}
									classNameName={`input`}
									elementId={'email'}
									placeholder={'ईमेल भर्नुहोस्'}
									maxLength={50}
									register={register}
									isRequired={true}
									setValue={setValue}
									autoComplete={'nope'}
									defaultValue={userDetail && userDetail.email}
								/>
							</Form.Group>
						</Col>
						<Col md={4} className="mb-3">
							<Form.Group>
								<Form.Label className="mb-0">{language === 'english' ? 'Password' : 'पासवर्ड'}</Form.Label>
								<Password
									css={InputFieldStyleOverwrite}
									register={register}
									id="password"
									placeholder={'Enter password'}
									defaultValue={userDetail && userDetail.password}
									className={`mini-field ${userDetail?.eventType === 'edit' ? '' : 'textbox_req'}`}
									mini-field
									isRequired={userDetail?.eventType === 'edit' ? false : true}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={4} className="mb-3">
							<Form.Group controlId="formBasicEmail">
								<Form.Label className="mb-0">पद</Form.Label>
								<InputField
									css={InputFieldStyleOverwrite}
									type="text"
									className={`textbox_req`}
									language={'nepali'}
									classNameName={`input`}
									elementId={`post_np`}
									placeholder={'पद भर्नुहोस्'}
									maxLength={50}
									register={register}
									setValue={setValue}
									isRequired={true}
								/>
							</Form.Group>
						</Col>
						<Col md={4} className="mb-3">
							<Form.Group controlId="formBasicEmail">
								<Form.Label className="mb-0">Designation</Form.Label>
								{/* <Form.Control
									type="text"
									placeholder="Enter post"
									{...register(`post_en`, {
										required: true,
									})}
									className={`textbox_req`}
									defaultValue={userDetail && userDetail.post_en}
								/> */}
								<InputField
									css={InputFieldStyleOverwrite}
									type="text"
									className={`textbox_req`}
									language={'english'}
									classNameName={`input`}
									elementId={`post_en`}
									placeholder={'Enter Post'}
									maxLength={50}
									register={register}
									setValue={setValue}
									isRequired={true}
								/>
							</Form.Group>
						</Col>
						<Col md={4} className="mb-3">
							<Form.Group controlId="formBasicEmail">
								<Form.Label className="mb-0">भूमिका</Form.Label>
								<Form.Control
									as={'select'}
									{...register('role', {
										required: true,
									})}
									css={InputFieldStyleOverwrite}
									required
								>
									<option value="">भूमिका छान्नुहोस्</option>
									<option value="ward_user">शाखा प्रयोगकर्ता</option>
									<option value="ward_admin">शाखा प्रमुख</option>
									{/* <option value="ward_user">थप प्रयोगकर्ता</option> */}
									<option value="ward_kawa">शाखा का.वा</option>
								</Form.Control>
							</Form.Group>
						</Col>
					</Row>
					<div className="d-flex justify-content-end align-items-center">
						<Button
							variant="secondary"
							className="mr-2"
							onClick={() => {
								setShowAdd(!showAdd);
								setUserDetail(null);
							}}
						>
							रद्द गर्नुहोस्
						</Button>
						<Button type="submit" variant="success">
							पेश गर्नुहोस्
						</Button>
					</div>
				</Card.Body>
			</Card>
		</Form>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	postWardUser,
	patchWardUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWardUser);
