import React, { Fragment, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  FaArrowLeft,
  FaArrowRight,
  FaEdit,
  FaFileUpload,
  FaStickyNote,
  FaUser,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

const TemplateSingle = () => {
  const [step, setStep] = useState(1);
  return (
    <Fragment>
      <Row className="mt-3">
        <Col md={12}>
          <div className="p-relative">
            <div className="wizard-steps">
              <div
                className={
                  step === 1
                    ? `wizard-step wizard-step-active pointer`
                    : `wizard-step pointer`
                }
                onClick={() => setStep(1)}
              >
                <div className="wizard-step-icon mr-3">
                  <FaUser className="mb-0" size="34px" />
                </div>
                <h5>निवेदकको विवरण</h5>
              </div>
              <div
                className={
                  step === 2
                    ? `wizard-step wizard-step-active pointer`
                    : `wizard-step pointer`
                }
                onClick={() => setStep(2)}
              >
                <div className="wizard-step-icon mr-3">
                  <FaStickyNote size="34px" />
                </div>
                <h5>सिफारिस</h5>
              </div>
              <div
                className={
                  step === 3
                    ? `wizard-step wizard-step-active pointer`
                    : `wizard-step pointer`
                }
                onClick={() => setStep(3)}
              >
                <div className="wizard-step-icon mr-3">
                  <FaEdit />
                </div>
                <h5>निवेदन</h5>
              </div>
              <div
                className={
                  step === 4
                    ? `wizard-step wizard-step-active pointer`
                    : `wizard-step pointer`
                }
                onClick={() => setStep(4)}
              >
                <div className="wizard-step-icon mr-3">
                  <FaFileUpload />
                </div>
                <h5>कागजात अप्लोड</h5>
              </div>
            </div>
            <div className="nav-buttons d-flex justify-content-between w-100">
              {step > 1 ? (
                <Button
                  className="btn btn-icon icon-left btn-primary mr-2 btn-left"
                  onClick={() => setStep(step - 1)}
                >
                  <FaArrowLeft /> Prev
                </Button>
              ) : (
                <div></div>
              )}
              {step < 4 ? (
                <Button
                  className="btn btn-icon icon-right btn-primary btn-right"
                  onClick={() => setStep(step + 1)}
                >
                  Next <FaArrowRight />
                </Button>
              ) : (
                <Link
                  to="/template-old"
                  className="btn btn-icon icon-right btn-primary btn-right"
                >
                  Save <FaArrowRight />
                </Link>
              )}
            </div>
          </div>
        </Col>
      </Row>
      {step === 1 && <Step1 />}
      {step === 2 && <Step2 />}
      {step === 3 && <Step3 />}
      {step === 4 && <Step4 />}
    </Fragment>
  );
};

export default TemplateSingle;
