/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
//eslint-disable-next-line no-unused-vars
import { Fragment, useState, useRef, useEffect } from 'react';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import { AiOutlineFile, AiFillCloseCircle, AiFillEdit } from 'react-icons/ai';
import { FiSave } from 'react-icons/fi';
import { useSelector } from 'react-redux';
// import NepaliDate from 'nepali-date-converter';
// import ContentEditable from "react-contenteditable";
import ContentEditable from './ContentEditable';
import { normalizeHtml } from '../../_helpers/methods';

import { useHistory } from 'react-router';
// import { Link } from 'react-router-dom';
import { nepaliNumber } from '../../_helpers/methods';
// import QRCode from "qrcode.react";
import { VscLoading } from 'react-icons/vsc';
import styled from 'styled-components';
import { fetchActiveDesignTemplate, fetchSingleDesignTemplate } from '../../_redux/slices/designTemplates';
import { connect } from 'react-redux';
import { generatePreview } from '../../_helpers/methods';
import DefaultFooter from '../../_elements/DefaultFooter';

const PreviewModel = ({
	show,
	handleClose,
	paper,
	files = {},
	previewData,
	documents = [],
	previewFormData,
	handlePrint,
	innerRef,
	handleTemplateSave,
	config = {},
	preview,
	meta,
	isEnglish,
	paperExtra,
	isCitizenTemplate,
	paperId,
	isApproved,
	isSaved,
	handleIsSaved,
	issueId,
	handleIssueId,
	wardAdmin,
	fetchActiveDesignTemplate,
	fetchSingleDesignTemplate,
	issueCount,
	darta_number,
	templateHeader
}) => {
	const user = useSelector((state) => state.users.user);
	// const fiscal = useSelector((state) => state.fiscal);
	const { activeDesignTemplate, singleTemplate } = useSelector(
		(state) => state.designTemplates
	);

	const history = useHistory();
	const [previewOuterHtml, setPreviewOuterHtml] = useState(
		normalizeHtml(previewData)
	);

	const [printSelect, setPrintSelect] = useState('preview');
	let wardAdminRole = '';
	let wardAdminName = '';

	useEffect(() => {
		if (templateHeader) {
			fetchSingleDesignTemplate(templateHeader);
		}
		else {
			fetchActiveDesignTemplate();
		}
	}, [fetchActiveDesignTemplate, fetchSingleDesignTemplate, templateHeader]);

	const [designTemplate, setDesignTemplate] = useState({
		header: undefined,
		footer: undefined,
	});
	useEffect(() => {
		if (templateHeader && singleTemplate) {
			let headerPreview = generatePreview(
				{ preview: singleTemplate?.content, meta: singleTemplate?.meta },
				singleTemplate?.meta,
				user,
				darta_number,
				paperExtra,
				true
			);
			setDesignTemplate((prev) => ({ ...prev, header: headerPreview }));
		}
		else if (activeDesignTemplate?.length > 0) {
			let header = activeDesignTemplate?.find((item) => item.choices === 'header');
			let footer = activeDesignTemplate?.find((item) => item.choices === 'footer');
			let tempHeaderPreview = header
				? generatePreview(
					{ preview: header.content, meta: header.meta },
					header.meta,
					user,
					darta_number,
					paperExtra,
					true
				)
				: undefined;
			let tempFooterPreview = footer
				? generatePreview(
					{ preview: footer.content, meta: footer.meta },
					footer.meta,
					user
				)
				: undefined;
			setDesignTemplate(() => ({
				header: tempHeaderPreview,
				footer: tempFooterPreview,
			}));
		}
	}, [activeDesignTemplate, user, darta_number, paperExtra, templateHeader, singleTemplate]);

	const inputRef = useRef();

	const [isSaveAndPrintLoading, setIsSaveAndPrintLoading] = useState(false);

	const handleSave = async () => {
		setIsSaveAndPrintLoading(true);
		let response = await handleTemplateSave(previewFormData, previewOuterHtml);
		if (response) {
			history.push('/papers/verified/1');
			handleIsSaved(true);
		}
		setIsSaveAndPrintLoading(false);
	};

	const handleSaveAndPrint = async () => {
		try {
			setIsSaveAndPrintLoading(true);
			let response = await handleTemplateSave(
				previewFormData,
				previewOuterHtml,
				true
			);
			if (response) {
				if (handleIsSaved) {
					handleIsSaved(true);
				}
				handleIssueId(response?.data?.issue_id);
				handlePrint();
			}
			setIsSaveAndPrintLoading(false);
		} catch (err) {
			console.log(err, 'err ');
			setIsSaveAndPrintLoading(false);
		}
	};

	// For QR
	// let QRValue;
	// let applicantName;
	// if (isEnglish) {
	//   if (previewFormData.fullname_en) {
	//     applicantName = `${previewFormData.fullname_en.firstName} ${previewFormData.fullname_en.middleName} ${previewFormData.fullname_en.lastName} `;
	//   } else {
	//     applicantName = "";
	//   }
	//   QRValue = `
	//   Application Date: ${y}/${m}/${d},
	//     Invoice Token: ${previewFormData?.invoice_token || ""},
	//     Ward name: ${user?.ward_info?.name_en || ""},
	//     Ward address: ${user?.ward_info?.address_en || ""},
	//     Applicant Name:${applicantName}
	//   `;
	// } else {
	//   let applicantName;
	//   if (previewFormData.fullname_np) {
	//     applicantName = `${previewFormData.fullname_np.firstName} ${previewFormData.fullname_np.middleName} ${previewFormData.fullname_np.lastName} `;
	//   } else {
	//     applicantName = "";
	//   }
	//   QRValue = `
	//   जारी मिती: ${y}/${m}/${d},
	//   विधुतीय रशिद नं.: ${previewFormData?.invoice_token || ""},
	//   वडाको नाम :  ${user?.ward_info?.name_np || ""},
	//   वडाको ठेगाना: ${user?.ward_info?.address_np || ""},
	//   निवेदकको नाम: ${applicantName}
	//   `;
	// }
	if (paperExtra) {
		if (paperExtra?.paper_category === 'English Format') {
			wardAdminName =
				paperExtra.ward_kawa_fullname_en || paperExtra.ward_admin_fullname_en;
			wardAdminRole = paperExtra.ward_kawa_fullname_en
				? 'Act. Chairman'
				: 'Ward Admin';
		} else {
			wardAdminName =
				paperExtra.ward_kawa_fullname_np || paperExtra.ward_admin_fullname_np;
			wardAdminRole = paperExtra.ward_kawa_fullname_np
				? 'वडा का.वा'
				: 'वडा अध्यक्ष';
		}
	} else if (wardAdmin) {
		let [AdminName, AdminRole] = wardAdmin.split('_');
		wardAdminRole = AdminRole;
		wardAdminName = AdminName;
		console.log(wardAdminRole, wardAdminName);
	}

	return (
		<Modal
			id="preview"
			show={show}
			onHide={handleClose}
			dialogClassName="fullscreen-modal"
		>
			<Modal.Body className="position-relative">
				<Tabs
					defaultActiveKey="preview"
					className={`position-fixed top-0 left-0 border-0 flex-column bg-transparent p-0 d-flex justify-content-start `}
					style={{ gap: '1rem' }}
					onClick={(e) =>
						e.target.innerText === 'Preview'
							? setPrintSelect('preview')
							: e.target.innerText === 'Documents' &&
							setPrintSelect('documents')
					}
				>
					<Tab
						eventKey="preview"
						title="Preview"
						disabled={
							documents?.length === 0 && Object.keys(files)?.length === 0
								? true
								: false
						}
					>
						{previewData ? (
							<div
								className="page"
								ref={printSelect === 'preview' ? innerRef : undefined}
								css={css``}
							>
								{config.hasHeader && user && (
									<Fragment>
										{designTemplate.header ? (
											<div
												dangerouslySetInnerHTML={{
													__html: designTemplate.header,
												}}
											></div>
										) : (
											<div className="heading">
												<div className="d-flex justify-content-between align-items-stretch">
													<div className="d-flex flex-column justify-content-between">
														<div />
														{user?.nagarpalika_info?.nagarpalika_main_logo && (
															<img
																className="logo"
																css={css`
																	width: 150px;
																`}
																src={
																	user?.nagarpalika_info?.nagarpalika_main_logo
																}
																alt="main_logo"
															/>
														)}
														<div style={{ color: 'red' }}>
															<p>च. नं./Ref. No.:</p>
															<p>प. स./Dispatch No.:</p>
														</div>
													</div>

													<div className="title text-center">
														<h4 className="nagarpalika_name">
															{user?.nagarpalika_info?.nagarpalika_name_np}
														</h4>
														<h5 className="nagarpalika_name mb-2">
															{user?.nagarpalika_info?.nagarpalika_name_en}
														</h5>
														<div className="d-flex justify-content-center align-items-center">
															<h3 className="woda-title mb-0">
																{user?.nagarpalika_info?.nagarpalika_name_np?.includes(
																	'गाउँ'
																)
																	? 'गाउँ'
																	: 'नगर'}{' '}
																कार्यपालिकाको कार्यालय
															</h3>
														</div>
														<h4 className="woda-title mb-2">
															Office of{' '}
															{user?.nagarpalika_info?.nagarpalika_name_np?.includes(
																'गाउँ'
															)
																? 'Rural'
																: ''}{' '}
															Municipal Executive
														</h4>

														<h5 className="ward_address">
															<span className="">
																{user?.nagarpalika_info?.nagarpalika_address_np}
															</span>
															&nbsp;(
															{user.nagarpalika_info?.nagarpalika_address_en})
														</h5>
													</div>

													{/* {user.nagarpalika_info?.nagarpalika_campaign_logo ? (
														<img
															className="sidelogo"
															css={css`
																width: 150px;
															`}
															src={user.nagarpalika_info?.nagarpalika_campaign_logo}
															alt="campaign_logo"
														/>
													) : (
														<div style={{ width: '20%' }}></div>
													)} */}

													<div
														style={{ width: '150px', color: 'red' }}
														className="d-flex flex-column justify-content-end align-items-center"
													>
														<p className="text-center">
															{user.nagarpalika_info?.province_np}, नेपाल
														</p>
														<p
															style={{ fontSize: '0.8rem' }}
															className="text-center"
														>
															{user.nagarpalika_info?.province_en}, Nepal
														</p>
													</div>
												</div>

												<hr style={{ margin: 0, marginTop: '1rem' }} />
											</div>
										)}
									</Fragment>
								)}

								{issueCount > 0 ? (
									<div
										css={css`
											position: absolute;
											top: 1cm;
											right: 1.5cm;
											z-index: 5;
											font-size: 1rem;
										`}
									>
										प्रतिलिपि {nepaliNumber(issueCount)}
									</div>
								) : undefined}

								{issueId && (
									<div
										css={css`
											position: absolute;
											top: 28cm;
											left: -2cm;
											transform: rotate(90deg);
											z-index: 5;
											font-size: 1rem;
										`}
									>
										{issueId}
									</div>
								)}
								<table className="w-100 content  content-border">
									{/* {config.hasHeader && user && user && (
										<thead>
											<tr>
												<td>
													<div className="header-space">&nbsp;</div>
												</td>
											</tr>
										</thead>
									)} */}

									<tbody>
										<tr>
											<td>
												<div>
													<ContentEditable
														id="preview"
														autoComplete="off"
														autoCorrect="off"
														autocapiatlise="off"
														spellCheck="false"
														innerRef={inputRef}
														html={
															previewOuterHtml ? previewOuterHtml : previewData
														}
														disabled={true}
														onChange={(e) => {
															let tempValue = e.target.value;
															setPreviewOuterHtml(tempValue);
														}}
														isEnglish={isEnglish}
														tagName="div"
													/>
												</div>
												{/* {config.hasHeader && (
													<div className="col-12 d-flex justify-content-end">
														<div className="text-right">
															<div className="w-100 border-bottom border-dark border-dotted mb-2"></div>
															<div>{wardAdminName}</div>
															<div className="text-red">{wardAdminRole}</div>
														</div>
													</div>
												)} */}
											</td>
										</tr>
									</tbody>
									{config.hasFooter && user && (
										<tfoot>
											<tr>
												<td>
													<div className="footer-space">&nbsp;</div>
												</td>
											</tr>
										</tfoot>
									)}
								</table>

								{config.hasFooter && user && user && designTemplate.footer ? (
									<div
										dangerouslySetInnerHTML={{ __html: designTemplate.footer }}
									></div>
								) : (
									<DefaultFooter
										isEnglish={isEnglish}
									/>
								)}
							</div>
						) : (
							paper
						)}
					</Tab>

					{(documents?.length !== 0 || Object.keys(files)?.length !== 0) && (
						<Tab eventKey="document" title="Documents">
							<div
								ref={printSelect === 'documents' ? innerRef : undefined}
								className="page"
							>
								<div className="content">
									{files &&
										Object.values(files).map((file, i) => (
											<Fragment key={i}>
												<div>
													<div className="image-content">
														<img
															className="content-image mb-4"
															src={URL.createObjectURL(file)}
															alt="doc"
														/>
													</div>
												</div>
												{i !== files.length - 1 && (
													<div className="break-page"></div>
												)}
											</Fragment>
										))}
									{documents?.length > 0 &&
										documents?.map((document, i) => (
											<Fragment key={document.id}>
												<div key={document.id}>
													<h4 className="text-primary my-4 text-center">
														{document.title}
													</h4>
													<div className="image-content">
														<img
															className="content-image"
															src={document?.file}
															alt="doc"
														/>
													</div>
												</div>
												{i !== documents.length - 1 && (
													<div className="break-page"></div>
												)}
											</Fragment>
										))}
									{/* {documents?.length === 0 && Object.keys(files)?.length === 0 && (
										<div style={{ height: '75vh' }} className="d-flex justify-content-center align-items-center">
											<h5 className="text-center text-danger">No documents to show</h5>
										</div>
									)} */}
								</div>
							</div>
						</Tab>
					)}
				</Tabs>
				<Actions>
					{preview ? (
						<Fragment>
							<Button
								variant="success"
								className="px-3 mx-2 flex-center"
								disabled={isSaveAndPrintLoading}
								onClick={handlePrint}
							>
								<Fragment>
									<FaPrint className="mr-2" />
									Print
								</Fragment>
							</Button>
						</Fragment>
					) : (
						<Fragment>
							<Fragment>
								{isCitizenTemplate && isApproved ? (
									<Button
										className="mx-2 d-flex justify-content-center align-items-center"
										onClick={() => {
											history.push(`/paper-single/${paperId}/citizen`);
										}}
										disabled={isSaveAndPrintLoading}
									>
										<Fragment>
											<AiFillEdit className="mr-2" />
											Edit
										</Fragment>
									</Button>
								) : (
									<Button
										className="mx-2"
										onClick={() => {
											handleClose();
										}}
										disabled={isSaveAndPrintLoading}
									>
										<Fragment>
											<AiFillEdit className="mr-2" />
											Edit
										</Fragment>
									</Button>
								)}
							</Fragment>

							<Fragment>
								{paperId ? (
									<Fragment>
										{isApproved ? (
											<Button
												variant="success"
												className="px-3 mx-2 flex-center"
												onClick={handlePrint}
											>
												<Fragment>
													<FaPrint className="mr-2" />
													Print
												</Fragment>
											</Button>
										) : (
											<Button
												variant="success"
												className="px-3 mx-2 flex-center"
												disabled={isSaveAndPrintLoading}
												onClick={handleSaveAndPrint}
											>
												<Fragment>
													<FaPrint className="mr-2" />
													Update and Print
												</Fragment>
											</Button>
										)}
									</Fragment>
								) : (
									<Fragment>
										<Button
											variant="primary"
											className="px-3 mx-2 flex-center"
											disabled={isSaveAndPrintLoading}
											onClick={handleSave}
										>
											<Fragment>
												<FiSave className="mr-2" />
												Save
											</Fragment>
										</Button>
										{isSaved ? (
											<Button
												variant="success"
												className="px-3 mx-2 flex-center"
												onClick={handlePrint}
											>
												<Fragment>
													<FaPrint className="mr-2" />
													Print
												</Fragment>
											</Button>
										) : (
											<Button
												variant="success"
												className="px-3 mx-2 flex-center "
												disabled={isSaveAndPrintLoading}
												onClick={handleSaveAndPrint}
											>
												<Fragment>
													{isSaveAndPrintLoading && (
														<VscLoading className="spin" />
													)}
													<FaPrint className="mr-2" />
													Save and Print
												</Fragment>
											</Button>
										)}
									</Fragment>
								)}
							</Fragment>

							<Button
								variant="secondary"
								className="px-3 mx-2 flex-center"
								onClick={() => {
									history.push('/templates/1');
								}}
								disabled={isSaveAndPrintLoading}
							>
								<Fragment>
									<AiOutlineFile className="mr-2" />
									Create New
								</Fragment>
							</Button>
						</Fragment>
					)}
					<Button
						variant="secondary"
						className="px-3 mx-2 flex-center"
						disabled={isSaveAndPrintLoading}
						onClick={handleClose}
					>
						<Fragment>
							<AiFillCloseCircle className="mr-2" />
							Close
						</Fragment>
					</Button>
					{/* <label
						htmlFor="eng"
						css={css`
							width: 100%;
						`}
					>
						<div
							css={css`
								background-color: #0c8a72;
								color: white;
							`}
							className="mx-2 px-3 py-2"
						>
							<Form.Check
								type="switch"
								id="eng"
								label={'Type in English'}
								checked={english}
								onChange={() => {
									setEnglish(!english);
								}}
							/>
						</div>
					</label> */}
				</Actions>
			</Modal.Body>
		</Modal>
	);
};

export default connect(null, { fetchActiveDesignTemplate, fetchSingleDesignTemplate })(PreviewModel);

const Actions = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	/* transform: translateY(-50%); */
	width: max-content;
	height: max-content;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: stretch;
	gap: 1rem;
	padding: 1.5rem;
	/* background-color: red; */
`;

/* 
				  Disabled for now
				  <div
					css={css`
					  position: relative;
					`}
				  >
					<div
					  css={css`
						position: absolute;
						right: 0;
						top: -13px;
						width: 2.5cm;
						height: 2.5cm;
					  `}
					>
					  <QRCode value={QRValue} size={90} />
					</div>
				  </div> */
