import React from "react";
import InputField from "../../InputField";

import { ResizableInput } from "../../../_elements";

function InputFields({ element, elementId, fields, language, register }) {
  let elementType = fields[elementId].type;

  switch (elementType) {
    case "select":
      return register ? (
        <select {...register(elementId)}>
          {fields[elementId].options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.value}
            </option>
          ))}
        </select>
      ) : (
        <select>
          {fields[elementId].options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.value}
            </option>
          ))}
        </select>
      );
    case "text":
      return (
        <ResizableInput
          type="text"
          fields={fields}
          element={element}
          language={language}
          maxLength="50"
          register={register}
          elementId={elementId}
        />
      );
    case "number":
      return (
        <ResizableInput
          type="text"
          fields={fields}
          element={element}
          language={language}
          register={register}
          elementId={elementId}
          maxLength="20"
        />
      );
    case "date":
      return (
        <InputField
          className="dateInput "
          {...fields[elementId]}
          type="date"
          maxLength={10}
          language={language}
          register={register}
          elementId={elementId}
        />
      );
    default:
      return <span></span>;
  }
}

export default InputFields;
