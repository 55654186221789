/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaEnvelope, FaPhone } from 'react-icons/fa';

const Support = () => {
	return (
		<Fragment>
			<Card
				css={css`
					border-radius: 8px;
					box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
				`}
			>
				<Card.Body className="d-flex">
					<Col md={6}>
						<h3 className="main-title mb-3">Welcome to our Support page</h3>
						<p className="text-muted">
							यहाँलाई Idea Breed द्वारा निर्मित संघ संस्था दर्ता प्रणालीको
							Support Page मा हार्दिक स्वागत छ। कृपया एकीकृत प्रणाली बारे
							बुझ्नको लागि निम्न फोन नम्बरमा कल गर्नुहोस् वा निम्न इमेल ठेगानामा
							इमेल गर्नुहोस्।
						</p>
						<div>
							<Row>
								<Col md={6}>
									<Card>
										<Card.Body className="d-flex align-items-center">
											<FaPhone className="text-primary mr-3" />
											<p className="text-primary">9808424754</p>
										</Card.Body>
									</Card>
								</Col>
								<Col md={6}>
									<Card>
										<Card.Body className="d-flex align-items-center">
											<FaEnvelope className="text-danger mr-3" />
											<p className="text-danger">support@ideabreed.net</p>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</div>
					</Col>
					<Col md={6}>
						<img
							src="./assets/images/support.png"
							className="support-img"
							alt="support"
						/>
					</Col>
				</Card.Body>
			</Card>
		</Fragment>
	);
};

export default Support;
