import Verification from '../Verification/Verification';

const SifarishVerification = () => {
	return (
		<div className="main-wrapper">
			<div className="content-wrapper citizen-sifarish-verification">
				<Verification />
			</div>
		</div>
	);
};

export default SifarishVerification;
