import styled from 'styled-components';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

export const Eye = styled.span`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20px;
	width: 20px;
	right: 2%;
	bottom: 10%;
	cursor: pointer;
	svg {
		color: grey;
	}
`;

export const FormInput = styled(Form.Control)`
	display: block;
	width: 100% !important;
	float: none !important;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem !important;
	font-weight: 400 !important;
	line-height: 1.5 !important;
	color: #495057 !important;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	*/ &.mini-field {
		height: calc(1.5em + 0.75rem + 2px);
		padding: 3px 4px;
		border-radius: 0.25rem;

		border: 1px solid #ccc;
		width: 164px;
	}
`;

const StyledPassword = styled(Form.Group)`
	position: relative !important;
`;
function Password({ id, register, className, placeholder, isRequired, css = null }) {
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	return (
		<StyledPassword>
			<FormInput
				{...(css ? { css: css } : {})}
				type={showCurrentPassword ? 'text' : 'password'}
				{...register(id, { required: isRequired })}
				placeholder={placeholder}
				autoComplete="new-password"
				className={className}
			/>
			<Eye>
				{showCurrentPassword ? (
					<AiFillEyeInvisible onClick={() => setShowCurrentPassword(false)} />
				) : (
					<AiFillEye onClick={() => setShowCurrentPassword(true)} />
				)}
			</Eye>
		</StyledPassword>
	);
}

export default Password;
