import React, { useEffect, useState } from "react";
import { FaChevronCircleRight } from "react-icons/fa";
import DistrictModal from "./Modals/DistrictModal/DistrictModal";
import { StyledSelect } from "./styles/StyledSelect";
import { districts } from "../../../../_datas/districts";

function District({
  data,
  systemLanguage,
  language,
  register,
  elementId,
  properties = {},
  errors = {},
  validation,
  setValue,
  value,
  isPublic,
  entity,
}) {
  const [districtCode, setDistrictCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [district, setDistrict] = useState(() => {
    if (value) {
      return value;
    }
    return "";
  });
  useEffect(() => {
    if (district) {
      for (let key in districts) {
        if (districts[key][language].indexOf(district) !== -1) {
          setDistrictCode(key);
        }
      }
    }
  }, [district, language]);
  if (isPublic && entity === "private") {
    return null;
  }

  const registerDistrictValueChange = (value) => {
    setDistrict(value);
    setValue(elementId, value);
  };
  const getDistrictCode = (searchVal, language) => {
    for (let key in districts) {
      if (districts[key][language].indexOf(searchVal) !== -1) {
        return key;
      }
    }
  };
  const handleDistrictChangeBySelect = (value) => {
    let code = getDistrictCode(value, language);
    setDistrictCode(code);
    registerDistrictValueChange(value);
  };

  const handleDistrictChangeByCode = (value) => {
    if (value > Object.keys(districts).length) return null;
    if (value < 0) return null;
    setDistrictCode(value);
    try {
      registerDistrictValueChange(districts[value][language]);
    } catch (err) {}
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  return (
    <StyledSelect>
      <label>{data.label} :</label>
      <div className="inputDiv">
        <input
          className="small"
          type="number"
          value={districtCode}
          onChange={(e) => {
            handleDistrictChangeByCode(e.target.value);
          }}
          disabled={properties.readOnly}
        />
        <span>
          <select
            id={elementId}
            value={district}
            {...register(elementId, validation)}
            className={`mx-1 form-control formField ${
              properties?.isRequired ? "textbox_req" : ""
            } ${errors[elementId] ? "is-invalid" : ""} `}
            onChange={(e) => {
              handleDistrictChangeBySelect(e.target.value);
            }}
            disabled={properties.readOnly}
          >
            {language === "nepali"
              ? Object.keys(districts).map((code) => (
                  <option key={code} value={districts[code]["nepali"]}>
                    {districts[code]["nepali"]}
                  </option>
                ))
              : Object.keys(districts).map((code) => (
                  <option key={code} value={districts[code]["english"]}>
                    {districts[code]["english"]}
                  </option>
                ))}
          </select>
        </span>

        <span>
          <FaChevronCircleRight fill="#999999" onClick={handleShowModal} />
        </span>

        {showModal && (
          <DistrictModal
            show={showModal}
            handleClose={handleHideModal}
            districts={districts}
            language={language}
            handleDistrictChangeByCode={handleDistrictChangeByCode}
          />
        )}
      </div>
    </StyledSelect>
  );
}

export default District;
