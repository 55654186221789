/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment, useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { connect } from 'react-redux';

import { MdLocationOn } from 'react-icons/md';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import NagarpalikaDetailEditModal from './NagarpalikaDetailEditModal';
import { HiOfficeBuilding } from 'react-icons/hi';
import { hasSSO } from '../../_helpers/methods';

function NagarpalikaDetails({ language }) {
	const [nagarpalikas, setNagarpalikas] = useState([]);
	const [lang, setLang] = useState('np');
	const [show, setShow] = useState(false);
	const [selectedNagarpalika, setSelectedNagarpalika] = useState(null);

	const showEditModal = (id) => {
		setShow(true);
		let tempSelectedNagarpalika = nagarpalikas.filter(
			(nagarpalika) => nagarpalika.id === id
		);
		setSelectedNagarpalika(tempSelectedNagarpalika[0]);
	};

	useEffect(() => {
		if (language === 'nepali') {
			setLang('np');
		} else {
			setLang('en');
		}
	}, [language]);

	useEffect(() => {
		const getNagarpalikas = async () => {
			const response = await fetchWrapper.get('/nagarpalika/');
			setNagarpalikas(response.data.results);
		};
		getNagarpalikas();
	}, []);

	const handleClose = () => {
		setShow(false);
	};

	return (
		<Fragment>
			<Row className="mt-2">
				{nagarpalikas.map((nagarpalika) => {
					return (
						<Col md={3}>
							<div
								datatype="nagarpalikaDetail"
								className="icon-container text-center"
							>
								{/* <span className="settings-badge position-absolute">1</span> */}
								<div className="d-flex justify-content-center align-items-center">
									<div className="icon">
										<div
											css={css`
												width: 100%;
												max-width: 150px;
												margin: 0 auto 1rem auto;
											`}
										>
											{nagarpalika['main_logo'] ? (
												<img
													css={css`
														width: 100%;
													`}
													src={nagarpalika['main_logo']}
													alt={nagarpalika[`name_${lang}`]}
												/>
											) : (
												<HiOfficeBuilding fontSize="48" color="#6777ef" />
											)}
										</div>
										<h4 className="card-text">
											{nagarpalika[`name_${lang}`]}{' '}
										</h4>
										<p className="card-text">
											<MdLocationOn />
											{nagarpalika[`address_${lang}`]}{' '}
										</p>
										<br />

										<p>{nagarpalika[`slogan_${lang}`]}</p>
										{/* <p>वडा: {nagarpalika?.ward_count}</p> */}
										{/* <p>शाखा: {nagarpalika?.sakha_count}</p> */}
										{!hasSSO() && (
											<Button
												variant="link"
												onClick={() => showEditModal(nagarpalika.id)}
											>
												<FaEdit className="text-info mr-2" />
											</Button>
										)}
									</div>
								</div>
							</div>
						</Col>
					);
				})}
			</Row>
			<NagarpalikaDetailEditModal
				show={show}
				handleClose={handleClose}
				lang={lang}
				selectedNagarpalika={selectedNagarpalika}
			/>
		</Fragment>
	);
}

const mapStateToProps = (state) => ({
	language: state.language.language,
});

export default connect(mapStateToProps)(NagarpalikaDetails);
