import React, { useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { fetchWrapper } from '../../../../_helpers/fetchWrapper';
import { toast } from 'react-toastify';

// key={ele.id}
// 						elementId={ele.id}
// 						data={ele}
// 						label={ele.label}
// 						register={register}
// 						setValue={setValue}
// 						value={values[ele.id]}
// 						errors={errors}
// 						properties={ele.properties}
// 						isPublic={isPublic}
// 						validation={validation}
// 						entity={ele.entity}

const ImageUploader = ({ elementId, label, register, setValue, value }) => {
	const [showCamera, setShowCamera] = useState(false);
	const [image, setImage] = useState(null);
	const cameraRef = useRef(null);

	function openCamera() {
		setShowCamera(true);
		let devices = window.navigator.mediaDevices;
		if (!devices || !devices.getUserMedia) {
			alert('Devices not Supported');
			return;
		}
		devices
			.getUserMedia({
				video: true,
			})
			.then((stream) => {
				window.localStream = stream;
				cameraRef.current.srcObject = window.localStream;
				cameraRef.current.onloadedmetadata = (e) => {
					cameraRef.current.play();
				};
			})
			.catch((e) => console.log(e.message));
	}

	async function closeCamera() {
		window.localStream.getTracks().forEach((track) => track.stop());
		setShowCamera(false);
	}

	function captureImage() {
		let canvas = document.createElement('canvas');
		canvas.width = 150;
		canvas.height = 175;
		canvas
			.getContext('2d')
			.drawImage(cameraRef?.current, 0, 0, canvas.width, canvas.height);
		let imageUrl = canvas.toDataURL('image/jpg');
		setImage(imageUrl);
		canvas.toBlob(async (blob) => {
			let formdata = new FormData();

			formdata.append('title', label);
			formdata.append('file', blob, label + '.png');
			const response = await fetchWrapper.post(
				'/add-documents/',
				formdata,
				true
			);
			console.log(await response?.data?.file);
			setValue(elementId, response?.data?.file);
		}, 'image/png');
		closeCamera();
	}

	const handleOnChange = async (event) => {

		let formdata = new FormData();
		const MAX_FILE_SIZE = 2120 // 2MB
		const selectedFile = event.target.files && event.target.files[0];


		const fileSizeKiloBytes = selectedFile.size / 1024;

		if (fileSizeKiloBytes > MAX_FILE_SIZE) {
			return toast.error('File size exceed, File must be less than 2MB');
		}
		setImage(URL.createObjectURL(selectedFile));

		formdata.append('title', label);
		formdata.append('file', selectedFile, label + '.png');
		const response = await fetchWrapper.post(
			'/add-documents/',
			formdata,
			true
		);
		setValue(elementId, response?.data?.file);
	}

	return (
		<>
			<div className="py-2 d-flex align-items-center">
				<label htmlFor={elementId}>{label} : </label>
				<div>
					<Button className="ml-2" size="sm" onClick={openCamera}>
						Camera
					</Button>
					<input className='mt-2' type='file' onChange={handleOnChange} />
				</div>

				{image && (
					<img
						src={image}
						alt=""
						className="ml-2 object-fit-contain"
						style={{ height: '100px', width: '75px' }}
					/>
				)}
			</div>
			<input type="text" {...register(elementId)} value={value} hidden />

			{showCamera && (
				<Modal show={showCamera} onHide={closeCamera}>
					<Modal.Header className="d-flex align-items-center justify-content-between">
						<p>Camera</p>
						<span onClick={closeCamera}>
							<FaTimes />
						</span>
					</Modal.Header>
					<Modal.Body>
						<video
							ref={cameraRef}
							style={{
								height: '50vh',
								width: '100%',
								border: '1px dotted gray',
							}}
						/>
						<button className="" onClick={captureImage}>
							Capture
						</button>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};

export default ImageUploader;
