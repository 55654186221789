// import React, { useEffect, useState } from 'react';
// import { fetchWrapper } from '../_helpers/fetchWrapper';
// import { StyledDashboard } from './styles/StyledDashboard';
// import { FiCopy } from 'react-icons/fi';
// import { RiFilePaper2Line, RiCommunityLine } from 'react-icons/ri';
// import { GoPerson } from "react-icons/go";
// import { Link } from 'react-router-dom';
// import { englishToNepaliNumber as np } from 'nepali-number';
// import { FaBuilding } from 'react-icons/fa';
// import { Row } from 'react-bootstrap';
// import { VscLoading } from 'react-icons/vsc';
// import { useSelector } from 'react-redux';
// import { languageSelector } from '../_redux/slices/languages';
// import { Fragment } from 'react';
import OverviewDashboard from './OverviewDashboard';
// import TemplateCard from '../components/TemplateCard/TemplateCard';

const Box = ({ title, icon = null, number }) => {
	return (
		<div className="dashboard-stats-box">
			<div className="dashboard-stats-box-content">
				<h5>{title}</h5>
			</div>
			<h5 className="dashboard-number">
				<kbd className="red-shade">{number}</kbd>
			</h5>
			<span>
				<div className="dashboard-stats-box-icon">{icon}</div>
			</span>
		</div>
	);
};
export const colorClass = ['purple-shade', 'yellow-shade', 'red-shade', 'orange-shade', 'green-shade', 'sky-shade'];

const Dashboard = () => {
	// const [dashBoardInfo, setDashBoardInfo] = useState(null);
	// const [pinnedTemplates, setPinnedTemplates] = useState(null);
	// const [isLoading, setIsLoading] = useState(null);
	// const user = useSelector((state) => state.users.user);
	// const { language } = useSelector(languageSelector);
	// const role = user.role;

	// useEffect(() => {
	// 	const fetchPinnedTemplates = async () => {
	// 		try {
	// 			setIsLoading(true);
	// 			const response = await fetchWrapper.get('/pinned-templates/');
	// 			setPinnedTemplates(response.data);
	// 			setIsLoading(false);
	// 		} catch (err) {
	// 			setIsLoading(false);
	// 		}
	// 	};
	// 	fetchPinnedTemplates();
	// }, []);
	// const getDashBoardInfo = async () => {
	// 	try {
	// 		let response = await fetchWrapper.get('/dashboard-stats');
	// 		setDashBoardInfo(response.data);
	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// };
	// useEffect(() => {
	// 	getDashBoardInfo();
	// }, []);

	// if (role === 'ito_admin') {
	return <OverviewDashboard />;
	// }

	// return (
	// 	<Fragment>
	// 		<StyledDashboard>
	// 			<div className="row">
	// 				<div className="col-3">
	// 					<Link to="templates/1">
	// 						<Box
	// 							title={language === 'english' ? 'Sifarish Templates' : 'पत्रको ढाँचा'}
	// 							number={np(dashBoardInfo?.templates || 0)}
	// 							icon={<FiCopy />}
	// 						/>
	// 					</Link>
	// 				</div>
	// 				<div className="col-3">
	// 					<Link to="papers/verified/1">
	// 						<Box
	// 							title={language === 'english' ? 'Sifarish' : 'जारी भएका पत्र'}
	// 							number={np(dashBoardInfo?.papers || 0)}
	// 							icon={<RiFilePaper2Line />}
	// 						/>
	// 					</Link>
	// 				</div>
	// 				{user.role === 'ito_admin' ? (
	// 					<div className="col-3">
	// 						<Link to="/settings/allusers/1">
	// 							<Box
	// 								title={language === 'english' ? 'Ward office' : 'वडा'}
	// 								number={np(dashBoardInfo?.wards || 0)}
	// 								icon={<RiCommunityLine />}
	// 							/>
	// 						</Link>
	// 					</div>
	// 				) : null}
	// 				{user.role === 'ward_admin' ? (
	// 					<div className="col-3">
	// 						<Link to={`/settings/warduser/${user?.profile?.ward}`}>
	// 							<Box
	// 								title={language === 'english' ? 'My ward office' : 'मेरो वडा कार्यालय'}
	// 								number={np(dashBoardInfo?.wards || 0)}
	// 								icon={<FaBuilding />}
	// 							/>
	// 						</Link>
	// 					</div>
	// 				) : null}

	// 				<div className="col-3">
	// 					{/* <Link to="/">
	//             <Box
	//               title={language === "english" ? "Applicants" : "आवेदक"}
	//               icon={<GoPerson />}
	//               number={np(dashBoardInfo?.applicants?.total || 0)}
	//             />
	//           </Link> */}
	// 				</div>
	// 			</div>
	// 		</StyledDashboard>

	// 		<h5 className="main-title mt-5">प्राय प्रयोगमा आएका ढाँचाहरु </h5>

	// 		<Row className="mt-3">
	// 			{isLoading ? (
	// 				<div className="w-100 d-flex justify-content-center">
	// 					<VscLoading className="spin" fontSize="32" />
	// 				</div>
	// 			) : pinnedTemplates ? (
	// 				pinnedTemplates.map((template, idx) => (
	// 					<TemplateCard
	// 						title={template.title}
	// 						icon={template.icon}
	// 						categoryIcon={template.category_icon}
	// 						id={template.id}
	// 						key={template.id}
	// 						idx={idx % colorClass.length}
	// 						charge={template.charge}
	// 						additional_info={template.additional_info}
	// 					/>
	// 				))
	// 			) : (
	// 				<div>No pinned templates</div>
	// 			)}
	// 		</Row>
	// 	</Fragment>
	// );
};
export { Box };
export default Dashboard;
