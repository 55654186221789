/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useState, Fragment } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { FaEye, FaPlusCircle } from "react-icons/fa";
import BackButton from "../../../_elements/BackButton";
import AddApplicant from "./AddApplicant";

const Applicant = () => {
  const [showAdd, setShowAdd] = useState(false);

  const showApplicantForm = () => {
    setShowAdd(true);
  };
  const hideApplicantForm = () => {
    setShowAdd(false);
  };
  return (
    <Fragment>
      <Row className="mt-2">
        <Col md={12}>
          <BackButton />
        </Col>
      </Row>
      {console.log(showAdd)}
      {showAdd && (
        <Row className="mt-2">
          <Col md={12}>
            <AddApplicant
              setShowAdd={setShowAdd}
              showApplicantForm={showApplicantForm}
              hideApplicantForm={hideApplicantForm}
            />
          </Col>
        </Row>
      )}
      <Row className="mt-2">
        <Col md={12}>
          <Card>
            <Card.Header className="card-header-custom">
              <h2>आवेदकहरु</h2>
              <Button
                variant="primary"
                onClick={() => {
                  setShowAdd(!showAdd);
                }}
              >
                <FaPlusCircle />
                &nbsp; नयाँ आवेदक
              </Button>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr
                      css={css`
                        th {
                          text-align: center;
                        }
                      `}
                    >
                      <th>निवेदकको नाम</th>
                      <th>निवेदकको आईडी</th>
                      <th>वडा</th>
                      <th>निवेदकको फोन</th>
                      <th>अस्थायी ठेगाना</th>
                      <th>स्थाई ठेगाना</th>
                      <th>जम्मा सिफारिस</th>
                      <th className="text-center">कार्यहरू</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      css={css`
                        td {
                          text-align: center;
                        }
                      `}
                    >
                      <td>क्षितिज पौडेल</td>
                      <td>565656565656</td>
                      <td>८</td>
                      <td>९७५८०५५५५५</td>
                      <td>बाँसगढी नगरपालिका वडा नं ८</td>
                      <td>m bn nb</td>
                      <td>0</td>
                      <td className="text-center">
                        <Button variant="success" className="mr-1">
                          <FaEye /> निवेदकको विवरण
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Applicant;
