/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';

function AppTitle() {
	return (
		<div className="heading">
			<div className="d-flex justify-content-between align-items-center">
				<div className="title text-center mx-2 w-100">
					<div className="login-brand">
						<h1 css={css`
							color: #6677ef;
							font-size: 2rem;
							font-weight: 700;
						`}>
							संघ संस्था दर्ता प्रणाली
						</h1>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AppTitle;
