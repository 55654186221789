import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({
  component: Component,
  accessGrantedRoles,
  ...rest
}) => {
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const user = useSelector((state) => state.users.user);
  const role = user?.role;
  const sso =
    process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === 'true' || process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === true;

  return (
    <Route
      {...rest}
      render={(props) => {
        const localStorageUser = window.localStorage.getItem("user");
        if (!isLoggedIn || !localStorageUser) {
          if (sso && process.env.REACT_APP_SSO_URL && process.env.REACT_APP_SSO_LOGIN === 'true') {
            const redirectLink = document.createElement('a');
            let url = `http://localhost:3000`;
            if (process.env.NODE_ENV === "production") {
              url = `${process.env.REACT_APP_SSO_URL}`;
            }
            let link = `${url}/signout`;

            redirectLink.href = link;
            redirectLink.click();
          }
          return <Redirect to="/login" />;
        }
        if (user) {
          localStorage.removeItem('showLoginPage');

          if (accessGrantedRoles?.includes(role))
            return <Component {...props} />;
          return <Redirect to="/" />;
        }
      }}
    />
  );
};

// const FirstConnectedComponent = ContainerCreater(PrivateRoute);
// const SecondConnectedComponent = ContainerCreater(AuthRoute);

const FirstConnectedComponent = PrivateRoute;
// const SecondConnectedComponent = ContainerCreater(AuthRoute);

export {
  FirstConnectedComponent as PrivateRoute,
  // , SecondConnectedComponent as AuthRoute
};
