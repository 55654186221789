import Name from './Name';
import Checkbox from './Checkbox';
import Select from './Select';
import Date from './Date';
import Number from './Number';
import Text from './Text';
import Table from './Table';
import { Fragment } from 'react';
import { dynamicValidator } from '../../../../_helpers/dynamicValidator';
import District from './District';
import ImageUploader from './ImageUploader';

const FormGroup = ({
	data,
	register,
	control,
	values,
	errors,
	recursion,
	colSpan,
	setValue,
	systemLanguage,
	category,
	properties = {},
	isPublic,
	entity,
	language,
}) => {
	if (isPublic && entity === 'private') {
		return null;
	}
	const element = (ele) => {
		let validation = {};
		if (ele.properties) {
			validation = dynamicValidator(ele.properties);
		}
		switch (ele.key) {
			case 'FormGroup':
				return (
					<FormGroup
						key={ele.id}
						data={ele}
						register={register}
						recursion={true}
						values={values}
						setValue={setValue}
						errors={errors}
						category={category}
						isPublic={isPublic}
						language={language}
						entity={ele.entity}
						systemLanguage={systemLanguage}
					/>
				);
			case 'Checkbox':
				return (
					<Checkbox
						key={ele.id}
						text="कुनै शारीरिक विकृती छ"
						register={register}
						elementId={ele.id}
						data={ele}
						value={values[ele.id]}
						setValue={setValue}
						properties={ele.properties}
						isPublic={isPublic}
						entity={ele.entity}
					/>
				);
			case 'Date':
				return (
					<Date
						key={ele.id}
						label={ele.label}
						elementId={ele.id}
						register={register}
						errors={errors}
						value={values[ele.id]}
						language={language}
						setValue={setValue}
						properties={ele.properties}
						isPublic={isPublic}
						entity={ele.entity}
					/>
				);
			case 'Name':
				return (
					<Name
						key={ele.id}
						className="form-control align-left border"
						register={register}
						errors={errors}
						elementId={ele.id}
						data={ele}
						value={values[ele.id]}
						properties={ele.properties}
						popper={ele.popper}
						setValue={setValue}
						isPublic={isPublic}
						language={language}
						entity={ele.entity}
					/>
				);
			case 'Number':
				return (
					<Number
						key={ele.id}
						register={register}
						errors={errors}
						elementId={ele.id}
						label={ele.label}
						language={language}
						className=""
						placeholder={ele.placeholder}
						value={values[ele.id]}
						validation={validation}
						properties={ele.properties}
						setValue={setValue}
						isPublic={isPublic}
						entity={ele.entity}
					/>
				);
			case 'Select':
				return (
					<Select
						key={ele.id}
						label={ele.label}
						register={register}
						elementId={ele.id}
						options={ele.options}
						value={values[ele.id]}
						setValue={setValue}
						properties={ele.properties}
						isPublic={isPublic}
						entity={ele.entity}
					/>
				);
			case 'Text':
			case 'TextArea':
				return (
					<Text
						type={ele.key === 'Text' ? 'text' : 'textarea'}
						key={ele.id}
						register={register}
						elementId={ele.id}
						errors={errors}
						label={ele.label}
						language={language}
						className={ele.className}
						placeholder={ele.placeholder}
						value={values[ele.id]}
						setValue={setValue}
						properties={ele.properties}
						validation={validation}
						isPublic={isPublic}
						entity={ele.entity}
					/>
				);
			case 'Table':
				return (
					<Table
						key={ele.id}
						data={ele}
						language={language}
						register={register}
						control={control}
						setValue={setValue}
						value={values[ele.id]}
						category={category}
						isPublic={isPublic}
						entity={ele.entity}
					/>
				);

			case 'District':
				return (
					<District
						key={ele.id}
						elementId={ele.id}
						data={ele}
						systemLanguage={systemLanguage}
						language={language}
						register={register}
						setValue={setValue}
						value={values[ele.id]}
						errors={errors}
						properties={ele.properties}
						isPublic={isPublic}
						validation={validation}
						entity={ele.entity}
					/>
				);
			case 'Image':
				return (
					<ImageUploader
						key={ele.id}
						elementId={ele.id}
						data={ele}
						label={ele.label}
						register={register}
						setValue={setValue}
						value={values[ele.id]}
						errors={errors}
						properties={ele.properties}
						isPublic={isPublic}
						validation={validation}
						entity={ele.entity}
					/>
				);
			default:
				return null;
		}
	};
	return (
		<div
			key={data.id}
			className="member-registration-block vital-add-baby-block"
		>
			<div className={`title ${data?.noBorder ? 'noBorder' : ''}`}>
				<h2>{data.title}</h2>
				{data.options &&
					data.options.map((data) => (
						<div key={data.value} className="same-checkbox">
							<input
								data-val="true"
								data-val-required="The newbornIsForeign field is required."
								id="chkForeignNewBorn"
								name="newbornIsForeign"
								style={{ width: '20px', align: 'right' }}
								type="checkbox"
								value="true"
							/>
							<input name="newbornIsForeign" type="hidden" value="false" />
							{data.value}
						</div>
					))}
			</div>
			<div className="table" width="100%">
				{data.row.map((rowData, idx) => {
					const rowWithoutFormData = rowData.filter(
						(rowElement) => rowElement.key !== 'FormGroup'
					);
					const rowWithFormData = rowData.filter(
						(rowElement) => rowElement.key === 'FormGroup'
					);
					return (
						<Fragment>
							{rowWithoutFormData.length ? (
								<div className="row w-100 m-auto border-bottom justify-content-between align-items-center">
									{rowWithoutFormData.map((rowElement) => element(rowElement))}
								</div>
							) : null}
							{rowWithFormData.map((rowElement) => element(rowElement))}
						</Fragment>
					);
				})}
			</div>
		</div>
	);
};

export default FormGroup;
