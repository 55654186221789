/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { upperData, lowerData } from "./SidebarData";
import SidebarButtonRenderer from "./SidebarButtonRenderer";
import PaperTemplateRenderer from "./PaperTemplateRenderer";
import { checkPermission } from "../../_helpers/roles/check-roles";

const Sidebar = () => {
  const nagarpalika = useSelector((state) => state.nagarpalika.nagarpalika);
  const nagarpalikaInfo = useSelector(
    (state) => state.users?.user?.profile?.nagarpalika_info
  );
  const user = useSelector((state) => state.users?.user);
  const isSSO = process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === "true";
  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-top">
        <a
          href={
            isSSO
              ? process.env.REACT_APP_SSO_URL + "/application/user"
              : "/templates/1"
          }
        >
          <img
            src={nagarpalikaInfo?.main_logo || "/assets/images/nep_logo.png"}
            className="logo"
            alt=""
          />
        </a>

        {nagarpalikaInfo ? (
          <div>
            <h5>{nagarpalikaInfo.name_np}</h5>
            <p
              css={css`
                font-size: 1rem;
              `}
            >
              <Fragment>
                {user?.role === "ito_admin" ? (
                  <Fragment>
                    {nagarpalika?.name_np?.includes("गाउँ") ? "गाउँ " : "नगर "}
                    कार्यपालिकाको कार्यालय
                  </Fragment>
                ) : (
                  `${user?.profile?.ward_info?.name_np}`
                )}
              </Fragment>
            </p>
            <p
              css={css`
                font-size: 0.9rem;
              `}
            >
              {nagarpalikaInfo.address_np}
            </p>
          </div>
        ) : (
          <div>
            <h5>{nagarpalika?.name_np}</h5>
            <p
              css={css`
                font-size: 1rem;
              `}
            >
              {/* {language === 'nepali' ? ( */}
              <Fragment>
                {user?.role === "ito_admin" ? (
                  <Fragment>
                    {user?.role === "ito_admin" ? (
                      <Fragment>
                        {nagarpalika?.name_np?.includes("गाउँ")
                          ? "गाउँ "
                          : "नगर "}
                        कार्यपालिकाको कार्यालय
                      </Fragment>
                    ) : (
                      `${user?.organization?.name_np}`
                    )}
                  </Fragment>
                ) : (
                  `${
                    user?.ward_info?.name_np || user?.organization?.name_np
                  } शाखा`
                )}
              </Fragment>
              {/*}) : (
								<Fragment>
									{user?.role === 'ito_admin'
										? 'Office Of the Municipal Executive'
										: user?.organization?.type === 'sakha'
										? `${user?.organizations?.name_en} Department`
										: `Office of Ward No. ${user?.organization?.name_en}`}
								</Fragment>
							)}
							*/}
            </p>
            <p
              css={css`
                font-size: 0.9rem;
              `}
            >
              {nagarpalika?.address_np}
            </p>
          </div>
        )}
      </div>
      <hr
        css={css`
          margin: 0 auto;
          width: 90%;
          border-top-color: #dedede !important;
        `}
      />
      <h4
        css={css`
          text-align: center;
          padding: 0.5rem 0;
          color: black;
        `}
      >
        संघ संस्था दर्ता प्रणाली
      </h4>
      <hr
        css={css`
          margin: 0 auto;
          width: 90%;
          border-top-color: #dedede !important;
        `}
      />
      <div className="menus">
        {upperData && <SidebarButtonRenderer data={upperData} user={user} />}
        {checkPermission(user?.role, "view:templates") && (
          <PaperTemplateRenderer />
        )}
        {lowerData && <SidebarButtonRenderer data={lowerData} user={user} />}
      </div>
    </div>
  );
};

export default Sidebar;
