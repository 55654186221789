export const apiKey="3753bd03b970acfb13aba03a95e3728f71bf05be";

export const USER_PROFILE_TOKEN_NAME = 'certificate_profile_token';

export const FOOTER_DETAILS = {
    'siyari': {
        data: {
            en:
                [
                    {
                        label: "Office Code",
                        value: 801054815,
                        type: 'text'
                    },
                    {
                        label: "PAN Number",
                        value: 201367142,
                        type: 'text'
                    },
                    {
                        label: "Email",
                        value: "siyarimun@gmail.com",
                        type: "link"
                    },
                    {
                        label: "Website",
                        value: "https://siyarimun.gov.np",
                        type: "link"
                    },
                    {
                        label: "Facebook",
                        value: "facebook.com/SiyariRuralMun",
                        type: "link"
                    }
                ],
            np:
                [
                    {
                        label: "कार्यालय कोड नं.",
                        value: "८०१०५४८१५",
                        type: 'text'
                    },
                    {
                        label: "स्थाई लेखा नं.",
                        value: "२०१३६७१४२",
                        type: 'text'
                    },
                    {
                        label: "वेबसाइट",
                        value: "https://siyarimun.gov.np",
                        type: "link"
                    },
                    {
                        label: "इमेल",
                        value: "siyarimun@gmail.com",
                        type: "link"
                    },
                    {
                        label: "फेसबुक",
                        value: "facebook.com/SiyariRuralMun",
                        type: "link"
                    }
                ],
        },
        qr: '/assets/images/siyariMuniQr.png'
    },
    'bansgadhi': {
        data: {
            en:
                [
                    {
                        label: "Office Code",
                        value: 801055803,
                        type: 'text'
                    },
                    {
                        label: "PAN Number",
                        value: 201325742,
                        type: 'text'
                    },
                    {
                        label: "Phone",
                        value: '084400002',
                        type: 'text'
                    },
                    {
                        label: "Website",
                        value: "https://bansgadhimun.gov.np",
                        type: "link"
                    },
                    {
                        label: "Email",
                        value: "info@bansgadhimun.gov.np",
                        type: "link"
                    },
                    {
                        label: "Facebook",
                        value: "facebook.com/municipalitybansgadhi",
                        type: "link"
                    },
                    {
                        label: "Twitter",
                        value: "twitter.com/bansgadhimun",
                        type: "link"
                    }
                ],
            np:
                [
                    {
                        label: "कार्यालय कोड नं.",
                        value: "८०१०५५८०३",
                        type: 'text'
                    },
                    {
                        label: "स्थाई लेखा नं.",
                        value: "२०१३२५७४२",
                        type: 'text'
                    },
                    {
                        label: "सम्पर्क नं.",
                        value: '०८४४००००२',
                        type: 'text'
                    },
                    {
                        label: "वेबसाइट",
                        value: "https://bansgadhimun.gov.np",
                        type: "link"
                    },
                    {
                        label: "इमेल",
                        value: "info@bansgadhimun.gov.np",
                        type: "link"
                    },
                    {
                        label: "फेसबुक",
                        value: "facebook.com/municipalitybansgadhi",
                        type: "link"
                    },
                    {
                        label: "ट्विटर",
                        value: "twitter.com/bansgadhimun",
                        type: "link"
                    }
                ],
        },
        qr: '/assets/images/bansgadhiQr.png'
    }
}
