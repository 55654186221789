import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Button, Card, Col, FormControl, Row, Table } from 'react-bootstrap';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { fetchWards } from '../../_redux/slices/ward';
import { fetchFiscalYear } from '../../_redux/slices/fiscalyear';
import { useSelector } from 'react-redux';
import { BsDownload } from 'react-icons/bs';

import { englishToNepaliNumber, englishToNepaliNumber as np } from 'nepali-number';
import { VscLoading } from 'react-icons/vsc';
import PaginationComp from '../Pagination';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import moment from 'moment';
import NepaliDate from 'nepali-date-converter';

function NabikaranReport() {
    const [isLoading, setIsLoading] = useState(false);
    const [papers, setPapers] = useState({});

    const fiscalYear = useSelector((state) => state.fiscal.fiscalyear);
    const activeFiscalYear = useSelector((state) => state.fiscal.activeFiscalYear);
    const user = useSelector((state) => state.users.user);
    const [page, setPage] = useState(1);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [filter, setFilter] = useState({
        fy: activeFiscalYear?.id,
        ward: null,
        category: null,
        renewed: "true",
        from: { ad: null, bs: null },
        to: { ad: null, bs: null },
    });
    const [toDateError, setToDateError] = useState(false);
    const [fromDateError, setFromDateError] = useState(false);
    const templatesCategory = useSelector(
        (state) => state?.templateCategories?.templatesCategory
    );

    useEffect(() => {
        fetchFiscalYear();
        fetchWards();
    }, []);


    const getPapers = useCallback(
        async (page) => {
            if (!activeFiscalYear?.id) return;
            try {
                let uri = `/report/paper/?page=${page}`;
                if (filter.fy) {
                    uri += `&fiscal_year=${filter.fy}`;
                }
                else {
                    uri += `&fiscal_year=${activeFiscalYear?.id}`;
                }
                if (filter.ward) {
                    uri += `&sakha=${filter.ward}`;
                }
                if (filter.category) {
                    uri += `&template=${filter.category}`;
                }
                if (filter.from.ad) {
                    uri += `&from=${filter.from.ad}`;
                }
                if (filter.to.ad) {
                    uri += `&to=${filter.to.ad}`;
                }
                if (filter.renewed) {
                    uri += `&renewed=${filter.renewed}`;
                }
                setIsLoading(true);
                const res = await fetchWrapper.get(uri);
                setPapers(res.data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        },
        [activeFiscalYear?.id, filter.fy, filter.ward, filter.category, filter.from.ad, filter.to.ad, filter.renewed]
    );

    useEffect(() => {
        getPapers(page);
    }, [getPapers, page]);

    useEffect(() => {
        setPage(1);
    }, [filter.from.ad, filter.to.ad]);

    const handleDownloadFile = async (type = 'pdf') => {
        try {
            setDownloadLoading(type);
            let uri = `/report/paper/?type=${type}`;
            if (filter.fy) {
                uri += `&fiscal_year=${filter.fy}`;
            }
            if (filter.renewed) {
                uri += `&renewed=${filter.renewed}`;
            }
            if (filter.ward) {
                uri += `&sakha=${filter.ward}`;
            }
            if (filter.category) {
                uri += `&template=${filter.category}`;
            }

            const res = await fetchWrapper.get(uri);
            const a = document.createElement('a');
            a.setAttribute('target', '_blank');
            a.href = res.data;
            a.download = '';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setDownloadLoading(false);
        }
        catch (err) {
            setDownloadLoading(false);
        }

    };

    return (
        <div>
            <div className="container-fluid">
                <Row className="justify-content-between">
                    <Col md={3}>
                        <FormControl as={'select'} onChange={(e) => setFilter({ ...filter, fy: e.target.value })}>
                            <option selected>आर्थिक वर्ष छान्नुहोस्</option>
                            {fiscalYear.map((fy) => (
                                <option value={fy.id} key={fy.id} selected={activeFiscalYear?.id === fy.id}>
                                    {fy.title}
                                </option>
                            ))}
                        </FormControl>
                    </Col>

                    <Col md={3}>
                        <FormControl as={'select'} value={filter.renewed} onChange={(e) => setFilter({ ...filter, renewed: e.target.value })}>
                            <option value="">सबै प्रमाण पत्र</option>
                            <option value="true">नविकरण भएको</option>
                            <option value="false">नविकरण नभएको</option>
                        </FormControl>
                    </Col>

                    <Col md={3} className="p-0">
                        <FormControl as={'select'} onChange={(e) => setFilter({ ...filter, category: e.target.value })}>
                            <option value="">प्रमाण पत्रको प्रकार छान्नुहोस्</option>
                            {templatesCategory.filter(category => ['प्रमाण पत्रहरु'].includes(category.title)).map((categoryType) => (
                                categoryType.templates.map(template => <option value={template.id} key={template.id}>
                                    {template.title}
                                </option>)
                            ))}
                        </FormControl>
                    </Col>

                    <Col md={3} className="d-flex justify-content-end">
                        <Button variant="outline-primary" onClick={() => handleDownloadFile('excel')}
                            className="ml-2 d-flex justify-content-center align-items-center"
                            disabled={downloadLoading === "excel"}>

                            {
                                downloadLoading === "excel" ?
                                    <VscLoading className='spin' />
                                    :
                                    <Fragment>
                                        <BsDownload />
                                        &nbsp;Overall report (Excel)
                                    </Fragment>
                            }
                        </Button>
                    </Col>
                </Row>

                <Row className="py-2">
                    <Card className="p-4" style={{ borderRadius: '8px', boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)' }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <Card.Title>
                                <h4 className="text-primary">प्रमाण पत्र नविकरण</h4>
                            </Card.Title>

                            <div className="d-flex justify-content-center align-items-center mb-3">
                                <div className="d-flex">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex align-items-center">
                                            <label htmlFor="from" className="w-auto mr-2 mb-0">
                                                From:{' '}
                                            </label>
                                            <NepaliDatePicker
                                                inputClassName="form-control"
                                                className=""
                                                value={filter.from.bs}
                                                onChange={(value) => {
                                                    if (value && value !== filter.from.bs) {
                                                        try {
                                                            let { year, month, date } = new NepaliDate(value).getAD();
                                                            let today = moment().format('YYYY-MM-DD');
                                                            let selectedAD = `${year}-${('0' + (month + 1)).slice(-2)}-${date}`;
                                                            if (moment(selectedAD).isAfter(today, 'day') && filter.from.bs) {
                                                                setFromDateError('चयन गरिएको मिति आजको मिति भन्दा ठूलो हुनु हुँदैन');
                                                            } else {
                                                                setFromDateError(false);
                                                                setFilter({
                                                                    ...filter,
                                                                    from: {
                                                                        ad: `${year}-${('0' + (month + 1)).slice(-2)}-${date}`,
                                                                        bs: value,
                                                                    },
                                                                });
                                                            }
                                                        } catch (error) {
                                                            console.log(error);
                                                        }
                                                    }
                                                }}
                                                options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                                            />
                                        </div>
                                        {
                                            fromDateError &&
                                            <span className="p-2 text-danger">{fromDateError}</span>
                                        }
                                    </div>

                                    <div className="d-flex flex-column">
                                        <div className="ml-4 d-flex align-items-center justify-content-start">
                                            <label className="w-auto mr-2 mb-0">To: </label>
                                            <NepaliDatePicker
                                                inputClassName="form-control"
                                                className=""
                                                value={filter.to.bs}
                                                onChange={(value) => {
                                                    if (value && value !== filter.to.bs) {
                                                        try {
                                                            let { year, month, date } = new NepaliDate(value).getAD();
                                                            let today = moment().format('YYYY-MM-DD');
                                                            let selectedAD = `${year}-${('0' + (month + 1)).slice(-2)}-${date}`;

                                                            if (moment(selectedAD).isAfter(today, 'day')) {
                                                                setToDateError('चयन गरिएको मिति आजको मिति भन्दा ठूलो हुनु हुँदैन');
                                                            } else if (filter?.from?.ad) {
                                                                if (moment(selectedAD).isBefore(filter?.from?.ad, 'day')) {
                                                                    setToDateError('चयन गरिएको मिति "From" मिति भन्दा सानो हुनु हुँदैन');
                                                                } else {
                                                                    setToDateError(false);
                                                                    setFilter({
                                                                        ...filter,
                                                                        to: {
                                                                            ad: `${year}-${('0' + (month + 1)).slice(-2)}-${date}`,
                                                                            bs: value,
                                                                        },
                                                                    });
                                                                }
                                                            } else {
                                                                setToDateError(false);
                                                                setFilter({
                                                                    ...filter,
                                                                    to: {
                                                                        ad: `${year}-${('0' + (month + 1)).slice(-2)}-${date}`,
                                                                        bs: value,
                                                                    },
                                                                });
                                                            }
                                                        } catch (error) {
                                                            console.log(error);
                                                        }
                                                    }
                                                }}
                                                options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                                            />
                                        </div>
                                        {toDateError &&
                                            <span className="p-2 text-danger">{toDateError}</span>
                                        }
                                    </div>
                                </div>

                                <div className='ml-2 mr-3'>{papers?.count > 0 && <span>कुल संख्या: {englishToNepaliNumber(papers.count)}</span>}
                                </div>

                                <div className="">
                                    <Button
                                        variant="outline-primary"
                                        className="ml-2 d-flex justify-content-center align-items-center"
                                        onClick={() => handleDownloadFile()}
                                        disabled={downloadLoading === "pdf"}
                                    >

                                        {
                                            downloadLoading === "pdf" ?
                                                <VscLoading className='spin' />
                                                :
                                                <Fragment>
                                                    <BsDownload />
                                                    &nbsp;PDF
                                                </Fragment>
                                        }
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <Table size="sm" hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '80px' }}>क्र.सं</th>
                                    <th>प्रमाण पत्रको प्रकार</th>
                                    <th>व्यवसाय/संस्थाको नाम</th>
                                    <th>ठेगाना</th>
                                    <th>फोन नं.</th>
                                    <th>पछिल्लो नविकरण मिति</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <tr>
                                        <td colSpan={10} className="text-center py-4">
                                            <VscLoading fontSize="34" className="spin" />
                                        </td>
                                    </tr>
                                ) : papers?.results?.length < 1 ? (
                                    <tr>
                                        <td colSpan={10} className="text-center p-4">
                                            No Data Found
                                        </td>
                                    </tr>
                                ) : (
                                    papers?.results?.map((paper, i) => {
                                        return (
                                            <tr key={paper.id}>
                                                <td style={{ width: '80px' }}>{(page - 1) * 10 + i + 1}</td>
                                                <td>{paper.title} </td>
                                                <td>{paper.name} </td>
                                                <td>{paper.address || "-"}</td>
                                                <td>{paper.phone || "-"}</td>
                                                <td>{paper.renewed_date || "-"}</td>
                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        </Table>

                        {papers?.count > 10 && <PaginationComp total={papers.count} page={page} setPage={setPage} />}
                    </Card>
                </Row>
            </div>
        </div>
    )
}

export default NabikaranReport

