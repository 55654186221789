/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import {
	fetchFiscalYear,
	createFiscalYear,
	editFiscalYear,
	deleteFiscalYear,
} from '../../_redux/slices/fiscalyear';
import { FaCheckCircle, FaPlusCircle, FaTrash } from 'react-icons/fa';
import { AiFillCheckCircle } from 'react-icons/ai';
import PaginationComp from '../../components/Pagination';
import { InputFieldStyleOverwrite } from './Ward/AddWard';
import InputField from '../../components/InputField';
import CustomTable from '../../components/Table';
import { hasSSO } from '../../_helpers/methods';

const FiscalYear = ({
	fetchFiscalYear,
	fiscalyear,
	totalFiscalYear,
	createFiscalYear,
	editFiscalYear,
	deleteFiscalYear,
}) => {
	const [page, setPage] = useState(1);

	useEffect(() => {
		fetchFiscalYear(page);
	}, [fetchFiscalYear, page]);

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		defaultValues: {},
	});

	const handleFiscalYearForm = () => {
		setShowAdd(false);
	};

	const onSubmit = (data) => {
		createFiscalYear(data, handleFiscalYearForm);
	};

	const onActive = (item) => {
		let activatedItem = {
			id: item.id,
			is_active: true,
			title: item.title,
		};
		editFiscalYear(activatedItem);
		setShow(null);
	};

	const [show, setShow] = useState(null);
	const handleDeleteFiscalYear = (fiscalYearId) => {
		deleteFiscalYear(fiscalYearId);
		setShow(null);
	};
	const [showAdd, setShowAdd] = useState(false);
	const loading = useSelector((state) => state.fiscal.loading);

	const headers = useMemo(() => {
		if (fiscalyear?.length > 0) {
			return [
				{
					key: 'title',
					title: 'आर्थिक वर्ष',
					headerStyleOverwrite: css`
						width: 20%;
						text-align: center !important;
					`,
					render: (value) => (
						<div className="text-center">
							{value.title}
							<FaCheckCircle
								className={
									value.is_active === true ? `text-success ml-1` : `ml-1`
								}
							/>
						</div>
					),
				},
				...(!hasSSO()
					? [
							{
								key: 'actions',
								title: 'कार्यहरू',
								render: (value) => (
									<>
										{!value.is_active && (
											<Fragment>
												<Button
													variant="primary"
													css={css`
														font-size: 0.8rem;
														padding: 0.25rem 0.5rem !important;
													`}
													onClick={() =>
														setShow({ type: 'activate', item: value })
													}
												>
													<AiFillCheckCircle size={12} /> Activate
												</Button>
												<Button
													variant="danger"
													css={css`
														font-size: 0.8rem;
														padding: 0.25rem 0.5rem !important;
													`}
													className="ml-2"
													onClick={() =>
														setShow({ type: 'delete', item: value })
													}
												>
													<FaTrash size={12} /> हटाउनु
												</Button>
											</Fragment>
										)}
									</>
								),
							},
					  ]
					: []),
			];
		}
		return [];
	}, [fiscalyear]);

	return (
		<Fragment>
			{!hasSSO() && showAdd && (
				<Card
					className="mt-2"
					css={css`
						border-radius: 8px;
						box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
						margin-block-end: 1rem;
					`}
				>
					<Card.Header className="card-header-custom d-block px-3">
						<h3>नयाँ आर्थिक वर्ष</h3>
					</Card.Header>
					<Card.Body className="py-0 px-3">
						<Row className="">
							<Col md={12}>
								<Form onSubmit={handleSubmit(onSubmit)}>
									<div className="row pb-3">
										<Col md={4} className="">
											<Form.Group controlId="formBasicEmail">
												<Form.Label className="mb-0">वर्ष</Form.Label>
												<InputField
													css={InputFieldStyleOverwrite}
													type="text"
													className={'textbox_req'}
													language={'english'}
													classNameName={`input`}
													elementId={`title`}
													placeholder={'वर्ष भर्नुहोस्'}
													maxLength={10}
													register={register}
													isRequired={true}
													setValue={setValue}
												/>
												{errors?.title && (
													<p
														className="text-danger text-small"
														css={css`
															width: 100%;
															display: inline-block;
														`}
													>
														{errors?.title?.message}
													</p>
												)}
											</Form.Group>
										</Col>

										<div className="col col-12">
											<Form.Check
												type="checkbox"
												id="fiscalyear"
												label="चालु आर्थिक वर्ष?"
												{...register('is_active')}
											/>
										</div>
										<div className="col col-4 mt-2">
											<Button variant="success" type="submit">
												सुरक्षित
											</Button>
										</div>
									</div>
								</Form>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			)}

			<Row className="mt-2">
				<Col md={12}>
					<Card
						css={css`
							border-radius: 8px;
							box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
						`}
					>
						<Card.Header className="card-header-custom px-3">
							<h2>आर्थिक वर्ष</h2>
							{!hasSSO() && (
								<Button
									variant="primary"
									onClick={() => {
										setShowAdd(!showAdd);
										setValue('title', '');
									}}
								>
									<FaPlusCircle />
									&nbsp; नयाँ आर्थिक वर्ष
								</Button>
							)}
						</Card.Header>

						<Card.Body className="px-3 py-0">
							<CustomTable
								headers={headers}
								data={fiscalyear}
								isLoading={loading}
							/>
							{totalFiscalYear > 10 && (
								<PaginationComp
									total={totalFiscalYear}
									page={page}
									setPage={setPage}
								/>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Modal show={Boolean(show)} onHide={() => setShow(null)} centered>
				<Modal.Header>
					<h3>आर्थिक वर्ष</h3>
				</Modal.Header>
				<Modal.Body>
					<p className="h5 font-weight-lighter">
						{show?.type === 'activate'
							? 'के तपाइँ यो आर्थिक वर्ष सक्रिय गर्न चाहानुहुन्छ?'
							: 'के तपाइँ यो आर्थिक वर्ष हटाउन चाहानुहुन्छ?'}
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="btn btn-secondary"
						onClick={() => {
							setShow(null);
						}}
					>
						रद्द गर्नुहोस्
					</Button>
					<Button
						className="btn btn-primary"
						onClick={() => {
							if (show?.type === 'activate') {
								onActive(show?.item);
							} else {
								handleDeleteFiscalYear(show?.item.id);
							}
						}}
					>
						सुनिश्चित गर्नुहोस्
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	fiscalyear: state.fiscal.fiscalyear,
	totalFiscalYear: state.fiscal.totalFiscalYear,
});

const mapDispatchToProps = {
	fetchFiscalYear,
	createFiscalYear,
	editFiscalYear,
	deleteFiscalYear,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiscalYear);
