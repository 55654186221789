import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiMessage } from "./ui";

export const initialState = {
  loading: false,
  hasErrors: false,
  sifarishTypes: [],
  count: 0,
};

// A slice
const sifarishTypesSlice = createSlice({
  name: "sifarishTypes",
  initialState,
  reducers: {
    getSifarishTypes: (state, action) => {
      state.sifarishTypes = action.payload.data.results;
      state.count = action.payload.count;
    },

    addSifarishType: (state, action) => {
      state.sifarishTypes = [action.payload, ...state.sifarishTypes];
      state.count = state.count + 1;
    },

    updateSifarishType: (state, action) => {
      const sifarishTypes = [...state.sifarishTypes];

      const editedIndex = sifarishTypes.findIndex(
        (sifarishType) => sifarishType.id === action.payload.id
      );

      if (editedIndex !== -1) {
        sifarishTypes[editedIndex] = action.payload;
      }
      state.sifarishTypes = sifarishTypes;
    },
    removeSifarishType: (state, action) => {
      const sifarishTypes = [...state.sifarishTypes];

      const editedIndex = sifarishTypes.findIndex(
        (sifarishType) => sifarishType.id === action.payload
      );
      sifarishTypes.splice(editedIndex, 1);
      state.sifarishTypes = sifarishTypes;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Actions generated from the slice
const {
  getSifarishTypes,
  addSifarishType,
  updateSifarishType,
  removeSifarishType,
  setLoading,
} = sifarishTypesSlice.actions;

// export user selector to get the slice in any component
export const sifarishTypesSelector = (state) => state.sifarishTypes;

// export The reducer
const sifarishTypesReducer = sifarishTypesSlice.reducer;

export default sifarishTypesReducer;

// Actions
export const fetchSifarishTypes = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const sifarishTypes = await fetchWrapper.get("/template/category/");
    dispatch(getSifarishTypes(sifarishTypes));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const createSifarishType = (data, setShowAdd) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const sifarishType = await fetchWrapper.post("/template/category/", data);
    dispatch(addSifarishType(sifarishType.data));
    setShowAdd((prev) => !prev);
    dispatch(setUiMessage(sifarishType.message));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const editSifarishType =
  (data, setShowAdd, setDocumentDetail) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const sifarishType = await fetchWrapper.patch(`/template/category/${data.id}/`, data);

      dispatch(updateSifarishType(sifarishType.data));
      setShowAdd((prev) => !prev);
      setDocumentDetail(null);
      dispatch(setUiMessage(sifarishType.message));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

export const deleteSifarishType = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const sifarishType = await fetchWrapper.delete(`/template/category/${id}/`);
    dispatch(removeSifarishType(id));
    dispatch(setUiMessage(sifarishType.message));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
  }
};
