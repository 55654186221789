import React from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import Loader from '../../_elements/Loader';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUiMessage, startUiLoading, stopUiLoading } from '../../_redux/slices/ui';
import { resetMessage } from '../../_redux/slices/formTemplates';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { useHistory } from 'react-router-dom';

function ResetPassword(props) {
	const [password, setPassword] = useState('');
	const history = useHistory();
	const loading = useSelector((state) => state.users.loading);
	const dispatch = useDispatch();

	const handleSubmit = () => {
		let location = window.location.hash;
		const [uid, token] = location.split('/');
		const body = {
			uid: uid,
			token: token,
			new_password: password,
		};
		dispatch(startUiLoading);
		if (password) {
			fetchWrapper
				.post('/user/reset_password_confirm/', body)
				.then((res) => {
					dispatch(setUiMessage('पासवर्ड रिसेट भयो'));
					history.push('/login');
				})
				.catch((err) => {
					dispatch(resetMessage(err.message));
					dispatch(setUiMessage('केहि त्रुटि भयो, फेरि प्रयास गर्नुहोस्'));
					console.log(err);
				});
		}
		dispatch(stopUiLoading);
	};
	return (
		<section className="section">
			<Container>
				<Row className="mt-5">
					<div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
						<div className="login-brand">
							<img src="/assets/images/nep_logo.png" alt="logo" width="100" className="shadow-light rounded-circle" />
							<h3>संघ संस्था दर्ता  प्रणाली</h3>
						</div>
						<div className="card card-primary">
							<div className="card-header">
								<h4>पासवर्ड रिसेट</h4>
							</div>
							<div className="card-body">
								<form className="needs-validation">
									<div className="form-group">
										<label for="password">नया पासवर्ड</label>
										<input
											id="password"
											type="password"
											className="form-control"
											name="password"
											tabindex="1"
											required
											onChange={(e) => setPassword(e.target.value)}
											autofocus
										/>
									</div>
									<div className="form-group">
										<Button onClick={handleSubmit} className="btn btn-primary btn-lg btn-block">
											पासवर्ड परिवर्तन गर्नुहोस्
										</Button>
									</div>
								</form>
							</div>
						</div>
						<div className="simple-footer">
							Copyright &copy; {new Date().getFullYear()} Ideabreed IT Solution Pvt. Ltd.
						</div>
					</div>
				</Row>
			</Container>
			{loading && <Loader />}
		</section>
	);
}

export default ResetPassword;
