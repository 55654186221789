/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useEffect, useState } from "react";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { Link } from "react-router-dom";
import { Row, Container } from "react-bootstrap";

import { colorClass } from "../Dashboard";
import { VscLoading } from "react-icons/vsc";
import CitizensHeader from "./CitizensHeader";
import { useSelector } from "react-redux";
import { languageSelector } from "../../_redux/slices/languages";
import InputField from "../../components/InputField";

//icons
import { AiOutlineSearch } from "react-icons/ai";
import TemplateCard from "../../components/TemplateCard/TemplateCard";

const Box = ({ title, icon = null, number }) => {
  return (
    <div className="dashboard-stats-box">
      <div className="dashboard-stats-box-content">
        <h5>{title}</h5>
      </div>
      <h5 className="dashboard-number">
        <kbd className="red-shade">{number}</kbd>
      </h5>
      <span>
        <div className="dashboard-stats-box-icon">{icon}</div>
      </span>
    </div>
  );
};

const CitizensHome = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [paperTemplateCategories, setPaperTemplateCategories] = useState([]);
  const { language } = useSelector(languageSelector);
  const getTemplateCategory = async () => {
    try {
      setIsLoading(true);
      let response = await fetchWrapper.get("/public/template/category/");
      let templateCategories = response.data.results;
      setIsLoading(false);
      setPaperTemplateCategories(templateCategories);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    const searchTemplate = async (search) => {
      try {
        setIsLoading(true);
        let response = await fetchWrapper.get(
          "/public/template/?title=" + search, false, true
        );
        let templateCategories = [
          {
            title: "",
            templates: response.data.results,
          },
        ];
        setIsLoading(false);
        setPaperTemplateCategories(templateCategories);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    };
    if (search === "") {
      getTemplateCategory();
    } else {
      searchTemplate(search);
    }
  }, [search]);

  const handleSearch = async (value) => {
    setSearch(value);
  };

  return (
    <div className="main-wrapper">
      <div className="content-wrapper">
        <CitizensHeader />
        <Container fluid>
          <div className="main-content">
            <div>
              <Link to="/login" className="btn btn-primary">
                {language === "english" ? "Back" : "पछाडी जानुहोस"}
              </Link>
            </div>
            <div
              css={css`
                padding-top: 1rem;
                text-align: right;
                .search_input-div {
                  position: relative;
                  .search-icon {
                    position: absolute;
                    font-size: 26px;
                    color: rgb(102, 119, 239);
                    top: 4px;
                    right: 8px;
                  }
                }
                .search_input {
                  padding: 0.5rem;
                  width: 20rem;
                  border-radius: 15px;
                }
              `}
            >
              <div className="search_input-div">
                <InputField
                  language={language}
                  className={`search_input`}
                  placeHolder="सिफारिसको नाम"
                  getInputData={(value) => {
                    handleSearch(value);
                  }}
                />
                <AiOutlineSearch className="search-icon" />
              </div>
            </div>
            <div>
              {isLoading ? (
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 200px;
                  `}
                >
                  <VscLoading className="spin" fontSize="32" />
                </div>
              ) : (
                paperTemplateCategories.map((category) => {
                  const templates = category.templates.filter(
                    (template) => template?.entity !== "private"
                  );
                  if (category.entity === "private") {
                    return null;
                  }
                  return (
                    <div key={category.title}>
                      <h5 className="main-title mt-3">{category.title}</h5>
                      <Row className="mt-3">
                        {templates.length ? (
                          templates.map((template, idx = 1) => (
                            <TemplateCard
                              templateUrl="/citizen-template/"
                              title={template.title}
                              icon={template.icon}
                              categoryIcon={template.category_icon}
                              id={template.id}
                              key={template.id}
                              idx={idx % colorClass.length}
                              charge={template.charge}
                              additional_info={template.additional_info}
                            />
                          ))
                        ) : (
                          <div className="text-center m-4 w-100">
                            <h3 className="color-primary">सिफारिस पाईएन</h3>
                          </div>
                        )}
                      </Row>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
export { Box };
export default CitizensHome;
