import React from 'react';
import { englishToNepaliNumber as np } from 'nepali-number';
import { Link } from 'react-router-dom';

function StatCard({ title, number, icon, path }) {
	return (
		<div className="col p-2">
			<Link to={path || null}>
				<div className="dashboard-stats-box">
					<div className="dashboard-stats-box-content">
						<h5>{title}</h5>
					</div>
					<h5 className="dashboard-number">
						<kbd className="red-shade">{number ? np(number) : '--'}</kbd>
					</h5>
					<span>
						<div className="dashboard-stats-box-icon">{icon}</div>
					</span>
				</div>
			</Link>
		</div>
	);
}

export default StatCard;
