import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { setUiError, setUiMessage } from './ui';

export const initialState = {
	loading: false,
	hasErrors: false,
	designTemplates: [],
	singleTemplate: null,
	activeDesignTemplate: [],
};

// A slice
const designTemplateSlice = createSlice({
	name: 'designTemplates',
	initialState,
	reducers: {
		setDesignTemplate: (state, action) => {
			state.designTemplates = action.payload.data.results;
		},
		setSingleTemplate: (state, action) => {
			state.singleTemplate = action.payload;
		},
		setActiveDesignTemplate: (state, action) => {
			state.activeDesignTemplate = action.payload;
		},
		setActivateDesignTemplate: (state, action) => {
			state.designTemplates = state.designTemplates.map((item) =>
				item.id === action.payload.id ? { ...item, is_enabled: true } : item
			);
			state.activeDesignTemplate = [
				action.payload,
				...state.activeDesignTemplate,
			];
		},
		setDeactivateDesignTemplate: (state, action) => {
			state.designTemplates = state.designTemplates.map((item) =>
				item.id === action.payload.id ? { ...item, is_enabled: false } : item
			);
			state.activeDesignTemplate = state.activeDesignTemplate.filter(
				(item) => item.id !== action.payload.id
			);
		},
		setNewDesignTemplate: (state, action) => {
			state.designTemplates = [action.payload, ...state.designTemplates];
		},
		removeDesignTemplate: (state, action) => {
			state.designTemplates = state.designTemplates.filter(
				(item) => item.id !== action.payload
			);
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
	},
});

// Actions generated from the slice
const {
	setSingleTemplate,
	setDesignTemplate,
	setNewDesignTemplate,
	setActiveDesignTemplate,
	removeDesignTemplate,
	setLoading,
	setActivateDesignTemplate,
	setDeactivateDesignTemplate,
} = designTemplateSlice.actions;

// export user selector to get the slice in any component
export const fiscalSelector = (state) => state.designTemplates;

// export The reducer
const designTemplateReducer = designTemplateSlice.reducer;

export default designTemplateReducer;

// Actions
export const fetchDesignTemplate = () => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const designTemplates = await fetchWrapper.get('/design-template/');

		dispatch(setDesignTemplate(designTemplates));
		dispatch(setLoading(false));
	} catch (error) {
		// dispatch(getUsersFailure());
		dispatch(setLoading(false));
	}
};
export const fetchSingleDesignTemplate = (id) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const designTemplate = await fetchWrapper.get(`/design-template/${id}/`);
		dispatch(setSingleTemplate(designTemplate?.data));
		dispatch(setLoading(false));
	} catch (error) {
		// dispatch(getUsersFailure());
		dispatch(setLoading(false));
	}
};
export const fetchActiveDesignTemplate = () => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const activeDesignTemplate = await fetchWrapper.get(
			'/design-template/active/'
		);
		dispatch(setActiveDesignTemplate(activeDesignTemplate.data.results));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};

export const activateDesignTemplate = (id) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const activeDesignTemplate = await fetchWrapper.get(
			`/design-template/${id}/activate/`
		);
		dispatch(setActivateDesignTemplate(activeDesignTemplate.data));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};

export const deactivateDesignTemplate = (id) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const activeDesignTemplate = await fetchWrapper.get(
			`/design-template/${id}/deactivate/`
		);
		dispatch(setDeactivateDesignTemplate(activeDesignTemplate.data));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};

export const createDesignTemplate =
	(data, handleFiscalYearForm) => async (dispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await fetchWrapper.post('/design-template/', data);
			if (data.is_enabled) {
				dispatch(setActiveDesignTemplate({ data, activate: true }));
			}
			dispatch(setNewDesignTemplate(response.data));
			dispatch(setUiMessage(response.message));
			dispatch(setLoading(false));
			handleFiscalYearForm();
		} catch (error) {
			if (error?.error?.error?.non_field_error) {
				dispatch(setUiError(error?.error?.error?.non_field_error));
				dispatch(setLoading(false));
			}
		}
	};

export const deleteDesignTemplate = (id) => async (dispatch) => {
	try {
		const response = await fetchWrapper.delete(`/design-template/${id}/`);
		dispatch(removeDesignTemplate(id));
		dispatch(setUiMessage(response.message));
	} catch (error) {
		if (error?.error?.error?.non_field_error) {
			dispatch(setUiError(error?.error?.error?.non_field_error));
		}
	}
};
