/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { createDocumentType, editDocumentType } from '../../../_redux/slices/documents';
import { useForm } from 'react-hook-form';
import InputField from '../../../components/InputField';
import { useSelector } from 'react-redux';
import { languageSelector } from '../../../_redux/slices/languages';
import { InputFieldStyleOverwrite } from '../Ward/AddWard';
import { yupResolver } from '@hookform/resolvers/yup';
import { documentTypeValidation } from '../../../_helpers/schema-validation';
import { VscLoading } from 'react-icons/vsc';

const AddDocumentType = ({ showAdd, setShowAdd, documentDetail, setDocumentDetail }) => {
	const dispatch = useDispatch();

	const { language } = useSelector(languageSelector);
	const loading = useSelector((state) => state.documents.loading);
	const documents = useSelector((state) => state.documents);

	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(documentTypeValidation),
	});

	const onSubmit = (data) => {
		if (documentDetail) {
			dispatch(editDocumentType({ ...data, id: documentDetail.id }, setShowAdd, setDocumentDetail));
			setShowAdd(false);
		} else {
			dispatch(createDocumentType(data, documents?.documentsType, setShowAdd));
		}
	};

	useEffect(() => {
		if (documentDetail) {
			setValue('title', documentDetail.title);
			setValue('type', documentDetail.type);
		} else {
			setValue('title', '');
			setValue('type', null);
		}
	}, [setValue, documentDetail]);

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Card
				css={css`
					border-radius: 8px;
					box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
					margin-bottom: 1rem;
				`}
			>
				<Card.Header className="card-header-custom px-3">
					<h3>{documentDetail?.eventType === 'edit' ? 'आवश्यक कागजात सम्पादन गर्नुहोस्' : ' नयाँ आवश्यक कागजात'}</h3>
				</Card.Header>
				<Card.Body className="px-3 pt-2 pb-3">
					<Row>
						<Col md={4} className="">
							<Form.Group>
								<Form.Label className="mb-0">शीर्षक</Form.Label>
								<InputField
									css={InputFieldStyleOverwrite}
									type="text"
									className={`textbox_req`}
									language={language}
									classNameName={`input`}
									elementId={'title'}
									placeholder={'Enter Title'}
									register={register}
									isRequired={true}
									setValue={setValue}
									autoComplete={'nope'}
								/>

								<Form.Text className="text-danger">{errors.title?.message}</Form.Text>
							</Form.Group>
						</Col>
						<Col md={4}>
							<Form.Group>
								<Form.Label className="mb-0">प्रकार</Form.Label>
								<Form.Control
									css={InputFieldStyleOverwrite}
									as="select"
									placeholder="Enter type"
									name="type"
									{...register('type')}
								>
									<option value="personal">Personal</option>
									<option value="sifarish">Pramaan Patra</option>
								</Form.Control>
							</Form.Group>
						</Col>
						<Col md={12}>
							<Button variant="secondary" className="mr-2" onClick={() => setShowAdd(!showAdd)} disabled={loading}>
								रद्द गर्नुहोस्
							</Button>
							<Button type="submit" variant="success" disabled={loading}>
								{loading ? <VscLoading className="spin" /> : ' पेश गर्नुहोस्'}
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Form>
	);
};

export default AddDocumentType;
