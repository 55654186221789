import CitizensHeader from "../Citizens/CitizensHeader";
import Verification from "../Verification/Verification";

/**
 * 
 */
export default function PublicVerification() {
    return (
        <div>
            <CitizensHeader />

            <Verification showBackbutton />
        </div>
    )
}