import React, { useEffect, useState } from "react";
import { StyledSelect } from "./styles/StyledSelect";
import { setSelectValue } from "../../../../_redux/slices/formTemplates";
import { connect, useSelector } from "react-redux";

function Select({
  label,
  options,
  register,
  elementId,
  setValue,
  setSelectValue,
  properties = {},
  value,
  isPublic,
  entity,
}) {
  const [val, setVal] = useState(value);
  const select = useSelector((state) => state.formTemplates.select);
  useEffect(() => {
    if (elementId && elementId.startsWith("select_gender")) {
      const [pref, suff] = elementId.split("__");
      if (suff === "en" && select) {
        const gender = select.find((s) => s.key === `${pref}__np`)?.value;
        gender &&
          setValue(elementId, translateGender(gender)) &&
          setVal(translateGender(gender));
      } else if (suff === "np" && select) {
        const gender = select.find((s) => s.key === `${pref}__en`)?.value;
        gender &&
          setValue(elementId, translateGender(gender)) &&
          setVal(translateGender(gender));
      }
    }
  }, [elementId, select, setValue]);
  if (isPublic && entity === "private") {
    return null;
  }

  function translateGender(val) {
    switch (val) {
      case "पुरुष":
        return "male";
      case "महिला":
        return "female";
      case "अन्य":
        return "other";
      case "male":
        return "पुरुष";
      case "female":
        return "महिला";
      case "other":
        return "अन्य";
      default:
        return null;
    }
  }

  return (
    <StyledSelect>
      <label>{label} :</label>
      <div className="inputDiv">
        {/* <input
            className="small inputselect"
            type="number"
            value={value}
            {...register(elementId)}
            onChange={(e) => {
              setVal(e.target.value);
              register(elementId).onChange(e);
            }}
          /> */}
        <select
          className=""
          value={val}
          {...register(elementId)}
          onChange={(e) => {
            setVal(e.target.value);
            register(elementId).onChange(e);
            if (elementId.startsWith("select_gender")) {
              setSelectValue({ key: elementId, value: e.target.value });
            }
          }}
          disabled={properties.readOnly}
        >
          {Object.keys(options).map((key) => {
            return (
              <option key={key} value={options[key]}>
                {options[key]}
              </option>
            );
          })}
        </select>
      </div>
    </StyledSelect>
  );
}

Select.defaultProps = {
  label: "Gender",
  options: {
    "": "---लिङ्ग छान्नुहोस्---",
    Male: "Male",
    Female: "Female",
    Other: "Other",
  },
};

export default connect(null, { setSelectValue })(Select);
