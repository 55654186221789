/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { FaCheckCircle, FaTrash } from 'react-icons/fa';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { connect, useSelector } from 'react-redux';
import {
	fetchDesignTemplate,
	deleteDesignTemplate,
	activateDesignTemplate,
	deactivateDesignTemplate,
} from '../../../_redux/slices/designTemplates';
import { useMemo } from 'react';
import CustomTable from '../../../components/Table';
import { hasSSO } from '../../../_helpers/methods';

const actionButtonStyleOverwrite = css`
	font-size: 12px;
	padding: 0.25rem 0.375rem !important;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
`;

const DesignTempate = ({
	fetchDesignTemplate,
	designTemplates,
	activateDesignTemplate,
	deactivateDesignTemplate,
	deleteDesignTemplate,
}) => {
	useEffect(() => {
		fetchDesignTemplate();
	}, [fetchDesignTemplate]);
	const [show, setShow] = useState(false);
	const onActive = (item, enable) => {
		if (enable) {
			activateDesignTemplate(item.id);
		} else {
			deactivateDesignTemplate(item.id);
		}
		setShow(null);
	};

	const handleDeleteDesignTemplate = (designTemplateId) => {
		deleteDesignTemplate(designTemplateId);
		setShow(null);
	};

	const loading = useSelector((state) => state.designTemplates.loading);

	const headers = useMemo(() => {
		if (designTemplates.length > 0) {
			return [
				{
					key: 'title',
					title: 'शीर्षक',
				},
				{
					key: 'choices',
					title: 'प्रकार',
				},
				{
					key: 'organization',
					title: 'वडा',
				},
				{
					key: 'status',
					title: 'स्थिति',
					render: (value) => (
						<FaCheckCircle
							className={
								value.is_enabled === true ? `text-success ml-1` : `ml-1`
							}
						/>
					),
				},
				...(!hasSSO()
					? [
							{
								key: 'actions',
								title: 'कार्यहरू',
								render: (item) => (
									<Fragment>
										{item.is_enabled === true ? (
											<Button
												variant="secondary"
												css={actionButtonStyleOverwrite}
												onClick={() =>
													setShow({
														type: 'activate',
														item: item,
														enable: false,
													})
												}
											>
												<AiFillCloseCircle size={12} /> Deactivate
											</Button>
										) : (
											<Button
												variant="primary"
												css={actionButtonStyleOverwrite}
												onClick={() =>
													setShow({
														type: 'activate',
														item: item,
														enable: true,
													})
												}
											>
												<AiFillCheckCircle size={12} /> Activate
											</Button>
										)}
										<Button
											variant="danger"
											css={actionButtonStyleOverwrite}
											className="ml-2"
											onClick={() => setShow({ type: 'delete', item: item })}
										>
											<FaTrash size={12} /> हटाउनु
										</Button>
									</Fragment>
								),
							},
					  ]
					: []),
			];
		}
		return [];
	}, [designTemplates]);
	return (
		<Fragment>
			<Row className="mt-2">
				<Col md={12}>
					<Card
						css={css`
							border-radius: 8px;
							box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
						`}
					>
						<Card.Header className="card-header-custom px-3">
							<h2>टेम्प्लेट</h2>
						</Card.Header>

						<Card.Body className="py-0 px-3">
							<CustomTable
								headers={headers}
								data={designTemplates?.length > 0 ? designTemplates : []}
								isLoading={loading}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Modal show={Boolean(show)} onHide={() => setShow(null)} centered>
				<Modal.Header>
					<h3>Template</h3>
				</Modal.Header>
				<Modal.Body>
					<p className="h5 font-weight-lighter">
						{show?.type === 'activate'
							? 'के तपाइँ यो Template सक्रिय गर्न चाहानुहुन्छ?'
							: 'के तपाइँ यो Template हटाउन चाहानुहुन्छ?'}
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="btn btn-secondary"
						onClick={() => {
							setShow(null);
						}}
					>
						रद्द गर्नुहोस्
					</Button>
					<Button
						className="btn btn-primary"
						onClick={() => {
							if (show?.type === 'activate') {
								onActive(show?.item, show?.enable);
							} else {
								handleDeleteDesignTemplate(show?.item.id);
							}
						}}
					>
						सुनिश्चित गर्नुहोस्
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	designTemplates: state.designTemplates.designTemplates,
});

const mapDispatchToProps = {
	fetchDesignTemplate,
	deleteDesignTemplate,
	activateDesignTemplate,
	deactivateDesignTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(DesignTempate);
