import "./sass/style.scss";
import { Helmet } from "react-helmet";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Templates from "./pages/Templates/Templates";
import TemplateSingle from "./pages/Templates/TemplateSingle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TemplateForm from "./pages/Templates/TemplateForm";
import TemplateOld from "./pages/Templates/TemplateOld";
import Verified from "./pages/Papers/Verified";
import Users from "./pages/Users/Users";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import { PrivateRoute } from "./auth-route";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { clearUiMessage } from "./_redux/slices/ui";
import { getUserData } from "./_redux/slices/users";
import { fetchActiveFiscalYear } from "./_redux/slices/fiscalyear";
import { fetchFiscalYear } from "./_redux/slices/fiscalyear";
import Settings from "./pages/Settings/Settings";
import FiscalYear from "./pages/Settings/FiscalYear";
import Ward from "./pages/Settings/Ward/Ward";
import AllUsers from "./pages/Settings/Users/AllUsers";
import WardUser from "./pages/Settings/Users/WardUser";
import SifarishType from "./pages/Settings/SifarishType";
import DocumentsType from "./pages/Settings/Documents/DocumentsType";
import FormerMunicipal from "./pages/Settings/FormerMunicipal/FormerMunicipal";
import Applicant from "./pages/Settings/Applicant/Applicant";
import PaperSingle from "./pages/Templates/PaperSingle";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Report from "./pages/Report";
import AddWard from "./pages/Settings/Ward/AddWard";
import RenewPapers from "./pages/RenewPapers";
import CitizenTemplateSingle from "./pages/CitizenTemplateSingle";
import CitizensHome from "./pages/Citizens/CitizensHome";
import CitizensTemplate from "./pages/Citizens/CitizensTemplate";
import CitizensFormSubmitted from "./pages/Citizens/CitizensFormSubmitted";
import SifarishStatus from "./pages/Citizens/SifarishStatus";
// import Faq from './pages/Faq';
import Support from "./pages/Support";
import NagarpalikaDetails from "./pages/NagarpalikaDetails/NagarpalikaDetails";
import MayorTab from "./pages/Settings/MayorTab/MayorTab";
import { fetchNagarpalika } from "./_redux/slices/nagarpalika";
import Profile from "./pages/Settings/Profile/Profile";
import SifarishVerification from "./pages/SifarishVerification/SifarishVerification";
import Layout from "./layout/Layout";
import DesignTempate from "./pages/Settings/DesignTemplate";
import ApplicantPreview from "./pages/ApplicantPreview";
import PublicVerification from "./pages/PublicVerification/PublicVerification";

function App({
  clearUiMessage,
  fetchNagarpalika,
  getUserData,
  fetchActiveFiscalYear,
  fetchFiscalYear,
}) {
  const message = useSelector((state) => state.ui.message);
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const nagarpalikaInfo = useSelector(
    (state) => state?.nagarpalika?.nagarpalika
  );

  useEffect(() => {
    fetchNagarpalika();
  }, [fetchNagarpalika]);

  useEffect(() => {
    if (isLoggedIn) {
      getUserData();
      fetchActiveFiscalYear();
      fetchFiscalYear();
    }
  }, [getUserData, fetchActiveFiscalYear, fetchFiscalYear, isLoggedIn]);

  useEffect(() => {
    if (message) {
      if (message.error === false) {
        toast.success(message.message);
        clearUiMessage();
      } else if (message) {
        toast.error(message.message);
        clearUiMessage();
      } else {
        toast.success(message);
      }
    }
  }, [message, clearUiMessage]);

  return (
    <Router>
      <Helmet>
        <title>
          {`${
            nagarpalikaInfo?.name_np ? `${nagarpalikaInfo?.name_np} | ` : ""
          } संघ संस्था दर्ता`}
        </title>
      </Helmet>

      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/citizens" component={CitizensHome} />
        <Route exact path="/status" component={SifarishStatus} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/password/reset" component={ResetPassword} />

        <Route
          exact
          path="/citizen-template/paper-submitted/:token"
          component={CitizensFormSubmitted}
        />
        <Route
          exact
          path="/citizen-template/:id"
          component={CitizensTemplate}
        />

        <Route
          exact
          path="/public/certificate/verification"
          component={PublicVerification}
        />
        <Route
          path="/"
          render={({ match }) => <RootRoutes basePath={match.path} />}
        />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
}

export default connect(null, {
  clearUiMessage,
  getUserData,
  fetchNagarpalika,
  fetchActiveFiscalYear,
  fetchFiscalYear,
})(App);

const RootRoutes = ({ basePath }) => (
  <Layout>
    <PrivateRoute
      exact
      path="/"
      component={Dashboard}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/applicant/:id"
      component={ApplicantPreview}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/reports"
      component={Report}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings"
      component={Settings}
      accessGrantedRoles={["super_admin", "ito_admin", "ward_admin"]}
    />
    <PrivateRoute
      exact
      path="/settings/fiscalyear"
      component={FiscalYear}
      accessGrantedRoles={["super_admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/settings/designtemplate"
      component={DesignTempate}
      accessGrantedRoles={["super_admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/settings/ward"
      component={Ward}
      accessGrantedRoles={["super_admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/settings/allusers/:pageNum"
      component={AllUsers}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/warduser/:id"
      component={WardUser}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/profile"
      component={Profile}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/sifarishtype"
      component={SifarishType}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />

    <PrivateRoute
      exact
      path="/settings/documentstype/:pageNum"
      component={DocumentsType}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/mayor"
      component={MayorTab}
      accessGrantedRoles={["super_admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/settings/formermunicipal"
      component={FormerMunicipal}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/applicants"
      component={Applicant}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    {/* <PrivateRoute
			exact
			path="/faq"
			component={Faq}
			accessGrantedRoles={['super_admin', 'ito_admin', 'ward_admin', 'ward_user', 'ward_paper_issuer']}
		/> */}
    <PrivateRoute
      exact
      path="/support"
      component={Support}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/ward/create/"
      component={AddWard}
      accessGrantedRoles={["super_admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/ward/update/:id"
      component={AddWard}
      accessGrantedRoles={["super_admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/nagarpalika/:pageNum"
      component={NagarpalikaDetails}
      accessGrantedRoles={["super_admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/certificate/verification"
      component={SifarishVerification}
      accessGrantedRoles={["super_admin", "ito_admin", "ward_admin"]}
    />
    <PrivateRoute
      exact
      path="/users"
      component={Users}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />

    <PrivateRoute
      exact
      path="/verify/:pageNum"
      component={RenewPapers}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/citizen/template/:id"
      component={CitizenTemplateSingle}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/papers/verified/:pageNum"
      component={Verified}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />

    <PrivateRoute
      exact
      path="/template-single/:id"
      component={TemplateForm}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/template-single"
      component={TemplateSingle}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />

    <PrivateRoute
      exact
      path="/paper-single/:paperId"
      component={PaperSingle}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/paper-single/:paperId/:citizen"
      component={PaperSingle}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/templates/:pageNum"
      component={Templates}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/template-old"
      component={TemplateOld}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
  </Layout>
);
