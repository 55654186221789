import React from "react";
// import { dynamicValidator } from "../../../../_helpers/dynamicValidator";
import InputField from "../../../InputField";

function Text({
  type = "text",
  label,
  register,
  elementId,
  language,
  className,
  placeholder,
  maxlength,
  value,
  setValue,
  errors = {},
  properties = {},
  isPublic,
  validation,
  entity,
}) {
  if (isPublic && entity === "private") {
    return null;
  }
  return (
    <div className="py-2">
      <label htmlFor={elementId}>
        {label} :{" "}
        <InputField
          type={type}
          language={language}
          className={`${className} input ${
            properties?.isRequired ? "textbox_req" : ""
          } ${errors[elementId] ? "is-invalid" : ""} `}
          register={register}
          elementId={elementId}
          placeholder={placeholder}
          maxLength={maxlength}
          value={value}
          setValue={setValue}
          properties={properties}
          validation={validation}
        />
      </label>
    </div>
  );
}

export default Text;
