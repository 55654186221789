import React from "react";
import { dynamicValidator } from "../../../../_helpers/dynamicValidator";
import InputField from "../../../InputField";

function Number({
  label,
  language,
  className,
  placeholder,
  register,
  errors = {},
  elementId,
  value,
  properties = {},
  setValue,
  isPublic,
  entity,
}) {
  if (isPublic && entity === "private") {
    return null;
  }

  let validation = {};
  if (properties) {
    validation = dynamicValidator(properties);
  }
  return (
    <div className="d-flex">
      <label htmlFor={elementId}>
        {label} :{" "}

        <InputField
          type="number"
          language={language}
          className={`${className} input ${properties?.isRequired ? "textbox_req" : ""
            } ${errors[elementId] ? "is-invalid" : ""} `}
          register={register}
          errors={errors}
          elementId={elementId}
          placeholder={placeholder}
          maxLength={properties?.length}
          value={value}
          properties={properties}
          validation={validation}
          setValue={setValue}
        />
      </label>
    </div>
  );
}

export default Number;
