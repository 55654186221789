import React from "react";

function Checkbox({ text, register, elementId, value }) {
  return (
    <div>
      {text}
      <input type="checkbox" {...register(elementId)} defaultValue={value} />
    </div>
  );
}

export default Checkbox;
