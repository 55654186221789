/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';
import { connect, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
	createSifarishType,
	fetchSifarishTypes,
	deleteSifarishType,
	editSifarishType,
} from '../../_redux/slices/sifarishTypes';
import InputField from '../../components/InputField';
import { InputFieldStyleOverwrite } from './Ward/AddWard';
import { languageSelector } from '../../_redux/slices/languages';
import { useMemo } from 'react';
import CustomTable from '../../components/Table';
import { hasSSO } from '../../_helpers/methods';

const actionButtonStyleOverwrite = css`
	font-size: 12px;
	padding: 0.5rem 0.5rem !important;
	display: inline-grid;
	place-content: center;
`;

const SifarishType = ({
	fetchSifarishTypes,
	createSifarishType,
	editSifarishType,
	deleteSifarishType,
}) => {
	const sifarishTypes = useSelector(
		(state) => state.sifarishTypes.sifarishTypes
	);
	const loading = useSelector((state) => state.sifarishTypes.loading);
	const { language } = useSelector(languageSelector);
	useEffect(() => {
		fetchSifarishTypes();
	}, [fetchSifarishTypes]);

	const [showAdd, setShowAdd] = useState(false);
	const [editType, setEditType] = useState(false);
	const [show, setShow] = useState(false);
	const { register, handleSubmit, setValue } = useForm({
		mode: 'onBlur',
		defaultValues: {},
	});

	useEffect(() => {
		if (showAdd?.title) {
			setValue('title', showAdd?.title);
		}
	}, [setValue, showAdd?.title]);

	const onSubmit = (data) => {
		if (editType) {
			data.id = showAdd.id;
			editSifarishType(data, setShowAdd);
			setEditType(false);
		} else {
			createSifarishType(data, setShowAdd);
		}
	};

	const handleDeleteSifarishType = async () => {
		await deleteSifarishType(show.id);
		setShow(null);
	};

	const headers = useMemo(() => {
		if (sifarishTypes?.length > 0) {
			return [
				{
					key: 'title',
					title: 'शीर्षक',
					headerStyleOverwrite: css`
						width: 20%;
					`,
				},
				{
					key: 'templates',
					title: 'छनौट',
					render: (value) => (
						<>
							{value.templates &&
								value.templates.map((template, index) => (
									<>
										{template.title}
										{value.templates.length - 1 !== index && ', '}
									</>
								))}
						</>
					),
				},
				...(!hasSSO()
					? [
							{
								key: 'actions',
								title: 'कार्यहरू',
								headerStyleOverwrite: css`
									width: 20%;
								`,
								render: (value) => (
									<>
										<Button
											variant="info"
											css={actionButtonStyleOverwrite}
											className="mr-1"
											onClick={() => {
												setEditType(true);
												setShowAdd(value);
											}}
										>
											<FaEdit />
										</Button>
										<Button
											variant="danger"
											css={actionButtonStyleOverwrite}
											onClick={() => setShow(value)}
										>
											<FaTrash />
										</Button>
									</>
								),
							},
					  ]
					: []),
			];
		}
		return [];
	}, [sifarishTypes]);

	return (
		<Fragment>
			{!hasSSO() && showAdd && (
				<Card
					className="mt-2"
					css={css`
						border-radius: 9px !important;
						box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
						margin-bottom: 1rem;
					`}
				>
					<Card.Header className="card-header-custom d-block px-3">
						<h3>
							{editType
								? 'प्रमाण पत्रको प्रकार सम्पादन गर्नुहोस्'
								: ' नयाँ प्रमाण पत्रको प्रकार'}
						</h3>
					</Card.Header>
					<Card.Body className="pb-0 pt-2 px-1 ">
						<Row className="">
							<Col md={12}>
								<Form onSubmit={handleSubmit(onSubmit)}>
									<div className="col col-4">
										<div className="form-group">
											<label className="mb-0">
												{language === 'english' ? 'Title' : 'शीर्षक'}
											</label>
											<InputField
												css={InputFieldStyleOverwrite}
												type="text"
												className={`textbox_req`}
												language={language}
												classNameName={`input`}
												elementId={'title'}
												placeholder={
													language === 'english'
														? 'Enter Certificate type title'
														: 'प्रमाण पत्रको प्रकारको शीर्षक'
												}
												maxLength={50}
												register={register}
												isRequired={true}
												setValue={setValue}
											/>
										</div>
									</div>
									<div className="col col-4 pb-3">
										<Button
											className="mr-2"
											variant="primary"
											onClick={() => {
												setShowAdd(false);
												setValue('title', '');
												setEditType(true);
											}}
										>
											रद्द गर्नुहोस्
										</Button>
										{showAdd?.eventType === 'edit' ? (
											<Button variant="success" type="submit">
												अपडेट गर्नुहोस्
											</Button>
										) : (
											<Button variant="success" type="submit">
												सुरक्षित गर्नुहोस्
											</Button>
										)}
									</div>
								</Form>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			)}
			<Row className="mt-2">
				<Col md={12}>
					<Card
						css={css`
							border-radius: 8px;
							box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
						`}
					>
						<Card.Header className="card-header-custom px-3">
							<h2>प्रमाण पत्रको प्रकार</h2>
							{!hasSSO() && (
								<Button
									variant="primary"
									onClick={() => {
										setShowAdd(true);
										setEditType(false);
										setValue('title', '');
									}}
								>
									<FaPlusCircle />
									&nbsp; नयाँ प्रमाण पत्रको प्रकार
								</Button>
							)}
						</Card.Header>
						<Card.Body className="py-0 px-3">
							<CustomTable
								headers={headers}
								data={sifarishTypes?.length > 0 ? sifarishTypes : []}
								isLoading={loading}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Modal show={Boolean(show)} onHide={() => setShow(null)} centered>
				<Modal.Header>प्रमाण पत्रको प्रकार</Modal.Header>
				<Modal.Body>
					<p className="h5 font-weight-lighter">
						के तपाइँ यो प्रमाण पत्रको प्रकार हटाउन चाहानुहुन्छ?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button className="btn btn-secondary" onClick={() => setShow(null)}>
						रद्द गर्नुहोस्
					</Button>
					<Button className="btn btn-danger" onClick={handleDeleteSifarishType}>
						सुनिश्चित गर्नुहोस्
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

const mapDispatchToProps = {
	fetchSifarishTypes,
	createSifarishType,
	editSifarishType,
	deleteSifarishType,
};

export default connect(null, mapDispatchToProps)(SifarishType);
