/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import { setUiMessage } from '../../../_redux/slices/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { wardValidationSchema } from '../../../_helpers/schema-validation';
import InputField from '../../../components/InputField';
import { VscLoading } from 'react-icons/vsc';

export const InputFieldStyleOverwrite = css`
	border: 1px solid #ccc !important;
	border-radius: 0.25rem;
	width: 164px !important;
	height: calc(1.5em + 0.75rem + 2px);
	color: #333 !important;
	padding: 3px 4px !important;
	background-color: #fff !important;
	font: 1rem 'Kalimati', 'sans serif' ;

	:focus {
		color: #495057;
		background-color: #fff;
		border-color: #80bdff;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
	}
`;
const roles = [
	{
		role: 'ward_admin',
		title: 'शाखा प्रमुखको विवरण',
	},
	{
		role: 'ward_user',
		title: 'शाखा प्रयोगकर्ता विवरण',
	},
	{
		role: 'ward_kawa',
		title: 'शाखा का.वा विवरण',
	},
];
const AddWard = () => {
	const { id } = useParams();
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		unregister,
		getValues,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		shouldUnregister: true,
		resolver: yupResolver(wardValidationSchema),
	});
	const dispatch = useDispatch();
	const history = useHistory();
	const [defaultVal, setDefaultVal] = useState(null);
	const [nagarpalikas, setNagarpalikas] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [hasKawa, setHasKawa] = useState(false);

	useEffect(() => {
		if (!id) {
			if (hasKawa) {
				register(`users[2].username`);
				register(`users[2].email`);
				register(`users[2].password`);
				register(`users[2].profile.fullname_np`);
				register(`users[2].profile.fullname_en`);
				register(`users[2].profile.phone_np`);
				register(`users[2].profile.phone_en`);
				register(`users[2].profile.phone_np`);
				register(`users[2].profile.post_np`);
				register(`users[2].profile.post_en`);
			} else {
				reset({
					...getValues(),
					'users[2].username': undefined,
					'users[2].email': undefined,
					'users[2].password': undefined,
					'users[2].profile.fullname_np': undefined,
					'users[2].profile.fullname_en': undefined,
					'users[2].profile.phone_np': undefined,
					'users[2].profile.phone_en': undefined,
					'users[2].profile.post_en': undefined,
					'users[2].profile.post_np': undefined,
				});
				unregister([
					`users[2].username`,
					`users[2].email`,
					`users[2].password`,
					`users[2].profile.fullname_np`,
					`users[2].profile.fullname_en`,
					`users[2].profile.phone_np`,
					`users[2].profile.phone_en`,
					`users[2].profile.post_en`,
					`users[2].profile.post_np`,
				]);
			}
		}
	}, [id, hasKawa, reset, register, unregister, getValues]);

	useEffect(() => {
		if (id) {
			const getWard = async () => {
				try {
					const res = await fetchWrapper.get(`/ward/${id}/`);
					setDefaultVal(res.data.data);
				} catch (err) { }
			};
			getWard();
		}
	}, [id]);

	useEffect(() => {
		setValue('name_np', defaultVal?.name_np || defaultVal?.name);
	}, [setValue, defaultVal?.name_np, defaultVal?.name]);

	useEffect(() => {
		setValue('name_en', defaultVal?.name_en || defaultVal?.name);
	}, [setValue, defaultVal?.name_en, defaultVal?.name]);

	useEffect(() => {
		setValue('phone_np', defaultVal?.phone_np || defaultVal?.phone);
	}, [setValue, defaultVal?.phone_np, defaultVal?.phone]);

	useEffect(() => {
		setValue('phone_en', defaultVal?.phone_en || defaultVal?.phone);
	}, [setValue, defaultVal?.phone_en, defaultVal?.phone]);

	useEffect(() => {
		setValue('address_np', defaultVal?.address_np || defaultVal?.address);
	}, [setValue, defaultVal?.address_np, defaultVal?.address]);

	useEffect(() => {
		setValue('email', defaultVal?.email);
	}, [setValue, defaultVal?.email]);

	useEffect(() => {
		setValue('address_en', defaultVal?.address_en || defaultVal?.address);
	}, [setValue, defaultVal?.address_en, defaultVal?.address]);

	useEffect(() => {
		const getNagarpalikas = async () => {
			try {
				let response = await fetchWrapper.get('/nagarpalika/');
				let nagarpalikaResult = response.data.results;
				setNagarpalikas(nagarpalikaResult);
			} catch (err) { }
		};
		getNagarpalikas();
	}, []);

	const handleKawaPresent = (e) => {
		setHasKawa(e.target.checked);
	};

	console.log(errors);

	const onSubmit = async (data) => {
		try {
			setIsLoading(true);
			if (data && !id) {
				await fetchWrapper
					.post('/ward/', data)
					.then((res) => {
						dispatch(setUiMessage('नया वडा कार्यालय थपियो '));
						history.push('/settings/allusers/1');
					})
					.catch((err) => {
						Object.keys(err.error).forEach((errorKey) => {
							dispatch(setUiMessage(err.error[errorKey][0]));
						});
					});
			} else if (data && id) {
				await fetchWrapper
					.patch(`/ward/${id}/`, data)
					.then((res) => {
						dispatch(setUiMessage('वडा कार्यालयको जानकारी परिबर्तन गरियो ।'));
						history.push('/settings/allusers/1');
					})
					.catch((err) => {
						console.log(err, err.error?.error?.non_field_error);
						err.error?.error?.non_field_error && dispatch(setUiMessage(err.error?.error?.non_field_error[0]));
					});
			}
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
		}
	};

	return (
		<Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Card>
					<Card.Header className="card-header-custom">
						<h3>शाखा कार्यालयको विवरण</h3>
					</Card.Header>
					<Card.Body>
						<Row>
							<Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">Nagarpalika</Form.Label>
									{nagarpalikas && (
										<select
											{...register('nagarpalika')}
											css={InputFieldStyleOverwrite}
											defaultValue={defaultVal?.nagarpalika}
										>
											{nagarpalikas.map((nagarpalika) => {
												return (
													<option key={nagarpalika.id} value={nagarpalika.id}>
														{nagarpalika.name_np}
													</option>
												);
											})}
										</select>
									)}
								</Form.Group>
							</Col>
							<Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">शाखा कार्यालय</Form.Label>
									<InputField
										css={InputFieldStyleOverwrite}
										type="text"
										className={`textbox_req`}
										language={'nepali'}
										classNameName={`input`}
										elementId={'name_np'}
										placeholder={'वडा नम्बर भर्नुहोस्'}
										maxLength={50}
										register={register}
										isRequired={true}
										setValue={setValue}
									/>
									{errors.name_np ? (
										<p
											className="text-danger text-small"
											css={css`
												width: 100%;
												display: inline-block;
											`}
										>
											{errors.name_np.message}
										</p>
									) : null}
								</Form.Group>
							</Col>
							{/* <Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">वडा नम्बर (अंग्रेजीमा)</Form.Label>
									<InputField
										css={InputFieldStyleOverwrite}
										type="text"
										className={`textbox_req`}
										language={'english'}
										classNameName={`input`}
										elementId={'name_en'}
										placeholder={'Enter ward number'}
										maxLength={50}
										register={register}
										isRequired={true}
										setValue={setValue}
									/>
									{errors.name_en ? (
										<p
											className="text-danger text-small"
											css={css`
												width: 100%;
												display: inline-block;
											`}
										>
											{errors.name_en.message}
										</p>
									) : null}
								</Form.Group>
							</Col> */}
							<Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">ईमेल</Form.Label>
									<InputField
										css={InputFieldStyleOverwrite}
										type="text"
										className={`textbox_req`}
										language={'english'}
										classNameName={`input`}
										elementId={'email'}
										placeholder={'ईमेल भर्नुहोस्'}
										maxLength={50}
										register={register}
										isRequired={true}
										setValue={setValue}
									/>
									{errors.email ? (
										<p
											className="text-danger text-small"
											css={css`
												width: 100%;
												display: inline-block;
											`}
										>
											{errors.email.message}
										</p>
									) : null}
								</Form.Group>
							</Col>
							<Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">फोन</Form.Label>
									<InputField
										css={InputFieldStyleOverwrite}
										type="number"
										className={`textbox_req`}
										language={'nepali'}
										classNameName={`input`}
										elementId={'phone_np'}
										placeholder={'फोन न. भर्नुहोस्'}
										maxLength={10}
										register={register}
										isRequired={true}
										setValue={setValue}
									/>
									{errors.phone_np ? (
										<p
											className="text-danger text-small"
											css={css`
												width: 100%;
												display: inline-block;
											`}
										>
											{errors.phone_np.message}
										</p>
									) : null}
								</Form.Group>
							</Col>
							<Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">Phone No.</Form.Label>
									<InputField
										css={InputFieldStyleOverwrite}
										type="number"
										className={`textbox_req`}
										language={'english'}
										classNameName={`input`}
										elementId={'phone_en'}
										placeholder={'Enter phone number'}
										maxLength={50}
										register={register}
										isRequired={true}
										setValue={setValue}
									/>
									{errors.phone_en ? (
										<p
											className="text-danger text-small"
											css={css`
												width: 100%;
												display: inline-block;
											`}
										>
											{errors.phone_en.message}
										</p>
									) : null}
								</Form.Group>
							</Col>
							<Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">ठेगाना</Form.Label>
									<InputField
										css={InputFieldStyleOverwrite}
										type="text"
										className={`textbox_req`}
										language={'nepali'}
										classNameName={`input`}
										elementId={'address_np'}
										placeholder={'ठेगाना भर्नुहोस्'}
										maxLength={20}
										register={register}
										isRequired={true}
										setValue={setValue}
									/>
									{errors.address_np ? (
										<p
											className="text-danger text-small"
											css={css`
												width: 100%;
												display: inline-block;
											`}
										>
											{errors.address_np.message}
										</p>
									) : null}
								</Form.Group>
							</Col>
							<Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">Address</Form.Label>

									<InputField
										css={InputFieldStyleOverwrite}
										type="text"
										className={`textbox_req`}
										language={'english'}
										classNameName={`input`}
										elementId={'address_en'}
										placeholder={'Enter address'}
										maxLength={50}
										register={register}
										isRequired={true}
										setValue={setValue}
									/>
									{errors.address_en ? (
										<p
											className="text-danger text-small"
											css={css`
												width: 100%;
												display: inline-block;
											`}
										>
											{errors.address_en.message}
										</p>
									) : null}
								</Form.Group>
							</Col>
						</Row>
					</Card.Body>
				</Card>

				{!id && (
					<Fragment>
						<Card
							css={css`
								display: flex;
								flex-direction: row;
								align-items: center;
								padding: 1rem;
								font-size: 1.2rem;
								.kawaInput {
									margin-left: 1rem;
								}
							`}
						>
							<label htmlFor="kawaInput">का.वा. प्रस्तुत छ? </label>
							<input id="kawaInput" className="kawaInput" type="checkbox" onChange={handleKawaPresent} />{' '}
						</Card>
						{roles.map((role, index) => {
							let isKawa = role.role === 'ward_kawa';
							if (isKawa && !hasKawa) {
								return null;
							}
							return (
								<Card key={index}>
									<Card.Header className="card-header-custom">
										<h3>{role.title}</h3>
										{/* <h3>शाखा प्रमुखको विवरण</h3> */}
									</Card.Header>
									<Card.Body>
										<Row>
											{/* <Col md={4} className="mb-3"> */}
											{/* <Form.Group controlId="formBasicEmail">
													<Form.Label className="mb-0">प्रयोगकर्ता</Form.Label>
													<Form.Control
														// css={InputFieldStyleOverwrite}
														type="text"
														className={'textbox_req'}
														// language={'nepali'}
														classNameName={`input`}
														{...register(`users[${index}].username`)}
														placeholder={'प्रयोगकर्ता भर्नुहोस्'}
														maxLength={50}
														// register={register}
														isRequired={true}
														// setValue={setValue}
													/>
													{errors?.users && errors?.users[index]?.username ? (
														<p
															className="text-danger text-small"
															css={css`
																width: 100%;
																display: inline-block;
															`}
														>
															{errors?.users[index]?.username?.message}
														</p>
													) : null}
												</Form.Group> */}
											{/* </Col> */}
											<Col md={4} className="mb-3">
												<Form.Group controlId="formBasicEmail">
													<Form.Label className="mb-0">नाम</Form.Label>
													<InputField
														css={InputFieldStyleOverwrite}
														type="text"
														className={'textbox_req'}
														language={'nepali'}
														classNameName={`input`}
														elementId={`users[${index}].profile.fullname_np`}
														placeholder={'पूरा नाम भर्नुहोस्'}
														maxLength={50}
														register={register}
														isRequired={true}
														setValue={setValue}
													/>
													{errors?.users && errors?.users[index]?.profile?.fullname_np ? (
														<p
															className="text-danger text-small"
															css={css`
																width: 100%;
																display: inline-block;
															`}
														>
															{errors?.users[index]?.profile.fullname_np?.message}
														</p>
													) : null}
												</Form.Group>
											</Col>
											<Col md={4} className="mb-3">
												<Form.Group controlId="formBasicEmail">
													<Form.Label className="mb-0">Full Name</Form.Label>
													<Form.Control
														type="text"
														{...register(`users[${index}].profile.fullname_en`)}
														placeholder="Enter full name"
														className={'textbox_req'}
													/>
													{errors?.users && errors?.users[index]?.profile?.fullname_en ? (
														<p
															className="text-danger text-small"
															css={css`
																width: 100%;
																display: inline-block;
															`}
														>
															{errors?.users[index]?.profile?.fullname_en.message}
														</p>
													) : null}
												</Form.Group>
											</Col>
											<Col md={4} className="mb-3">
												<Form.Group controlId="formBasicEmail">
													<Form.Label className="mb-0">ईमेल</Form.Label>
													<Form.Control
														type="email"
														{...register(`users[${index}].username`)}
														placeholder="ईमेल भर्नुहोस्"
														className={'textbox_req'}
														onChange={(e) => {
															setValue(`users[${index}].username`, e.target.value);
															setValue(`users[${index}].email`, e.target.value);
														}}
													/>
													{errors?.users && errors?.users[index]?.email ? (
														<p
															className="text-danger text-small"
															css={css`
																width: 100%;
																display: inline-block;
															`}
														>
															{errors?.users[index]?.email.message}
														</p>
													) : null}
												</Form.Group>
											</Col>
											<Col md={4} className="mb-3">
												<Form.Group controlId="formBasicEmail">
													<Form.Label className="mb-0">फोन</Form.Label>
													<InputField
														css={InputFieldStyleOverwrite}
														type="number"
														className={'textbox_req'}
														language={'nepali'}
														classNameName={`input`}
														elementId={`users[${index}].profile.phone_np`}
														placeholder={'फोन न. भर्नुहोस्'}
														maxLength={10}
														register={register}
														isRequired={true}
														setValue={setValue}
													/>
													{errors?.users && errors?.users[index]?.profile?.phone_np ? (
														<p
															className="text-danger text-small"
															css={css`
																width: 100%;
																display: inline-block;
															`}
														>
															{errors?.users[index]?.profile.phone_np?.message}
														</p>
													) : null}
												</Form.Group>
											</Col>
											<Col md={4} className="mb-3">
												<Form.Group controlId="formBasicEmail">
													<Form.Label className="mb-0">Phone No.</Form.Label>
													<Form.Control
														type="text"
														{...register(`users[${index}].phone`)}
														placeholder="Enter phone number"
														className={'textbox_req'}
													/>
													{errors?.users && errors?.users[index]?.profile?.phone_en ? (
														<p
															className="text-danger text-small"
															css={css`
																width: 100%;
																display: inline-block;
															`}
														>
															{errors?.users[index]?.profile.phone_en?.message}
														</p>
													) : null}
												</Form.Group>
											</Col>
											<Col md={4} className="mb-3">
												<Form.Group controlId="formBasicEmail">
													<Form.Label className="mb-0">पद</Form.Label>
													<InputField
														css={InputFieldStyleOverwrite}
														type="text"
														className={'textbox_req'}
														language={'nepali'}
														classNameName={`input`}
														elementId={`users[${index}].profile.post_np`}
														placeholder={'पद भर्नुहोस्'}
														maxLength={50}
														register={register}
														setValue={setValue}
														isRequired={true}
													/>
													{errors?.users && errors?.users[index]?.profile?.post_np ? (
														<p
															className="text-danger text-small"
															css={css`
																width: 100%;
																display: inline-block;
															`}
														>
															{errors?.users[index]?.profile.post_np?.message}
														</p>
													) : null}
												</Form.Group>
											</Col>
											<Col md={4} className="mb-3">
												<Form.Group controlId="formBasicEmail">
													<Form.Label className="mb-0">Designation</Form.Label>
													<Form.Control
														type="text"
														placeholder="Enter post"
														{...register(`users[${index}].profile.post_en`)}
														className={'textbox_req'}
													/>
													{errors?.users && errors?.users[index]?.profile?.post_en ? (
														<p
															className="text-danger text-small"
															css={css`
																width: 100%;
																display: inline-block;
															`}
														>
															{errors?.users[index]?.profile.post_en?.message}
														</p>
													) : null}
												</Form.Group>
											</Col>
											<Col md={4} className="mb-3">
												<Form.Group controlId="formBasicEmail">
													<Form.Label className="mb-0">पासवर्ड</Form.Label>
													<Form.Control
														type="password"
														{...register(`users[${index}].password`)}
														placeholder="पासवर्ड भर्नुहोस्"
														css={InputFieldStyleOverwrite}
														isRequired={true}
														className={'textbox_req'}
													/>
													{errors?.users && errors?.users[index]?.password ? (
														<p
															className="text-danger text-small"
															css={css`
																width: 100%;
																display: inline-block;
															`}
														>
															{errors?.users[index]?.password?.message}
														</p>
													) : null}
												</Form.Group>
											</Col>
										</Row>
										<input type="hidden" value={role.role} {...register(`users[${index}].role`)} />
									</Card.Body>
								</Card>
							);
						})}
					</Fragment>
				)}
				<Card.Footer className="d-flex justify-content-end align-items-center">
					<Button variant="secondary" className="mr-2" onClick={() => history.goBack()} disabled={isLoading}>
						रद्द गर्नुहोस्
					</Button>
					<Button type="submit" variant="success" disabled={isLoading}>
						{isLoading ? <VscLoading className="spin" /> : <Fragment>{id ? 'अपडेट' : 'सुरक्षित'} गर्नुहोस्</Fragment>}
					</Button>
				</Card.Footer>
			</Form>
		</Fragment>
	);
};

export default AddWard;
