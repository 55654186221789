/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Fragment, useEffect, useState } from "react";

import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { VscLoading } from "react-icons/vsc";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import InputField from "../../../components/InputField";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import { nepaliNumberConverter } from "../../../_helpers/methods";
import { mayorValidationSchema } from "../../../_helpers/schema-validation";
import { createMayor, editMayor } from "../../../_redux/slices/mayors";
import { postWardUser, patchWardUser } from "../../../_redux/slices/ward";
import { InputFieldStyleOverwrite } from "../Ward/AddWard";

const MayorForm = ({ showAdd, setShowAdd, mayorDetail, setMayorDetail }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {},
    resolver: yupResolver(mayorValidationSchema),
  });
  const [nagarpalikas, setNagarpalikas] = useState(null);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.mayors.loading);

  const onSubmit = (data) => {
    data.phone_np = nepaliNumberConverter(data.phone_en);
    mayorDetail
      ? dispatch(
          editMayor({ ...data, id: mayorDetail.id }, setShowAdd, setMayorDetail)
        )
      : dispatch(createMayor(data, setShowAdd));
  };

  useEffect(() => {
    const getNagarpalikas = async () => {
      try {
        let response = await fetchWrapper.get("/nagarpalika/");
        let nagarpalikaResult = response.data.results;
        setNagarpalikas(nagarpalikaResult);
      } catch (err) {}
    };
    getNagarpalikas();
  }, []);

  useEffect(() => {
    setValue("fullname_np", mayorDetail?.fullname_np);
  }, [setValue, mayorDetail?.fullname_np]);

  useEffect(() => {
    setValue("fullname_en", mayorDetail?.fullname_en);
  }, [setValue, mayorDetail?.fullname_en]);

  useEffect(() => {
    setValue("address_np", mayorDetail?.address_np);
  }, [setValue, mayorDetail?.address_np]);

  useEffect(() => {
    setValue("address_en", mayorDetail?.address_en);
  }, [setValue, mayorDetail?.address_en]);

  useEffect(() => {
    setValue("phone_en", mayorDetail?.phone_en);
  }, [setValue, mayorDetail?.phone_en]);

  const editType = mayorDetail?.eventType === "edit";
  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <input
        name="email"
        autoComplete="off"
        type="text"
        readOnly
        css={css`
          display: none;
        `}
      />
      <Card>
        <Card.Header className="card-header-custom">
          <h3>{editType ? "मेयर सम्पादन गर्नुहोस्" : " नयाँ मेयर"}</h3>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group>
                <Form.Label className="mb-0">Nagarpalika</Form.Label>
                {nagarpalikas && (
                  <select
                    {...register("nagarpalika")}
                    defaultValue={mayorDetail?.nagarpalika}
                  >
                    {nagarpalikas.map((nagarpalika) => {
                      return (
                        <option key={nagarpalika.id} value={nagarpalika.id}>
                          {nagarpalika.name_np}
                        </option>
                      );
                    })}
                  </select>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {!editType && (
              <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label className="mb-0">Email</Form.Label>
                  <input
                    css={InputFieldStyleOverwrite}
                    type="email"
                    {...register("email", { required: "Email is required" })}
                    placeholder="ईमेल भर्नुहोस्"
                    className="textbox_req"
                  />
                  {errors.email ? (
                    <p
                      className="text-danger text-small"
                      css={css`
                        width: 100%;
                        display: inline-block;
                      `}
                    >
                      {errors.email.message}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
            )}

            <Col md={4} className="mb-3">
              <Form.Group>
                <Form.Label className="mb-0">नाम</Form.Label>
                <InputField
                  css={InputFieldStyleOverwrite}
                  type="text"
                  className={`textbox_req`}
                  language={"nepali"}
                  classNameName={`input`}
                  elementId={"fullname_np"}
                  placeholder={"पूरा नाम भर्नुहोस्"}
                  maxLength={50}
                  register={register}
                  isRequired={true}
                  setValue={setValue}
                />
                {errors.fullname_np ? (
                  <p
                    className="text-danger text-small"
                    css={css`
                      width: 100%;
                      display: inline-block;
                    `}
                  >
                    {errors.fullname_np.message}
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group>
                <Form.Label className="mb-0">Full Name</Form.Label>

                <InputField
                  css={InputFieldStyleOverwrite}
                  type="text"
                  className={`textbox_req`}
                  language={"english"}
                  classNameName={`input`}
                  elementId={"fullname_en"}
                  placeholder={"Enter full name"}
                  maxLength={50}
                  register={register}
                  isRequired={true}
                  setValue={setValue}
                />
                {errors.fullname_en ? (
                  <p
                    className="text-danger text-small"
                    css={css`
                      width: 100%;
                      display: inline-block;
                    `}
                  >
                    {errors.fullname_en.message}
                  </p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group>
                <Form.Label className="mb-0">Address (nepali)</Form.Label>
                <InputField
                  css={InputFieldStyleOverwrite}
                  type="text"
                  className={`textbox_req`}
                  language={"nepali"}
                  classNameName={`input`}
                  elementId={"address_np"}
                  placeholder={"Enter address"}
                  maxLength={50}
                  register={register}
                  isRequired={true}
                  setValue={setValue}
                />
                {errors.address_np ? (
                  <p
                    className="text-danger text-small"
                    css={css`
                      width: 100%;
                      display: inline-block;
                    `}
                  >
                    {errors.address_np.message}
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group>
                <Form.Label className="mb-0">Address (english)</Form.Label>
                <InputField
                  css={InputFieldStyleOverwrite}
                  type="text"
                  className={`textbox_req`}
                  language={"english"}
                  classNameName={`input`}
                  elementId={"address_en"}
                  placeholder={"Enter address"}
                  maxLength={50}
                  register={register}
                  isRequired={true}
                  setValue={setValue}
                />
                {errors.address_en ? (
                  <p
                    className="text-danger text-small"
                    css={css`
                      width: 100%;
                      display: inline-block;
                    `}
                  >
                    {errors.address_en.message}
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            {!editType && (
              <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label className="mb-0">Password</Form.Label>
                  <Form.Control
                    type="password"
                    {...register(`password`)}
                    placeholder="पासवर्ड भर्नुहोस्"
                    css={InputFieldStyleOverwrite}
                    className={`textbox_req`}
                    autoComplete={"new-password"}
                  />
                  {errors?.password ? (
                    <p
                      className="text-danger text-small"
                      css={css`
                        width: 100%;
                        display: inline-block;
                      `}
                    >
                      {errors?.password.message}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group>
                <Form.Label className="mb-0">Phone No.</Form.Label>
                <InputField
                  css={InputFieldStyleOverwrite}
                  type="number"
                  className={`textbox_req`}
                  language={"english"}
                  classNameName={`input`}
                  elementId={"phone_en"}
                  placeholder={"Enter Phone number"}
                  maxLength={50}
                  register={register}
                  isRequired={true}
                  setValue={setValue}
                />
                {errors.phone_en ? (
                  <p
                    className="text-danger text-small"
                    css={css`
                      width: 100%;
                      display: inline-block;
                    `}
                  >
                    {errors.phone_en.message}
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group>
                <Form.Label className="mb-0">भूमिका</Form.Label>
                <Form.Control
                  as={"select"}
                  {...register("role", {
                    required: true,
                  })}
                  css={InputFieldStyleOverwrite}
                  defaultValue={mayorDetail?.role}
                >
                  <option value="mayor">mayor</option>
                  <option value="upa_mayor">upa mayor</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row></Row>
        </Card.Body>

        <Card.Footer className="d-flex justify-content-end align-items-center">
          <Button
            variant="secondary"
            className="mr-2"
            onClick={() => {
              setShowAdd(!showAdd);
            }}
            disabled={loading}
          >
            रद्द गर्नुहोस्
          </Button>
          <Button type="submit" variant="success" disabled={loading}>
            {loading ? (
              <VscLoading className="spin" />
            ) : (
              <Fragment>
                {mayorDetail ? "अपडेट" : "सुरक्षित"} गर्नुहोस्
              </Fragment>
            )}
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  postWardUser,
  patchWardUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(MayorForm);
