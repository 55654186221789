import NepaliDate from "nepali-date-converter";
import { nepaliNumbers } from "../_datas";
import moment from "moment";
import { fetchWrapper } from "./fetchWrapper";
import { NEPAL_SAMBAT } from "../_datas/nepal_sambat";

export function englishNumberConverter(numString) {
  let englishNumber = "";
  for (let i = 0; i < numString.length; i++) {
    let num = numString[i];
    let englishNum = nepaliNumbers.indexOf(num);
    if (englishNum < 0) {
      englishNumber += num;
    } else {
      englishNumber += englishNum;
    }
  }
  return englishNumber;
}

export function nepaliNumberConverter(numString) {
  let nepaliNumber = "";
  for (let i = 0; i < numString?.length; i++) {
    if (nepaliNumbers.includes(numString[i])) {
      nepaliNumber += numString[i];
    } else if (nepaliNumbers[numString[i]]) {
      nepaliNumber += nepaliNumbers[numString[i]];
    } else {
      nepaliNumber += numString[i];
    }
  }
  return nepaliNumber;
}

export function getTodayMonth() {
  let todayMonth = new NepaliDate().format("M");
  todayMonth = String(todayMonth);
  if (todayMonth.length < 2) {
    return "0" + todayMonth;
  } else {
    return todayMonth;
  }
}

export function getTodayDay() {
  let todayDay = new NepaliDate().format("D");
  todayDay = String(todayDay);
  if (todayDay.length < 2) {
    return "0" + todayDay;
  } else {
    return todayDay;
  }
}

export const nepaliNumber = (number) => {
  const strNum = String(number);
  let strNepNum = "";
  for (let i = 0; i < strNum.length; i++) {
    strNepNum +=
      nepaliNumbers[parseInt(strNum.substr(i, 1))] ||
      (nepaliNumbers.includes(strNum.substr(i, 1)) && strNum.substr(i, 1)) ||
      "";
  }
  return strNepNum;
};

const generateUneditable = (data) => `<span class="nonEditable">${data}</span>`;

const generateNameRelationBox = (data, config) => {
  const noOfRow = Math.ceil(data.length / 5);
  const html = [];
  for (let index = 0; index < noOfRow; index++) {
    html.push(
      `<tr>${data
        .map(
          (data, idx) =>
            index === Math.floor(idx / 5) &&
            `<td><div class="text-container"><div class="photobox"></div><div>${
              data[config.name]
            }</div><p>(${data[config.relation]})</p></div></td>`
        )
        .filter((data) => data)
        .join("")}</tr>`
    );
  }
  return html.join("");
};

export const generatePreview = (
  paper,
  formData,
  user,
  fiscal_year,
  paperExtra,
  isHeader
) => {
  let nagarpalikaName_en =
    user.profile?.nagarpalika_info?.name_en ||
    user?.nagarpalika_info?.nagarpalika_name_en ||
    user?.nagarpalika_info?.name_en;
  let nagarpalikaName_np =
    user.profile?.nagarpalika_info?.name_np ||
    user?.nagarpalika_info?.nagarpalika_name_np ||
    user?.nagarpalika_info?.name_np;
  let nagarpalikaAddress_en =
    user.profile?.nagarpalika_info?.address_en ||
    user?.nagarpalika_info?.nagarpalika_address_en ||
    user?.nagarpalika_info?.address_en;
  let nagarpalikaAddress_np =
    user.profile?.nagarpalika_info?.address_np ||
    user?.nagarpalika_info?.nagarpalika_address_np ||
    user?.nagarpalika_info?.address_np;
  let ward_name_en =
    user.profile?.ward_info?.name_en || user?.organization?.name_en;
  let ward_name_np =
    user.profile?.ward_info?.name_np || user?.organization?.name_np;

  let tempPreviewData = paper.preview;
  let metaData = paper.meta;
  let curlyBraceGraberRegex = /\{\{(.*?)\}\}/g; // {{text1}}
  tempPreviewData = tempPreviewData.replace(curlyBraceGraberRegex, (value) => {
    let key = value.replace(/[^0-9a-zA-Z_-]+/g, ""); // text1
    if (key.startsWith("fullname")) {
      return generateUneditable(
        formData[key] &&
          `${formData[key]?.firstName} ${formData[key]?.middleName} ${formData[key]?.lastName}`
      );
    } else if (key.includes("_logo")) {
      return formData[key];
    } else if (key.startsWith("date") || key.includes("date")) {
      let properties = key.split("-");
      key = key.split("-")[0];
      if (formData[key] && typeof formData[key] === "object") {
        let joinedDate =
          formData[key]["year"] +
          " | " +
          formData[key]["month"] +
          " | " +
          formData[key]["day"];
        try {
          if (key.includes("today")) {
            let englishified = englishNumberConverter(joinedDate);
            let hasNepalSambat = false;
            let isEnglish = joinedDate[key]?.year <= new Date().getFullYear();
            if (englishified.length > 8) {
              hasNepalSambat = true;
              let [yyyy, mm, dd] = englishified.split(" | ");
              let joinedDateAD;

              if (!isEnglish) {
                joinedDateAD = new NepaliDate(+yyyy, +(mm - 1), +dd).getAD();
                joinedDateAD = `${joinedDateAD.year}-${(
                  "0" +
                  (joinedDateAD.month + 1)
                ).slice(-2)}-${("0" + joinedDateAD.date).slice(-2)}`;
              }
              let nepalSambatDate = NEPAL_SAMBAT[joinedDateAD];
              if (nepalSambatDate) {
                if (properties.includes("same_line")) {
                  joinedDate += `<span> ( ने.सं. :- ${nepalSambatDate} )</span>`;
                } else {
                  joinedDate += `<span style='font-weight: 600'><br/>ने.सं. :- ${nepalSambatDate}</span>`;
                }
              }
            }
            if (hasNepalSambat) {
              return `<span style='font-family:"ui-sans-serif"'>${joinedDate}</span>`;
            }
          }
        } catch (error) {
          console.log(error, "error");
        }

        return generateUneditable(joinedDate);
      } else {
        return generateUneditable(formData[key]);
      }
    } else if (key.startsWith("dob")) {
      if (formData[key] && typeof formData[key] === "object") {
        let joinedDate = Object.values(formData[key]).join("/ ");
        return generateUneditable(joinedDate);
      } else {
        return generateUneditable(formData[key]);
      }
    } else if (key === "nagarpalika_name_np") {
      return generateUneditable(nagarpalikaName_np);
    } else if (key === "nagarpalika_name_en") {
      return generateUneditable(nagarpalikaName_en);
    } else if (key === "nagarpalika_address_np") {
      return generateUneditable(nagarpalikaAddress_np);
    } else if (key === "nagarpalika_address_en") {
      return generateUneditable(nagarpalikaAddress_en);
    } else if (key === "ward_name_np") {
      return generateUneditable(ward_name_np);
    } else if (key === "ward_name_en") {
      return generateUneditable(ward_name_en);
    }
    // else if (key === 'darta_number') {
    // 	return generateUneditable(fiscal_year);
    // }
    else if (key === "fy") {
      return generateUneditable(fiscal_year?.label);
    } else if (key === "fy_start") {
      return generateUneditable(fiscal_year?.label?.split("/")[0]);
    } else if (key === "fy_end") {
      return generateUneditable("20" + fiscal_year?.label?.split("/")[1]);
    } else if (key === "renewed") {
      return formData["renewed"]
        ? generateUneditable("नविकरण मिति: " + formData["renewed"])
        : "";
    } else if (metaData && metaData[key]) {
      return generateUneditable(metaData[key]);
    } else if (key.startsWith("image")) {
      return `<img src="${formData[key]}" style='width:100%;height:100%;object-fit:cover;object-position:center;' alt=''/>`;
    }

    // is handled in the template itself
    // else if (key.startsWith('post')) {
    // 	return '';
    // } else if (key.startsWith('admin_name')) {
    // 	return '';
    // }
    else if (key.startsWith("post")) {
      let post;
      try {
        // ward_admin => admin-name_admin-post ,, ram_adhakchya
        post = formData["ward_admin"]?.split("_")[1];
        if (post) {
          return generateUneditable(post);
        } else {
          post = "";
        }
      } catch (err) {
        post = "";
      }
      return generateUneditable(post);
    } else if (key.startsWith("admin_name")) {
      let admin_name;
      try {
        admin_name = formData["ward_admin"]?.split("_")[0];
        if (admin_name) {
          return admin_name;
        } else {
          admin_name = "";
        }
      } catch (err) {
        admin_name = "";
      }
      return generateUneditable(admin_name);
    } else if (key === "relation") {
      let sex = formData["select2"];
      if (sex === "male") {
        return generateUneditable("छोरा");
      } else if (sex === "female") {
        return generateUneditable("छोरी");
      } else {
        return generateUneditable("पुत्र");
      }
    } else if (key === "respect") {
      let sex = formData["select4"];
      if (sex === "male") {
        return generateUneditable("श्रीमान");
      } else if (sex === "female") {
        return generateUneditable("श्री");
      } else {
        return generateUneditable("श्री");
      }
    } else if (key === "age") {
      // 1997-01-22
      let age = moment().diff(
        Object.values(formData["date_of_birth_AD"]).join("-"),
        "years"
      );
      return generateUneditable(nepaliNumber(age));
    } else if (key.startsWith("table")) {
      if (formData[key]?.length > 0) {
        let tableData =
          '<div class="content-container"><table class="table table-bordered" id="table_resize" border="0" cellpadding = "0" cellspacing="0"><tr>';
        const tempHeadings = Object.keys(formData[key][0]);
        const headings = tempHeadings.filter(
          (head) => !head.includes("__fileupload__")
        );
        // const costumeKey = "table";
        // "table__r__1,2"
        const costumeKey = key;

        // eslint-disable-next-line no-unused-vars
        const [_, relationFields] = costumeKey.split("__r__");
        let name, relation;

        if (relationFields) {
          [name, relation] = relationFields.split("_");
        }
        const sortedHeadings = headings.sort();
        const headingData = sortedHeadings
          .map((heading, headingIndex) =>
            headingIndex === 0
              ? `<th style="width:60px;">${heading
                  .split("___")[1]
                  .split("####")
                  .join(".")
                  .split("&&&&")
                  .join("'")}</th>`
              : `<th>${heading
                  .split("___")[1]
                  .split("####")
                  .join(".")
                  .split("&&&&")
                  .join("'")}</th>`
          )
          .join("");
        tableData += headingData;
        const rows = formData[key].map(
          (rowData) =>
            `<tr>${headings
              .map((heading, headingIndex) =>
                headingIndex === 0
                  ? `<td style="max-width:25px;width:25px;">${rowData[heading]}</td>`
                  : `<td>${rowData[heading]}</td>`
              )
              .join("")}</tr>`
        );
        if (relationFields) {
          tableData += `</tr>${rows.join(
            ""
          )}</table></div><div class="tablephotocontainer"><table class="table" id="table_resize" border="0" cellpadding = "0" cellspacing="0">${generateNameRelationBox(
            formData[key],
            {
              name: sortedHeadings[name],
              relation: sortedHeadings[relation],
            }
          )}</table></div>`;
        } else tableData += `</tr>${rows.join("")}</table></div>`;
        return generateUneditable(tableData);
      }
      return null;
    } else {
      return generateUneditable(formData[key]);
    }
  });

  return tempPreviewData;
};

export function getDifference(a, b) {
  var i = 0;
  var j = 0;
  var result = "";
  let position;
  while (j < b.length) {
    if (a[i] !== b[j] || i === a.length) {
      result = b[j];
      position = j;
      break;
    } else i++;
    j++;
  }
  return { result, position };
}

export function normalizeHtml(str) {
  return (
    str &&
    str
      .replace(/&nbsp;|\u202F|\u00A0/g, " ")
      .replace(/&lt;/g, "ÿ") // < and <</p> causes a problem because 1st character is equal
      //and it always takes second one so this little hack prevents using  < at any cost
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&")
  );
}

function createRange(node, chars, range) {
  if (!range) {
    range = document.createRange();
    range.selectNode(node);
    range.setStart(node, 0);
  }

  if (chars.count === 0) {
    range.setEnd(node, chars.count);
  } else if (node && chars.count > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length < chars.count) {
        chars.count -= node.textContent.length;
      } else {
        range.setEnd(node, chars.count);
        chars.count = 0;
      }
    } else {
      for (var lp = 0; lp < node.childNodes.length; lp++) {
        range = createRange(node.childNodes[lp], chars, range);

        if (chars.count === 0) {
          break;
        }
      }
    }
  }

  return range;
}

export function setCurrentCursorPosition(
  chars,
  preview = "preview",
  insertCharacter
) {
  if (chars >= 0) {
    const selection = window.getSelection();

    let range = createRange(document.getElementById(preview).parentNode, {
      count: chars,
    });

    if (range) {
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
}

export function getCurrentCursorPosition() {
  const element = document.getElementById("preview");
  var caretOffset = 0;
  var doc = element.ownerDocument || element.document;
  var win = doc.defaultView || doc.parentWindow;
  var sel;
  if (typeof win.getSelection != "undefined") {
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
      var range = win.getSelection().getRangeAt(0);
      var preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
  } else if ((sel = doc.selection) && sel.type !== "Control") {
    var textRange = sel.createRange();
    var preCaretTextRange = doc.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint("EndToEnd", textRange);
    caretOffset = preCaretTextRange.text.length;
  }
  return caretOffset;
}

export const debounce = (func, delay = 500) => {
  let timeout;
  return function (...args) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const urlPublicChecker = (url, isPublic = false) => {
  if (isPublic) {
    return "/public" + url;
  }
  return url;
};

export const detailExtractor = (formValues, values) => {
  try {
    let paperDetail = "";
    for (let group of values?.values) {
      let groupDetail = `<div class='row mb-3 px-2'><div class='col col-12'><h5 class='mb-2 text-primary'>${group?.title}</h5></div>`;
      for (let row of group?.row) {
        for (let element of row) {
          let detail = "";
          if (element?.id.startsWith("date") || element?.id.includes("date")) {
            if (element?.id === "date_today") {
              continue;
            }
            if (
              formValues[element?.id] &&
              typeof formValues[element?.id] === "object"
            ) {
              detail =
                formValues &&
                formValues[element?.id].year +
                  "/" +
                  formValues[element?.id].month +
                  "/" +
                  formValues[element?.id].day;
            }
          } else if (element?.id.startsWith("table")) {
            detail =
              '<div class="content-container"><table class="table table-bordered" id="table_resize" border="0" cellpadding = "0" cellspacing="0"><tr>';
            const headings = Object.keys(formValues[element?.id][0]);

            const sortedHeadings = headings.sort();
            const headingData = sortedHeadings.map((heading, headingIndex) =>
              headingIndex === 0
                ? `<th style="width:60px;font-weight:bold;">${heading
                    .split("___")[1]
                    .split("####")
                    .join(".")
                    .split("&&&&")
                    .join("'")}</th>`
                : `<th style='font-weight:bold'>${heading
                    .split("___")[1]
                    .split("####")
                    .join(".")
                    .split("&&&&")
                    .join("'")
                    .split("__fileupload__")
                    .join("")}</th>`
            );
            detail += headingData.join("");
            const rows = formValues[element?.id].map(
              (rowData) =>
                `<tr>${headings
                  .map((heading, headingIndex) =>
                    headingIndex === 0
                      ? `<td style="max-width:25px;width:25px;">${rowData[heading]}</td>`
                      : heading.includes("__fileupload__")
                      ? `<td style='display:grid;place-content:center'>${
                          rowData[heading]
                            ? `<image style='width:100px;object-fit:cover;object-position:center' src='${rowData[heading]}' alt='detail'/>`
                            : "<i>माफ गर्नुहोस्, कुनै कागजात फेला परेन</i>"
                        }</td>`
                      : `<td>${rowData[heading]}</td>`
                  )
                  .join("")}</tr>`
            );

            detail += `</tr>${rows.join("")}</table></div>`;
          } else {
            detail = formValues && formValues[element?.id];
          }

          groupDetail += element?.id.startsWith("table")
            ? `<div class="col-12">${detail}</div>`
            : `<div class="col col-md-6 row mb-1"><h6 class="col-4" style='font-weight:bold'>${element?.label}:</h6><p class="col-8">${detail}</p></div>`;
        }
      }
      groupDetail +=
        values?.values?.indexOf(group) === values?.values?.length - 1
          ? "</div>"
          : '</div><hr style="border-width:1px !important;border-color:#e4e4e4 !important;"/>';
      paperDetail += groupDetail;
    }
    return paperDetail;
  } catch (error) {
    console.log("Detail Extractor Error", error);
  }
};

export function hasSSO() {
  const sso =
    process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === "true" ||
    process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === true;
  if (sso) return true;
  return false;
}

export const handleFileUpload = async (e, id) => {
  try {
    const formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    const response = await fetchWrapper.post("/add-documents/", formdata, true);
    return response?.data?.file;
  } catch (error) {
    console.log(error);
  }
};

export const getDateYMD_AD = (minus_day = 0) => {
  const today = new Date();
  let minusDate = new Date(today);
  minusDate.setDate(today.getDate() - minus_day);

  var $dd = minusDate.getDate();
  var $mm = minusDate.getMonth() + 1; //January is 0!

  var $yyyy = minusDate.getFullYear();

  return `${$yyyy}-${("0" + $mm).slice(-2)}-${("0" + $dd).slice(-2)}`;
};

export const getDateYMD_BS = (minus_day = 0) => {
  let date = getDateYMD_AD(minus_day);
  let [yyyy, mm, dd] = date.split("-");
  mm = +mm;
  let bsDate = new NepaliDate(new Date(yyyy, mm - 1, dd)).format("YYYY-MM-DD");
  return bsDate;
};

export const getPalikaKey = (palikaName) => {
  if (!palikaName) return "";
  palikaName = palikaName.toLowerCase();
  if (palikaName.includes("bansgadhi")) {
    return "bansgadhi";
  } else if (palikaName.includes("siyari")) {
    return "siyari";
  }
};
