/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaBuilding, FaCalendar, FaFileUpload, FaSitemap } from 'react-icons/fa';
import { RiFileEditFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkPermission } from '../../_helpers/roles/check-roles';
import { englishToNepaliNumber as np } from 'nepali-number';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { ImOffice } from 'react-icons/im';

const Settings = () => {
	const user = useSelector((state) => state.users.user);
	const [stats, setStats] = useState({});
	useEffect(() => {
		fetchWrapper
			.get('/settings-stats')
			.then((res) => setStats(res.data))
			.catch((err) => console.log(err));
	}, []);

	return (
		<Fragment>
			<Row className="mt-2">
				<Col md={12}>
					<Card
						css={css`
							border-radius: 8px;
							box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
						`}
					>
						<Card.Header className="card-header-custom d-block">
							<h2 className="text-center">{'सेटिङ्स'}</h2>
						</Card.Header>
					</Card>
				</Col>

				{checkPermission(user?.role, 'view:arthikBarsa') ? (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/fiscalyear">
								<span className="settings-badge position-absolute">{np(stats?.fiscal_year || 0)}</span>
								<div className="icon">
									<FaCalendar size={48} />
									<h6 className="card-text mt-3">{'आर्थिक वर्ष'}</h6>
								</div>
							</Link>
						</div>
					</Col>
				) : null}

				<Fragment>
					{checkPermission(user?.role, 'view:wodaKaralayaHaru') ? (
						<Col md={3}>
							<div className="icon-container text-center">
								<Link to="/settings/allusers/1">
									<span className="settings-badge position-absolute">{np(stats?.ward || 0)}</span>
									<div className="icon">
										<FaBuilding size={48} />
										<h6 className="card-text mt-3">{'शाखा कार्यालयहरु'}</h6>
									</div>
								</Link>
							</div>
						</Col>
					) : null}
					{user?.role === 'ward_admin' ? (
						<Col md={3}>
							<div className="icon-container text-center">
								<Link to={`/settings/warduser/${user?.organization?.id}`}>
									<span className="settings-badge position-absolute">{np(stats?.ward || 0)}</span>
									<div className="icon">
										<FaBuilding size={48} />
										<h6 className="card-text mt-3">{'मेरो वडा कार्यालय'}</h6>
									</div>
								</Link>
							</div>
						</Col>
					) : null}
				</Fragment>

				{checkPermission(user?.role, 'view:sifarisKoPrakar') ? (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/sifarishtype">
								{
									stats?.template_cat &&
									<span className="settings-badge position-absolute">{np(stats?.template_cat)}</span>
								}
								<div className="icon">
									<FaSitemap size={48} />
									<h6 className="card-text mt-3">{'प्रमाण पत्रको प्रकार'}</h6>
								</div>
							</Link>
						</div>
					</Col>
				) : null}

				{checkPermission(user?.role, 'view:kagajatBiwaran') ? (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/documentstype/1">
								<span className="settings-badge position-absolute">{np(stats?.application || 0)}</span>
								<div className="icon">
									<FaFileUpload size={48} />
									<h6 className="card-text mt-3">{'कागजात विवरण'}</h6>
								</div>
							</Link>
						</div>
					</Col>
				) : null}

				{checkPermission(user?.role, 'view:designTemplate') ? (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/designtemplate">
								{
									stats?.design_template &&
									<span className="settings-badge position-absolute">{np(stats?.design_template)}</span>
								}
								
								<div className="icon">
									<RiFileEditFill size={48} />
									<h6 className="card-text mt-3">{'डिजाइन टेम्प्लेट'}</h6>
								</div>
							</Link>
						</div>
					</Col>
				) : null}
				{checkPermission(user?.role, 'view:nagarpalikaDetails') ? (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/nagarpalika/1">
								{/* <span className="settings-badge position-absolute">{np(stats?.nagarpalika || 0)}</span> */}
								<div className="icon">
									<ImOffice size={48} />
									<h6 className="card-text mt-3">नगरपालिकाको विवरण</h6>
								</div>
							</Link>
						</div>
					</Col>
				) : null}
			</Row>
		</Fragment>
	);
};

export default Settings;
