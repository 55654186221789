/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, {
	Fragment,
	useEffect,
	useRef,
	useState,
	useMemo,
	useCallback,
} from 'react';
import {
	Button,
	Card,
	Col,
	Row,
	Modal,
	ModalBody,
	ModalFooter,
	Form,
} from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import { MdAutorenew } from 'react-icons/md';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchWrapper } from '../_helpers/fetchWrapper';
import PaginationComp from '../components/Pagination';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { generatePreview, handleErrorResponse, nepaliNumberConverter } from '../_helpers/methods';
import NepaliDate from 'nepali-date-converter';
import { useSelector } from 'react-redux';
import { languageSelector } from '../_redux/slices/languages';
import InputField from '../components/InputField';
import { toast } from 'react-toastify';
import {
	deletePaper,
	setExpiredPapersList,
	incrementPaperCountHandler,
} from '../_redux/slices/paper';
import PreviewModel from '../components/Form/PreviewModel';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import CustomTable from '../components/Table';
import { actionButtonStyleOverwrite } from './Papers/Verified';
import { InputFieldStyleOverwrite } from './Settings/Ward/AddWard';
import { fetchFiscalYear } from '../_redux/slices/fiscalyear';
import { englishToNepaliNumber as np } from 'nepali-number';
import { VscLoading } from 'react-icons/vsc';
import { AiOutlineClose } from 'react-icons/ai';


const RenewPapers = ({
	setExpiredPapersList,
	deletePaper,
	incrementPaperCountHandler,
}) => {
	let papers = useSelector((state) => state.papers.expiredPapers);
	let count = useSelector((state) => state.papers.expiredPapersCount);
	const fiscalYear = useSelector((state) => state.fiscal.fiscalyear);
	const templateCategory = useSelector(
		(state) => state?.templateCategories?.templatesCategory
	);

	const templates = useMemo(() => {
		let temps = [];
		if (templateCategory?.length > 0) {
			templateCategory?.forEach((templt) => {
				temps = [...temps, ...templt?.templates];
			});
		}
		return temps;
	}, [templateCategory]);

	const [isLoading, setIsLoading] = useState();
	const history = useHistory();
	const { pageNum } = useParams();
	const [page, setPage] = useState(pageNum);
	const [searchText, setSearchText] = useState('');
	const { language } = useSelector(languageSelector);
	const [showRenewModal, setShowRenewModal] = useState([null, false, false, null]);
	const [paperDetail, setPaperDetail] = useState(null);
	const [paper, setPaper] = useState(null);
	const user = useSelector((state) => state.users.user);
	const [show, setShow] = useState([null, false]);
	const [previewFormData, setPreviewFormData] = useState(null);
	const [previewData, setPreviewData] = useState(null);
	const [showPreview, setShowPreview] = useState(false);
	const dispatch = useDispatch();
	const [filter, setFilter] = useState([
		['fiscal_year', null],
		['type', null],
		['template', null],
	]);

	useEffect(() => {
		dispatch(fetchFiscalYear);
	}, [dispatch]);

	const getPapersList = useCallback(async () => {
		try {
			setIsLoading(true);
			setExpiredPapersList(page, searchText, false, filter);
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
		}
	}, [filter, page, searchText, setExpiredPapersList]);

	useEffect(() => {
		getPapersList();
	}, [getPapersList]);

	const handlePreviewPaper = useCallback(
		async (paper) => {
			setPaperDetail(paper.values);
			setPaper(paper);
			const formData = paper.form_values;
			setPreviewFormData(formData);
			let templateWithPreviewData;
			if (paper.values?.preview) {
				templateWithPreviewData = paper.values;
			} else {
				const response = await fetchWrapper.get(`/template/${paper.template}/`);
				templateWithPreviewData = response.data;
			}

			const tempPreviewData = generatePreview(
				templateWithPreviewData,
				formData,
				user,
				{
					label: fiscalYear.find(
						(fy) => Number(fy.id) === Number(paper?.fiscal_year || paper?.form_values?.fiscal_year)
					)?.title,
				},
				paper?.extra
			);
			setPreviewData(tempPreviewData);
			setShowPreview(true);
		},
		[user, fiscalYear]
	);

	const handleDeletePaper = () => {
		deletePaper(show[0]);
		papers.filter((val) => val.id === show.id);
		setShow([null, false]);
	};

	const onRenewSuccess = (renewData) => {
		let paper = { ...showRenewModal[3], ...renewData };
		handlePreviewPaper(paper);
		toast.success('यो प्रमाणपत्र सफलतापुर्वक नविकरण गरियो |');
		setShowPreview(true);
		setShowRenewModal([null, false]);
		getPapersList();
	};

	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};
	const printRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => {
			return printRef.current;
		},
		onAfterPrint: async () => {
			try {
				//Need to detect if the print is cancelled, if cancelled do not increment count
				dispatch(incrementPaperCountHandler(paper.id));
			} catch (err) {
				console.log(err, 'error after printing');
			}
		},
	});

	const handlePrintPreview = (id) => {
		const body = {
			_save_and_print: true,
		};
		return fetchWrapper
			.patch(`/paper/${paper.id}/`, body)
			.then((res) => {
				handlePrint();
			})
			.catch((err) => {
				// dispatch(stopUiLoading());
				let errorMessage = err.error?.error?.non_field_error || err.message;
				errorMessage && toast.error(errorMessage[0]);
				return false;
			});
	};

	const handlePaperSearch = () => {
		setExpiredPapersList(page, searchText);
	};

	const handleTypeFilterChange = (e) => {
		const tempFilter = filter.filter((f) => f[0] !== 'type');
		setFilter([...tempFilter, ['type', e.target.value]]);
	};

	const handleFYFilterChange = (e) => {
		const tempFilter = filter.filter((f) => f[0] !== 'fiscal_year');
		setFilter([['fiscal_year', e.target.value], ...tempFilter]);
	};

	const handleTemplateFilterChange = (e) => {
		const tempFilter = filter.filter((f) => f[0] !== 'template');
		setFilter([['template', e.target.value], ...tempFilter]);
	};

	const headers = useMemo(() => {
		// if (papers?.length > 0) {
		return [
			{
				key: 'applicant',
				title: 'संस्था',
				render: (paper) => (
					<Link to={`/applicant/${paper?.id}`}>
						{paper?.applicant_info?.name_np}
					</Link>
				),
			},
			{
				key: 'type',
				title: 'प्रमाण पत्रको प्रकार',
				render: (paper) => paper?.extra?.paper_title,
			},
			{
				key: 'issue_id',
				title: 'इ एन नम्बर',
			},
			{
				key: 'created_at',
				title: 'दर्ता मिती',
				headerStyleOverwrite: css`
					width: 10%;
				`,
				render: (paper) => (
					<>
						<span>
							{language === 'nepali'
								? new NepaliDate(new Date(paper?.created_at)).format(
									'YYYY/MM/DD',
									'np'
								)
								: moment(paper?.created_at).format('YYYY/MM/DD')}
						</span>
					</>
				),
			},
			{
				key: 'darta_number',
				title: 'दर्ता नम्बर',
				headerStyleOverwrite: css`
					width: 10%;
				`,
			},
			{
				key: 'expiry_date_bs',
				title: 'नविकरण मिति',
				headerStyleOverwrite: css`
					width: 10%;
				`,
			},
			{
				key: 'actions',
				title: 'कार्य',
				headerStyleOverwrite: css`
					width: 10%;
				`,
				render: (paper) => (
					<>
						<Button
							css={actionButtonStyleOverwrite}
							className="mr-1"
							onClick={() => handlePreviewPaper(paper)}
						>
							<AiFillEye />
						</Button>
						<Link
							to={`/paper-single/${paper?.id}`}
							css={css`
								color: white;
								:hover {
									color: white;
								}
							`}
						>
							<Button
								css={actionButtonStyleOverwrite}
								className="mr-1"
								variant="secondary"
							>
								<FiEdit />
							</Button>
						</Link>

						<Button
							variant="success"
							css={actionButtonStyleOverwrite}
							onClick={() => {
								setShowRenewModal([
									paper?.id,
									true,
									paper?.extra?.template_data?.isSansthaTemplate, paper
								]);
							}}
						>
							<MdAutorenew />
						</Button>
					</>
				),
			},
		];
		// }
		// return [];
	}, [language, handlePreviewPaper]);

	return (
		<Fragment>
			<Row className="mt-2">
				<Col md={12}>
					<Card
						css={css`
							min-height: 600px;
							border-radius: 8px;
							box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
						`}
					>
						<Card.Header className="card-header-custom px-3">
							<h2>प्रमाण पत्र नविकरण</h2>
							<div className="d-flex align-items-center">
								<div
									className="mr-2 rounded"
									css={css`
										.filter-select {
											height: 2.2rem;
											width: 12vw;
											border-radius: 2px;
											margin-right: 14px;
											outline: 1px solid #ccc;
											border: 0;
											padding: 0 0.5em;
											border-right: 0.5em solid transparent;
										}
									`}
								>

									<select
										className="filter-select"
										onChange={handleFYFilterChange}
									>
										<option value="">सबै आर्थिक वर्ष</option>
										{fiscalYear?.map((fy) => (
											<option key={fy.id} value={fy?.id}>
												{fy?.title}
											</option>
										))}
									</select>

									<select
										className="filter-select"
										onChange={handleTypeFilterChange}
									>
										<option value="">सबै प्रमाण पत्र</option>
										<option value="renewed">नविकरण भएको</option>
										<option value="not-renewed">नविकरण नभएको</option>
										{/* <option value="registered">नयाँ जारी गरिएको</option> */}
									</select>

									<select
										className="filter-select"
										onChange={handleTemplateFilterChange}
									>
										<option value="">प्रमाण पत्र प्रकार</option>
										{templates?.length > 0 &&
											templates?.map((template) => (
												<option key={template?.id} value={template?.id}>
													{template?.title}
												</option>
											))}
									</select>
								</div>
								<div
									className="card-header-form"
									css={css`
										min-width: 260px;
									`}
								>
									<div className="input-group">
										<InputField
											language={language}
											className="form-control custom-search"
											getInputData={(value) => {
												if (value === '') {
													return setExpiredPapersList(
														page,
														null,
														false,
														filter
													);
												}
												setSearchText(value);
											}}
											placeholder={
												language === 'english'
													? 'Please enter Organization Name'
													: 'संस्थाको नाम टाईप गर्नुहोस'
											}
											type="text"
										/>

										<div className="input-group-btn">
											<button
												className="btn btn-primary input-search-icon"
												onClick={handlePaperSearch}
											>
												<FaSearch />
											</button>
										</div>
									</div>
								</div>
							</div>
						</Card.Header>
						<Card.Body className="px-3 pt-0 pb-3 d-flex flex-column justify-content-between">
							<CustomTable
								headers={headers}
								data={papers?.length > 0 ? papers : []}
								isLoading={isLoading}
							/>
							{count > 10 && (
								<PaginationComp
									page={page}
									total={count}
									setPage={handleSetPage}
								/>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Modal show={show[1]} onHide={() => setShow([null, false])}>
				<ModalBody>
					<p className="h5 font-weight-lighter">
						{' '}
						के तपाइँ यो कागज मेटाउन चाहानुहुन्छ?{' '}
					</p>
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-danger" onClick={handleDeletePaper}>
						सुनिश्चित गर्नुहोस्
					</Button>
				</ModalFooter>
			</Modal>

			{showRenewModal[1] && (
				<RenewModal
					showRenewModal={showRenewModal}
					setShowRenewModal={setShowRenewModal}
					onRenewSuccess={onRenewSuccess}
				/>
			)}
			{showPreview && (
				<PreviewModel
					handleClose={() => setShowPreview(false)}
					show={showPreview}
					previewData={previewData}
					previewFormData={previewFormData}
					innerRef={printRef}
					config={paperDetail?.config}
					paperExtra={paper.extra}
					meta={paperDetail?.meta}
					preview={true}
					handlePrint={handlePrintPreview}
					issueId={paper?.issue_id}
					documents={paper?.documents}
					templateHeader={paper?.values?.header}
				/>
			)}
		</Fragment>
	);
};

export default connect(null, {
	setExpiredPapersList,
	deletePaper,
	incrementPaperCountHandler,
})(RenewPapers);

const RenewModal = ({ showRenewModal, setShowRenewModal, onRenewSuccess }) => {
	const { register, setValue, watch, handleSubmit } = useForm();
	const language = useSelector((state) => state.language.language);
	const { fiscalyear, activeFiscalYear } = useSelector(
		(state) => state?.fiscal
	);
	const [loading, setLoading] = useState(false);
	const [renewModalErrors, setRenewModalErrors] = useState({});

	useEffect(() => {
		const npDate = new NepaliDate().format('YYYY/MM/DD', 'np');
		setValue('date', npDate);
		setValue('fiscal_year', activeFiscalYear?.id);
	}, [setValue, activeFiscalYear]);

	const arthikBarsa = watch('fiscal_year');

	useEffect(() => {
		if (arthikBarsa && fiscalyear) {
			const selectedFiscalYear = fiscalyear.find(
				(fs) => fs.id === +arthikBarsa
			);
			if (!selectedFiscalYear) return;
			let endYear = selectedFiscalYear.title.split('/')[1];
			const endDate = new NepaliDate(+`20${endYear}`, 3, 0).getDate();

			setValue(
				'expiry_date',
				nepaliNumberConverter(`20${endYear}/03/${endDate}`)
			);
		}
	}, [arthikBarsa, fiscalyear, setValue, renewModalErrors]);

	const onSubmit = async (data) => {
		// !FIXME Use proper way to validate
		const requiredFields = [
			'fiscal_year',
			'date',
			'expiry_date',
			'renew_charge',
			'renew_bill',
		];
		let body = {
			form_values: data,
			renewed_date: data?.date,
			fiscal_year: data?.fiscal_year,
		};
		let formError = {};
		requiredFields.forEach((field) => {
			let val = data[field];
			if (typeof val === 'string' && val.trim().length === 0) {
				formError[field] = 'यो विवरण आवश्यक छ';
			} else if (!val) {
				formError[field] = 'यो विवरण आवश्यक छ';
			}
		});
		if (Object.keys(formError).length !== 0) {
			setRenewModalErrors(formError);
			return;
		}
		try {
			setLoading(true);
			const response = await fetchWrapper.post(`/paper/${showRenewModal[0]}/renew/`, body);
			onRenewSuccess(response.data.form_values);
			setLoading(false);
		} catch (err) {
			if (err?.error?.error?.non_field_error) {
				err.error?.error?.non_field_error.map((errorMessage) =>
					toast.error(errorMessage)
				);
			}
			setLoading(false);
		}
	};

	const handleFileUpload = async (e, id) => {
		try {
			const formdata = new FormData();
			formdata.append('file', e.target.files[0]);
			const response = await fetchWrapper.post(
				'/add-documents/',
				formdata,
				true
			);
			setValue(id, response?.data?.file);
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<StyledModal
			size="lg"
			show={showRenewModal[1]}
			onHide={() => setShowRenewModal((prev) => [null, false])}
			backdrop="static"
		>
			<Modal.Header>
				<h3>नविकरण विवरण</h3>

				<AiOutlineClose className='cursor-pointer' onClick={() => setShowRenewModal((prev) => [null, false])} />
			</Modal.Header>
			<ModalBody>
				<Row>
					<Col className="form-group" md={4}>
						<Form.Label>आर्थिक वर्ष</Form.Label>
						<Form.Control
							as={'select'}
							{...register('fiscal_year', {
								required: true,
							})}
							defaultValue={activeFiscalYear?.id}
							css={InputFieldStyleOverwrite}
							required
						>
							{fiscalyear?.map((fy) => (
								<option value={fy?.id}>{np(fy?.title)}</option>
							))}
						</Form.Control>
						<small className="text-danger">
							{renewModalErrors.fiscal_year}
						</small>
					</Col>
					<Col className="form-group" md={4}>
						<Form.Label>नविकरण गरिएको मिति</Form.Label>
						<InputField
							type="date"
							className={`textbox_req`}
							language={'nepali'}
							css={InputFieldStyleOverwrite}
							classNameName={`input`}
							elementId={`date`}
							maxLength={50}
							register={register}
							setValue={setValue}
							isRequired={true}
						/>
						<small className="text-danger">{renewModalErrors.date}</small>
					</Col>
					<Col className="form-group" md={4}>
						<Form.Label>नविकरण कायम रहने मिति</Form.Label>
						<InputField
							elementId="expiry_date"
							css={InputFieldStyleOverwrite}
							classNameName={`input`}
							type="date"
							language={language}
							register={register}
							setLoading
							className="w-100"
							isRequired={true}
						/>
						<small className="text-danger">
							{renewModalErrors.expiry_date}
						</small>
					</Col>
					<Col className="form-group" md={4}>
						<Form.Label>नविकरण रकम</Form.Label>
						<InputField
							elementId="renew_charge"
							type="number"
							css={InputFieldStyleOverwrite}
							classNameName={`input`}
							language={language}
							register={register}
							className="w-100"
							isRequired={true}
							validation={{ isRequired: true }}
						/>
						<small className="text-danger">
							{renewModalErrors.renew_charge}
						</small>
					</Col>
					<Col className="form-group" md={4}>
						<Form.Label>नविकरण जरिवाना रकम</Form.Label>
						<InputField
							elementId="renew_fine"
							type="number"
							css={InputFieldStyleOverwrite}
							classNameName={`input`}
							language={language}
							register={register}
							className="w-100"
						/>
					</Col>
					<Col className="form-group" md={4}>
						<Form.Label>नविकरण दस्तुर रसिद नं.</Form.Label>
						<InputField
							elementId="renew_bill"
							type="number"
							css={InputFieldStyleOverwrite}
							classNameName={`input`}
							language={language}
							register={register}
							className="w-100"
						/>
						<small className="text-danger">{renewModalErrors.renew_bill}</small>
					</Col>

					{showRenewModal[2] && (
						<>
							<Col className="form-group" md={4}>
								<Form.Label>कार्यसमितिको निर्णय</Form.Label>
								<Form.Control
									type="file"
									onChange={(e) => handleFileUpload(e, 'executive_decision')}
								/>
								<input type="text" hidden {...register('executive_decision')} />
							</Col>
							<Col className="form-group" md={4}>
								<Form.Label>अडिट रिपोर्ट</Form.Label>
								<Form.Control
									type="file"
									onChange={(e) => handleFileUpload(e, 'audit_report')}
								/>
								<input type="text" hidden {...register('audit_report')} />
							</Col>
							<Col className="form-group" md={4}>
								<Form.Label>साधारण सभा निर्णय</Form.Label>
								<Form.Control
									type="file"
									onChange={(e) => handleFileUpload(e, 'general_decision')}
								/>
								<input type="text" hidden {...register('general_decision')} />
							</Col>
							<Col className="form-group" md={4}>
								<Form.Label>वार्डको सिफारिस</Form.Label>
								<Form.Control
									type="file"
									onChange={(e) => handleFileUpload(e, 'ward_sifaris')}
								/>
								<input type="text" hidden {...register('ward_sifaris')} />
							</Col>
						</>
					)}
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button
					disabled={loading}
					className="btn btn-danger"
					onClick={() => setShowRenewModal([null, false])}
				>
					रद्द गर्नुहोस्
				</Button>
				<Button
					disabled={loading}
					className="btn btn-success"
					onClick={handleSubmit(onSubmit)}
				>
					{loading ? <VscLoading className="spin" /> : 'सुनिश्चित गर्नुहोस्'}
				</Button>
			</ModalFooter>
		</StyledModal>
	);
};

const StyledModal = styled(Modal)`
	.col-md-4 {
		margin-bottom: 1rem !important;
	}
`;
