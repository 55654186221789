import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiMessage } from "./ui";

export const initialState = {
  loading: false,
  hasErrors: false,
  mayors: [],
  count: 0,
};

// A slice
const mayorsSlice = createSlice({
  name: "mayors",
  initialState,
  reducers: {
    getMayors: (state, action) => {
      state.mayors = action.payload.data.results;
      state.count = action.payload.count;
    },

    addMayor: (state, action) => {
      state.mayors = [action.payload, ...state.mayors];
      state.count = state.count + 1;
    },

    updateMayor: (state, action) => {
      const mayors = [...state.mayors];

      const editedIndex = mayors.findIndex(
        (mayor) => mayor.id === action.payload.id
      );

      if (editedIndex !== -1) {
        mayors[editedIndex] = action.payload;
      }
      state.mayors = mayors;
    },
    removeMayor: (state, action) => {
      const mayors = [...state.mayors];

      const editedIndex = mayors.findIndex(
        (mayor) => mayor.id === action.payload
      );
      mayors.splice(editedIndex, 1);
      state.mayors = mayors;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Actions generated from the slice
const { getMayors, addMayor, updateMayor, removeMayor, setLoading } =
  mayorsSlice.actions;

// export user selector to get the slice in any component
export const mayorsSelector = (state) => state.mayors;

// export The reducer
const mayorsReducer = mayorsSlice.reducer;

export default mayorsReducer;

// Actions
export const fetchMayors = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const mayors = await fetchWrapper.get("/mayor/");
    dispatch(getMayors(mayors));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const createMayor = (data, setShowAdd) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const mayor = await fetchWrapper.post("/mayor/", data);
    dispatch(addMayor(mayor.data));
    setShowAdd((prev) => !prev);
    dispatch(setUiMessage(mayor.message));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const editMayor =
  (data, setShowAdd, setDocumentDetail) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const mayor = await fetchWrapper.patch(`/mayor/${data.id}/`, data);

      dispatch(updateMayor(mayor.data));
      setShowAdd((prev) => !prev);
      setDocumentDetail(null);
      dispatch(setUiMessage(mayor.message));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

export const deleteMayor = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const mayor = await fetchWrapper.delete(`/mayor/${id}/`);
    dispatch(removeMayor(id));
    dispatch(setUiMessage(mayor.message));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
  }
};
