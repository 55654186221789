/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment } from 'react';
import { Button, Card, Col, Form } from 'react-bootstrap';
import { FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { patchUser } from '../../../_redux/slices/users';
import { connect } from 'react-redux';
import { englishToNepaliNumber as np, nepaliToEnglishNumber as en } from 'nepali-number';
import { nepaliNumberConverter } from '../../../_helpers/methods';
import InputField from '../../../components/InputField';

const Profile = ({ patchUser }) => {
	const user = useSelector((state) => state.users.user);
	const language = useSelector((state) => state.language.language);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			fullname_en: user?.fullname_en || user?.fullname,
			fullname_np: user?.fullname_np,
			email: user?.email,
			phone_np: np(user?.phone),
			phone_en: en(user?.phone),
		},
	});
	const onEdit = (data) => {
		data.phone_np = nepaliNumberConverter(data.phone_en);
		// if (user?.role === 'user') {
		// 	let body = { ...data, username: data?.email };

		// 	patchWardUser(body, user?.id);
		// 	reset();
		// } else if (user?.role === 'ito_admin') {
		// 	let body = {
		// 		...data,
		// 		phone: nepaliNumberConverter(data.phone_en),
		// 		fullname: data.fullname_en,
		// 		email: data?.email,
		// 		username: data?.username,
		// 	};
		// 	patchITOAdminUser(body, user?.id);
		// 	reset();
		// }
		patchUser(data);
		reset();
	};

	return (
		<Fragment>
			<Card
				css={css`
					border-radius: 8px;
					box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
				`}
				className="mt-2"
			>
				<Card.Body
					css={css`
						padding: 2rem 0rem;
						display: flex;
						height: 80vh;
					`}
				>
					<Col
						md={6}
						css={css`
							border-right: 3px solid #ccc;
						`}
					>
						<Col md={8} className="mx-auto">
							<div
								css={css`
									max-width: 150px;
									margin: auto;
								`}
							>
								<img
									src={user?.img || '/assets/images/avatar.png'}
									alt={user?.username}
									css={css`
										max-width: 100%;
										aspect-ratio: 1 / 1;
										border-radius: 50%;
										border: 2px solid #333;
									`}
								/>
							</div>
							<div className="text-center py-3">
								<h2
									css={css`
										color: #000;
									`}
								>
									{user?.fullname || user?.fullname_np}
								</h2>
								{user?.username && (
									<p
										css={css`
											color: #6b6969;
										`}
									>
										{user?.username}
									</p>
								)}
								<p className="text-primary">{user?.post_np || user?.role}</p>
								<p className="text-secondary">
									<FaPhoneAlt
										css={css`
											margin-right: 0.2rem;
										`}
									/>{' '}
									{user?.phone || user?.phone_np}
								</p>
								{user.address && (
									<p className="text-secondary ml-4">
										<FaMapMarkerAlt
											css={css`
												margin-right: 0.2rem;
											`}
										/>{' '}
										{user?.address}
									</p>
								)}
							</div>
							<hr
								css={css`
									border-top-color: #ccc !important;
								`}
							/>

							<div className="mt-4 text-center">
								{/* <p>नगरपालिका</p> */}
								<p
									className="text-primary"
									css={css`
										font-size: 1.3rem;
									`}
								>
									{user.nagarpalika_info?.nagarpalika_name_np}
								</p>
								<p
									className="text-secondary mb-2"
								//   css={css`
								//     font-size: 1.3rem;
								//   `}
								>
									{user.nagarpalika_info?.nagarpalika_slogan_np}
								</p>
								<p className="text-secondary">
									<FaMapMarkerAlt
										css={css`
											margin-right: 0.2rem;
										`}
									/>{' '}
									{user.nagarpalika_info?.nagarpalika_address_np}
								</p>
								<p className="text-secondary">
									<FaPhoneAlt
										css={css`
											margin-right: 0.2rem;
										`}
									/>{' '}
									{user.nagarpalika_info?.nagarpalika_phone_np}
								</p>
							</div>
						</Col>
					</Col>
					<Col md={6}>
						<div className="col col-8 mx-auto d-flex flex-column h-100">
							<Form.Group>
								<Form.Label htmlFor="fullname_en">Full Name:</Form.Label>
								<Form.Control id="fullname_en" type="text" {...register('fullname_en')} />
							</Form.Group>

							<Form.Group>
								<Form.Label htmlFor="fullname_np">Full Name(Nepali):</Form.Label>
								
								<InputField
									language={language}
									className="form-control custom-search"
									type="text"
									register={register}
									elementId="fullname_np"
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label htmlFor="email">Email</Form.Label>
								<Form.Control id="email" type="email" {...register('email')} />
							</Form.Group>
							<Form.Group>
								<Form.Label htmlFor="phone_en">Phone:</Form.Label>
								<Form.Control id="phone_en" type="text" {...register('phone_en')} maxLength={10} />
							</Form.Group>
							<Button variant="primary" type="submit" className="w-100" onClick={handleSubmit(onEdit)}>
								Update
							</Button>
						</div>
					</Col>
				</Card.Body>
			</Card>
		</Fragment>
	);
};

export default connect(null, { patchUser })(Profile);
