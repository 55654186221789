import NepaliDate from 'nepali-date-converter';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, FormControl, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { FaCoins, FaEye } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchWrapper } from '../_helpers/fetchWrapper';
import { fetchWards } from '../_redux/slices/ward';
import { fetchFiscalYear } from '../_redux/slices/fiscalyear';
import { Link } from 'react-router-dom';
import PaginationComp from '../components/Pagination';
import { englishToNepaliNumber, englishToNepaliNumber as np } from 'nepali-number';
import { BsDownload } from 'react-icons/bs';
import { RiStackFill } from 'react-icons/ri';
import { GiPapers } from 'react-icons/gi';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import 'nepali-datepicker-reactjs/dist/index.css';
import { VscLoading } from 'react-icons/vsc';
import moment from 'moment';
import NabikaranReport from '../components/@Report/NabikaranReport';
import { getDateYMD_BS, getDateYMD_AD, nepaliNumberConverter } from '../_helpers/methods';

export const Box = ({ title, icon = null, number }) => {
	return (
		<div className="dashboard-stats-box">
			<div className="dashboard-stats-box-content">
				<h5>{title}</h5>
			</div>
			<h5 className="dashboard-number">
				<kbd className="red-shade">{number}</kbd>
			</h5>
			<span>
				<div className="dashboard-stats-box-icon">{icon}</div>
			</span>
		</div>
	);
};

function Report({ fetchWards, fetchFiscalYear }) {

	const [key, setKey] = useState('jari');

	const [isLoading, setIsLoading] = useState(false);
	const [papers, setPapers] = useState({});
	const [stats, setStats] = useState();
	const user = useSelector((state) => state.users.user);
	const templatesCategory = useSelector(
		(state) => state?.templateCategories?.templatesCategory
	);

	const fiscalYear = useSelector((state) => state.fiscal.fiscalyear);
	const activeFiscalYear = useSelector((state) => state.fiscal.activeFiscalYear);
	const [page, setPage] = useState(1);
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [filter, setFilter] = useState({
		fy: activeFiscalYear?.id,
		ward: null,
		category: null,
		from: { ad: getDateYMD_AD(1), bs: getDateYMD_BS(1) },
		to: { ad: getDateYMD_AD(), bs: getDateYMD_BS() },
	});

	const [toDateError, setToDateError] = useState(false);
	const [fromDateError, setFromDateError] = useState(false);

	useEffect(() => {
		fetchFiscalYear();
		fetchWards();
	}, [fetchFiscalYear, fetchWards]);

	const getPapers = useCallback(
		async (page) => {
			if (!activeFiscalYear?.id) return;
			try {
				let uri = `/report/list/?page=${page}`;
				if (filter.fy) {
					uri += `&fiscal_year=${filter.fy}`;
				}
				else {
					uri += `&fiscal_year=${activeFiscalYear?.id}`;
				}
				if (filter.ward) {
					uri += `&ward=${filter.ward}`;
				}
				if (filter.category) {
					uri += `&template=${filter.category}`;
				}
				if (filter.from.ad) {
					uri += `&from=${filter.from.ad}`;
				}
				if (filter.to.ad) {
					uri += `&to=${filter.to.ad}`;
				}
				setIsLoading(true);
				const res = await fetchWrapper.get(uri);
				setPapers(res);
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		},
		[activeFiscalYear?.id, filter.fy, filter.ward, filter.category, filter.from.ad, filter.to.ad]
	);

	const getData = useCallback(() => {
		let uri = `/report/stats/?page=${page}`;
		if (filter.fy) {
			uri += `&fiscal_year=${filter.fy}`;
		}
		if (filter.ward) {
			uri += `&ward=${filter.ward}`;
		}
		fetchWrapper
			.get(uri)
			.then((res) => setStats(res.data))
			.catch((err) => console.log(err));
	}, [page, filter.fy, filter.ward]);


	useEffect(() => {
		getPapers(page);
		getData();
	}, [getData, getPapers, page]);

	useEffect(() => {
		setPage(1);
	}, [filter.from.ad, filter.to.ad]);


	const handleDownloadFile = async (type = 'pdf') => {
		setDownloadLoading(type);
		let uri = `/report/list/?type=${type}`;
		if (filter.fy) {
			uri += `&fiscal_year=${filter.fy}`;
		}
		if (filter.ward) {
			uri += `&ward=${filter.ward}`;
		}
		if (filter.category) {
			uri += `&template=${filter.category}`;
		}
		if (filter.from.ad) {
			uri += `&from=${filter.from.ad}`;
		}
		if (filter.to.ad) {
			uri += `&to=${filter.to.ad}`;
		}
		const res = await fetchWrapper.get(uri);
		const a = document.createElement('a');
		a.setAttribute('target', '_blank');
		a.href = res?.data?.report;
		a.download = '';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		setDownloadLoading(false);
	};

	return (
		<Tabs
			id="controlled-tab-example"
			activeKey={key}
			onSelect={(k) => setKey(k)}
			className="mb-3 justify-content-start"
		>

			<Tab eventKey="jari" title="जारी भएका प्रमाण पत्र">
				<div className="container-fluid">
					<Row className="justify-content-between">
						<Col md={4} className="p-0">
							<FormControl as={'select'} onChange={(e) => setFilter({ ...filter, category: e.target.value })}>
								<option value="">प्रमाण पत्रको प्रकार छान्नुहोस्</option>
								{templatesCategory.filter(category => ['प्रमाण पत्रहरु'].includes(category.title)).map((categoryType) => (
									categoryType.templates.map(template => <option value={template.id} key={template.id}>
										{template.title}
									</option>)
								))}
							</FormControl>
						</Col>

						<Col md={4}>
							<FormControl as={'select'} onChange={(e) => setFilter({ ...filter, fy: e.target.value })}>
								<option selected disabled>आर्थिक वर्ष छान्नुहोस्</option>
								{fiscalYear.map((fy) => (
									<option value={fy.id} key={fy.id} selected={activeFiscalYear?.id === fy.id}>
										{fy.title}
									</option>
								))}
							</FormControl>
						</Col>
						<Col md={4} className="d-flex justify-content-end">
							<Button variant="outline-primary" onClick={() => handleDownloadFile('excel')}
								className="ml-2 d-flex justify-content-center align-items-center"
								disabled={downloadLoading === "excel"}>
								{
									downloadLoading === "excel" ?
										<VscLoading className='spin' />
										:
										<Fragment>
											<BsDownload />
											&nbsp;Overall report (Excel)
										</Fragment>
								}
							</Button>
						</Col>
					</Row>
					<Row className="my-3">
						{/* <div className="col-3">
					<Box title="निवेदकहरु" number={np(stats?.applicant || 0)} icon={<BsFillPeopleFill />} />
				</div> */}
						<div className="col-4 px-2">
							<Link to="/templates/1">
								<Box title="प्रमाण पत्रका ढाँचा" number={np(stats?.templates || 0)} icon={<RiStackFill />} />
							</Link>
						</div>
						<div className="col-4 px-2">
							<Link to="/papers/verified/1">
								<Box title="जारी प्रमाण पत्र" number={np(stats?.papers || 0)} icon={<GiPapers />} />
							</Link>
						</div>
						<div className="col-4 px-2">
							<Box title="जम्मा रकम" number={`रु. ${np(stats?.worth || 0)} /-`} icon={<FaCoins />} />
						</div>
					</Row>
					<Row className="px-2">
						<Card className="p-4" style={{ borderRadius: '8px', boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)' }}>
							<div className="d-flex justify-content-between mb-2 align-items-center">
								<Card.Title>
									<h4 className="text-primary">जारी भएका प्रमाण पत्र</h4>
								</Card.Title>
								<div className="d-flex justify-content-center align-items-center">
									<div className="d-flex">
										<div className="d-flex flex-column">
											<div className="d-flex align-items-center">
												<label htmlFor="from" className="w-auto mr-2 mb-0">
													From:{' '}
												</label>
												<NepaliDatePicker
													inputClassName="form-control"
													className=""
													value={filter.from.bs}
													onChange={(value) => {
														if (value && value !== filter.from.bs) {
															try {
																let { year, month, date } = new NepaliDate(value).getAD();
																let today = moment().format('YYYY-MM-DD');
																let selectedAD = `${year}-${('0' + (month + 1)).slice(-2)}-${date}`;
																if (moment(selectedAD).isAfter(today, 'day') && filter.from.bs) {
																	setFromDateError('चयन गरिएको मिति आजको मिति भन्दा ठूलो हुनु हुँदैन');
																} else {
																	setFromDateError(false);
																	setFilter({
																		...filter,
																		from: {
																			ad: `${year}-${('0' + (month + 1)).slice(-2)}-${date}`,
																			bs: value,
																		},
																	});
																}
															} catch (error) {
																console.log(error);
															}
														}
													}}
													options={{ calenderLocale: 'ne', valueLocale: 'en' }}
												/>
											</div>
											{
												fromDateError &&
												<span className="p-2 text-danger">{fromDateError}</span>
											}
										</div>

										<div className="d-flex flex-column">
											<div className="ml-4 d-flex align-items-center justify-content-start">
												<label className="w-auto mr-2 mb-0">To: </label>
												<NepaliDatePicker
													inputClassName="form-control"
													className=""
													value={filter.to.bs}
													onChange={(value) => {
														if (value && value !== filter.to.bs) {
															try {
																let { year, month, date } = new NepaliDate(value).getAD();
																let today = moment().format('YYYY-MM-DD');
																let selectedAD = `${year}-${('0' + (month + 1)).slice(-2)}-${date}`;

																if (moment(selectedAD).isAfter(today, 'day')) {
																	setToDateError('चयन गरिएको मिति आजको मिति भन्दा ठूलो हुनु हुँदैन');
																} else if (filter?.from?.ad) {
																	if (moment(selectedAD).isBefore(filter?.from?.ad, 'day')) {
																		setToDateError('चयन गरिएको मिति "From" मिति भन्दा सानो हुनु हुँदैन');
																	} else {
																		setToDateError(false);
																		setFilter({
																			...filter,
																			to: {
																				ad: `${year}-${('0' + (month + 1)).slice(-2)}-${date}`,
																				bs: value,
																			},
																		});
																	}
																} else {
																	setToDateError(false);
																	setFilter({
																		...filter,
																		to: {
																			ad: `${year}-${('0' + (month + 1)).slice(-2)}-${date}`,
																			bs: value,
																		},
																	});
																}
															} catch (error) {
																console.log(error);
															}
														}
													}}
													options={{ calenderLocale: 'ne', valueLocale: 'en' }}
												/>
											</div>
											{toDateError &&
												<span className="p-2 text-danger">{toDateError}</span>
											}
										</div>
									</div>
									<div className='ml-2 mr-3'>{papers?.count > 0 && <span>कुल संख्या: {englishToNepaliNumber(papers.count)}</span>}
									</div>

									<div className="">
										<Button
											variant="outline-primary"
											className="ml-2 d-flex justify-content-center align-items-center"
											onClick={() => handleDownloadFile()}
											disabled={downloadLoading === 'pdf'}
										>
											{
												downloadLoading === 'pdf' ?
													<VscLoading className='spin' />
													:
													<Fragment>
														<BsDownload />
														&nbsp;PDF
													</Fragment>
											}
										</Button>
									</div>
								</div>
							</div>
							<Table size="sm" hover>
								<thead>
									<tr>
										<th>क्र.सं</th>
										<th>दर्ता न.</th>
										<th>फर्मको नाम:</th>
										<th>फर्मको ठेगाना:</th>
										<th>पछिल्लो नविकरण मिति:</th>
										<th>व्यवसायीको नाम:</th>
										<th>समपर्क न‌.:</th>
										<th>प्रमाण पत्रको प्रकार:</th>
									</tr>
								</thead>
								<tbody>
									{isLoading ? (
										<tr>
											<td colSpan={10} className="text-center py-4">
												<VscLoading fontSize="34" className="spin" />
											</td>
										</tr>
									) : papers?.data?.length < 1 ? (
										<tr>
											<td colSpan={10} className="text-center p-4">
												No Data Found
											</td>
										</tr>
									) : (
										papers?.data?.map((paper, i) => {
											return (
												<tr key={paper.id}>
													<td>{(page - 1) * 10 + i + 1}</td>
													<TableTD value={nepaliNumberConverter(paper.darta_number)} />
													<TableTD value={paper.applicant_name} />
													<TableTD value={paper.applicant_address} />
													<TableTD value={nepaliNumberConverter(paper.renewed_date)} />
													<TableTD value={paper.owners_name} />
													<TableTD value={paper.phone} />
													<TableTD value={paper.template_category} />
												</tr>
											);
										})
									)}
								</tbody>
							</Table>
						</Card>
					</Row>
				</div>
			</Tab>

			<Tab eventKey="renew" title="प्रमाण पत्र नविकरण">
				<NabikaranReport />
			</Tab>
		</Tabs>
	);
}

const TableTD = ({ value }) => {
	return <td>{value ? value : "-"}</td>
}

export default connect(null, { fetchWards, fetchFiscalYear })(Report);
