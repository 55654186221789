/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment } from 'react';
import { useSelector } from "react-redux";

export const NagarpalikaDetail = ({ dark }) => {
    const nagarpalika = useSelector((state) => state.nagarpalika.nagarpalika);

    return (
        <div className="d-flex align-items-center w-100 border-bottom pb-2 mb-3">
            <img
                src="/assets/images/nep_logo.png"
                css={css`
            width: 80px;
            margin-right: 10px;
          `}
                alt=""
            />

            <div className="d-flex flex-column align-items-center text-left">
                <h4 className={`pb-0 ${dark ? "brand_primary" : "text-white"}  w-100`}>
                    संघ संस्था दर्ता प्रणाली{" "}
                </h4>
                <h6 className={`${dark ? "brand_primary" : "text-white"} w-100`}>
                    {nagarpalika?.name_np ? (
                        <Fragment>{nagarpalika?.name_np}</Fragment>
                    ) : (
                        ""
                    )}
                </h6>
                {/* <p className={`${dark ? "brand_primary" : "text-white"} text-small`}>
                    प्रविधिमुखी सार्वजनिक सेवा प्रवाहको सुनिश्चितताको प्रयास
                </p> */}
            </div>
        </div>
    );
};
