/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
// eslint-disable-next-line
import React, { useState, useRef, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useReactToPrint } from "react-to-print";

import Elements from "./Elements";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { Button, Card, Modal } from "react-bootstrap";
import PreviewModel from "./PreviewModel";

import "./index.css";
import { connect, useDispatch, useSelector } from "react-redux";

import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiMessage } from "../../_redux/slices/ui";
import { languageSelector } from "../../_redux/slices/languages";
import { setConvertedDates } from "../../_redux/slices/formTemplates";
import { generatePreview, nepaliNumberConverter } from "../../_helpers/methods";
import { incrementPaperCountHandler } from "../../_redux/slices/paper";
import { VscLoading } from "react-icons/vsc";
import { apiKey } from "../../constant";
import OldPaperFormat from "./Elements/OldPaperFormat/OldPaperFormat";
import Number from "./Elements/_new/Number";
import { fetchFiscalYear } from "../../_redux/slices/fiscalyear";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

function FormElements(props) {
  const templateWithPreviewData = props.data;
  const paperData = props.singleValue;
  const formInputValues = props.vals;
  const isPublic = props.isPublic;

  const { language } = useSelector(languageSelector);
  const fiscalyear = useSelector((state) => state.fiscal.fiscalyear);
  const dispatch = useDispatch();
  const params = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDocs, setShowDocs] = useState(false);
  const [dartaNumber] = useState("");
  const [isSaved, setIsSaved] = useState(() => {
    if (paperData?.issue_id) {
      return true;
    }
    return false;
  });
  const [issueId, setIssueId] = useState(() => {
    if (paperData?.issue_id) {
      return paperData?.issue_id;
    }
  });

  const [files, setFiles] = useState({});
  const [wardInfo, setWardInfo] = useState([]);
  const [province, setprovince] = useState(formInputValues?.province);
  const [district, setDistrict] = useState(formInputValues?.district);
  const [palika, setPalika] = useState(formInputValues?.palika);
  const [show, setShow] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [previewFormData, setPreviewFormData] = useState({});
  const user = useSelector((state) => state.users.user);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const activeFiscalYear = useSelector(
    (state) => state.fiscal.activeFiscalYear
  );
  const [fiscal_year, setFiscal_Year] = useState({ value: null, label: null });
  const [wardAdmin, setWardAdmin] = useState(false);
  const {
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      dispatch(fetchFiscalYear());
    } catch (error) {}
  }, [dispatch]);

  const handleIsSaved = (value) => {
    setIsSaved(value);
  };
  const handleIssueId = (value) => {
    setIssueId(value);
  };

  const history = useHistory();

  const handleFileLoad = (title, file) => {
    let tempFiles = { ...files };
    tempFiles[title] = file;
    setFiles(tempFiles);
  };

  useEffect(() => {
    if (formInputValues) {
      const selectedFiscalYearId = formInputValues?.fiscal_year;
      const selectedFiscalYear = fiscalyear.find(
        (fy) => fy.id === formInputValues?.fiscal_year
      );

      setFiscal_Year({
        value: selectedFiscalYearId,
        label: nepaliNumberConverter(selectedFiscalYear?.title),
      });
    } else {
      setFiscal_Year({
        value: activeFiscalYear?.id,
        label: nepaliNumberConverter(activeFiscalYear?.title),
      });
    }
  }, [activeFiscalYear, fiscalyear, formInputValues]);

  useEffect(() => {
    const getWardInfo = async () => {
      try {
        let response = await fetchWrapper.get("/public/all-ward/", false, true);
        setWardInfo(response.data);
      } catch (err) {}
    };
    if (isPublic) {
      getWardInfo();
    }
  }, [isPublic]);
  const onSubmit = async (formData) => {
    let FORMDATA = {
      ...formInputValues,
      ...formData,
    };
    setPreviewFormData(FORMDATA);
    if (isPublic) {
      const requiredFiles = Object.values(files);
      if (getValues("ward") === "वडा छान्नु होस्") {
        toast.error("वडा छान्नु होस्");
        return null;
      }
      if (
        templateWithPreviewData.documents.length &&
        (requiredFiles.length === 0 || requiredFiles.includes(undefined))
      ) {
        toast.error("add the required Documents");
        return null;
      }
      handleTemplateSave(FORMDATA);
    } else {
      setWardAdmin(formData?.ward_admin);
      if (templateWithPreviewData?.preview) {
        const tempPreviewData = generatePreview(
          templateWithPreviewData,
          FORMDATA,
          user,
          fiscal_year
        );
        setPreviewData(tempPreviewData);
      }
      handleShow();
    }
  };

  const handleSelectProvince = (provinceVal) => {
    setprovince(provinceVal);
  };

  const handleSelectDistrict = (districtVal) => {
    setDistrict(districtVal);
  };

  const handleSelectPalika = (palikaVal) => {
    setPalika(palikaVal);
  };
  const [savedPaperId, setSavedPaperId] = useState(null);
  const [isPrint, setIsPrint] = useState(false);
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => {
      return printRef.current;
    },
    onAfterPrint: () => {
      try {
        setIsPrint(true);
        dispatch(incrementPaperCountHandler(params.paperId));
      } catch (err) {
        console.log(err, "Error after print");
      }
    },
  });
  useEffect(() => {
    if (isPrint && savedPaperId) {
      dispatch(incrementPaperCountHandler(savedPaperId));
      setIsPrint(false);
    }
  }, [isPrint, savedPaperId, dispatch]);

  const handleTemplateSave = async (
    val,
    preview,
    isFromSaveAndPrint = false
  ) => {
    // check preview from handleTemplateSave
    const body = {
      values: templateWithPreviewData,
      form_values: { ...val, fiscal_year: fiscal_year.value },
      invoice_detail: val.invoice_detail,
      fiscal_year: fiscal_year.value,
      applicant_info: {
        name_np: val?.name_np,
        name_en: val?.name_en,
        email: val?.email,
        phone: val?.phone,
      },
    };
    if (val?.address) {
      body.applicant_info.address = val?.address;
    }
    // if (val?.ward) {
    // 	body.applicant_info.ward = val?.ward;
    // }
    if (val?.pan) {
      body.applicant_info.pan = val?.pan;
    }
    if (val?.established_date) {
      body.applicant_info.established_date = val?.established_date;
    }
    if (val.charge) {
      body.charge = val.charge;
    }
    if (val.reg_no) {
      body.reg_no = val.reg_no;
    }
    if (isFromSaveAndPrint) {
      body._save_and_print = true;
    }
    setIsSubmitting(true);
    if (formInputValues) {
      delete body.applicant;
      let response = await fetchWrapper
        .patch(`/paper/${params.paperId}/`, body)
        .then((res) => {
          dispatch(setUiMessage("paper updated!"));
          setIsSubmitting(false);
          return res;
        })
        .catch((err) => {
          setIsSubmitting(false);
          let errorMessage = err.error?.error?.non_field_error || err.message;
          errorMessage && toast.error(errorMessage[0]);
          return false;
        });
      if ((await response) && templateWithPreviewData.documents) {
        let formData = new FormData();
        let keysArray = Object.keys(files);

        for (let i = 0; i < keysArray.length; i++) {
          let documentTitle = keysArray[i];
          let document = files[documentTitle];
          if (document) {
            formData.append("paper", params.paperId);
            formData.append("title", documentTitle);
            formData.append("file", document);
            await fetchWrapper.post("/add-documents/", formData, true);
          }
        }
      }

      return response;
    } else {
      if (isPublic) {
        let paperId;
        body.template = params.id;
        let wardId = val.ward;
        body.ward = wardId;
        let selectedWard = wardInfo.filter((wardI) => wardI.id === +wardId)[0];
        if (templateWithPreviewData.category_title === "English Format") {
          body.form_values.ward_admin = `${selectedWard.fullname_en}_Ward Admin`;
        } else {
          body.form_values.ward_admin = `${selectedWard.fullname_np}_वडा अध्यक्ष`;
        }

        let createPaperResponse = await fetchWrapper
          .post(`/public/create-paper/`, body)
          .then((res) => {
            paperId = res.data.id;
            let token = res.data.token;
            setIsSubmitting(false);
            history.push(`/citizen-template/paper-submitted/${token}`);
            return res;
          })
          .catch((err) => {
            console.log(err, "err");
            // dispatch(stopUiLoading());
            let errorMessage = err.error?.error?.non_field_error || err.message;
            errorMessage && toast.error(errorMessage[0]);
            setIsSubmitting(false);
            return false;
          });
        if ((await createPaperResponse) && templateWithPreviewData.documents) {
          let formData = new FormData();
          let keysArray = Object.keys(files);

          for (let i = 0; i < keysArray.length; i++) {
            let documentTitle = keysArray[i];
            let document = files[documentTitle];
            if (document) {
              formData.append("paper", paperId);
              formData.append("title", documentTitle);
              formData.append("file", document);

              await fetch(
                process.env.REACT_APP_BACKEND_URL + "/public/upload-document/",
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    apikey: apiKey,
                  },
                  body: formData,
                }
              );
            }
          }
        }
        return createPaperResponse;
      }
      body.paper_status = "Approved";

      if (savedPaperId) {
        return fetchWrapper
          .patch(`/paper/${savedPaperId}/`, body)
          .then((res) => {
            dispatch(setUiMessage("paper updated!"));
            setIsSubmitting(false);
            return res;
          })
          .catch((err) => {
            let errorMessage = err.error?.error?.non_field_error || err.message;
            errorMessage && toast.error(errorMessage[0]);
            setIsSubmitting(false);
            return false;
          });
      }
      return await fetchWrapper
        .post(`/template/${params.id}/paper/`, body)
        .then(async (res) => {
          if ((await res) && templateWithPreviewData.documents) {
            let formData = new FormData();
            let keysArray = Object.keys(files);

            for (let i = 0; i < keysArray.length; i++) {
              let documentTitle = keysArray[i];
              let document = files[documentTitle];
              if (document) {
                formData.append("paper", await res.data.id);
                formData.append("title", documentTitle);
                formData.append("file", document);

                await fetchWrapper.post("/add-documents/", formData, true);
              }
            }
          }
          dispatch(setUiMessage("paper saved!"));
          setSavedPaperId(res.data.id);
          setIsSubmitting(false);
          return res;
        })
        .catch((err) => {
          let errorMessage = err.error?.error?.non_field_error || err.message;
          errorMessage && toast.error(errorMessage[0]);
          setIsSubmitting(false);
          return false;
        });
    }
  };

  useEffect(() => {
    setConvertedDates([]);
  }, []);

  console.log(props?.data, "template data");

  return (
    <Card
      css={css`
        margin-top: 0.5rem;
        border-radius: 8px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
        overflow: hidden;
      `}
    >
      <Card.Header>
        <u className="h3"> {templateWithPreviewData.title} </u>
        {isPublic ? (
          <div>
            <div className="float-right">
              <div>
                <label
                  css={css`
                    font-weight: 500;
                  `}
                >
                  शाखा छान्नु होस् :
                </label>
                <select
                  {...register("ward", { required: true })}
                  css={css`
                    width: fit-content;
                  `}
                  defaultValue={formInputValues && formInputValues.ward}
                >
                  <option>शाखा छान्नु होस्</option>
                  {wardInfo?.map((ward) => (
                    <option value={`${ward.id}`} key={`${ward.id}`}>
                      {ward.name_np}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        ) : (
          <Fragment>
            {templateWithPreviewData?.meta?.ward_kawa?.length > 0 ? (
              <div className="float-right">
                {templateWithPreviewData.category_title === "English Format"
                  ? "Act. Officer"
                  : "का.वा. प्रमुख"}{" "}
                <span>:</span>{" "}
                {`${
                  templateWithPreviewData?.meta?.ward_kawa[0][
                    templateWithPreviewData.category_title === "English Format"
                      ? "name_en"
                      : "name_np"
                  ]
                }
                `}
                <input
                  {...register("ward_admin")}
                  value={`${
                    templateWithPreviewData?.meta?.ward_kawa[0][
                      templateWithPreviewData.category_title ===
                      "English Format"
                        ? "name_en"
                        : "name_np"
                    ]
                  }_${
                    templateWithPreviewData?.meta?.ward_kawa[0][
                      templateWithPreviewData.category_title ===
                      "English Format"
                        ? "post_en"
                        : "post_np"
                    ]
                  }`}
                  hidden
                />
              </div>
            ) : (
              templateWithPreviewData?.meta?.ward_admins && (
                <div className="float-right">
                  <label>
                    {templateWithPreviewData.category_title === "English Format"
                      ? "Branch Officer"
                      : "शाखा प्रमुख"}
                  </label>
                  <select
                    {...register("ward_admin")}
                    css={css`
                      width: fit-content;
                    `}
                    defaultValue={formInputValues && formInputValues.ward_admin}
                  >
                    {templateWithPreviewData.meta.ward_admins.map(
                      (ward_admins) => (
                        <option
                          value={`${
                            ward_admins[
                              templateWithPreviewData.category_title ===
                              "English Format"
                                ? "name_en"
                                : "name_np"
                            ]
                          }_${
                            ward_admins[
                              templateWithPreviewData.category_title ===
                              "English Format"
                                ? "post_en"
                                : "post_np"
                            ]
                          }`}
                          key={`${ward_admins.name_np}_${ward_admins.post_np}`}
                        >
                          {
                            ward_admins[
                              templateWithPreviewData.category_title ===
                              "English Format"
                                ? "name_en"
                                : "name_np"
                            ]
                          }
                        </option>
                      )
                    )}
                  </select>
                </div>
              )
            )}
            <div className="float-right d-flex align-items-center justify-content-center">
              <label
                style={{
                  width: "max-content",
                  marginRight: "6px",
                  marginBottom: 0,
                }}
                htmlFor=""
              >
                आर्थिक वर्ष छान्नुहोस:
              </label>
              <select
                value={fiscal_year.value}
                onChange={(e) => {
                  setFiscal_Year({
                    value: e.target.value,
                    label: e.target.selectedOptions[0].innerText,
                  });
                }}
              >
                {fiscalyear?.map((fy) => (
                  <option value={fy.id}>{fy.title}</option>
                ))}
              </select>
            </div>
          </Fragment>
        )}
      </Card.Header>
      <Card.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isPublic ? null : (
            <div
              css={css`
                width: 100%;
                display: flex;
                justify-content: space-between;
                label {
                  width: max-content;
                }
              `}
            >
              <div>
                <Number
                  elementId="charge"
                  register={register}
                  label={
                    templateWithPreviewData?.category_title === "English Format"
                      ? "Charge "
                      : "दस्तुर "
                  }
                  errors={errors}
                  setValue={setValue}
                  language={language}
                  value={paperData?.charge}
                  properties={{ isRequired: true }}
                />
              </div>
              <div>
                <Number
                  elementId="invoiceNo"
                  register={register}
                  label={
                    templateWithPreviewData?.category_title === "English Format"
                      ? "Invoice no. "
                      : "रसिद नं. "
                  }
                  errors={errors}
                  setValue={setValue}
                  language={language}
                  value={
                    paperData?.invoiceNo || paperData?.form_values?.invoiceNo
                  }
                  properties={{ isRequired: true }}
                />
              </div>
            </div>
          )}
          <div>
            {!templateWithPreviewData?.config?.isNewFormat ? (
              <OldPaperFormat
                language={language}
                province={province}
                handleSelectProvince={handleSelectProvince}
                district={district}
                handleSelectDistrict={handleSelectDistrict}
                palika={palika}
                handleSelectPalika={handleSelectPalika}
                printRef={printRef}
                data={templateWithPreviewData}
                register={register}
                setValue={setValue}
                control={control}
              />
            ) : (
              <Elements
                language={language}
                elements={templateWithPreviewData.values}
                register={register}
                errors={errors}
                setValue={setValue}
                control={control}
                values={formInputValues}
                category={templateWithPreviewData.category_title}
                isPublic={isPublic}
              />
            )}
          </div>

          <div
            css={css`
              background: white;
              padding: 1rem;
              border-radius: 10px;
            `}
          >
            <h5
              css={css`
                margin-bottom: 1rem;
              `}
            >
              {isPublic && templateWithPreviewData.documents.length
                ? "आवश्यक कागजात अपलोड गर्नुहोस:"
                : "आवश्यक कागजात (कृपया कागजात अपलोड गर्नुहोस वा अगाडी बढ्नुहोस् |):"}
            </h5>
            <ul>
              {templateWithPreviewData.documents?.length > 0 &&
                templateWithPreviewData.documents.map((document) => {
                  return (
                    <li
                      key={document.id}
                      className="mb-2 d-flex align-items-center "
                    >
                      <p style={{ width: "20vw" }}>{document.title}: </p>
                      <input
                        type="file"
                        accept="image/*,.pdf"
                        onChange={(e) => {
                          setShowDocs(null);
                          e.target.files
                            ? handleFileLoad(document.title, e.target.files[0])
                            : handleFileLoad(document.title, null);
                        }}
                      />
                    </li>
                  );
                })}
            </ul>
            {console.log(showDocs, "showDocs")}
            {showDocs !== null && paperData?.documents?.length > 0 && (
              <div className="">
                {paperData.documents.map((document) => (
                  <span key={document.id} className="text-primary pointer mr-4">
                    <u onClick={() => setShowDocs(document.file)}>
                      {document.title}
                    </u>
                  </span>
                ))}
                <Modal show={showDocs} onHide={() => setShowDocs(false)}>
                  {/* <DocViewer
                    pluginRenderers={DocViewerRenderers}
                    documents={[{ uri: showDocs }]}
                  /> */}
                  <embed
                    width={"400px"}
                    height={"400px"}
                    src={showDocs}
                  ></embed>
                </Modal>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-between">
            {isPublic ? (
              <Button
                variant="primary"
                size="lg"
                disabled={isSubmitting}
                onClick={handleSubmit(onSubmit)}
              >
                {isSubmitting ? <VscLoading className="spin" /> : "Submit"}
              </Button>
            ) : (
              <Fragment>
                <Button
                  variant="primary"
                  size="lg"
                  disabled={isSubmitting}
                  onClick={handleSubmit(onSubmit)}
                >
                  {isSubmitting ? (
                    <VscLoading className="spin" />
                  ) : (
                    "Preview & Print"
                  )}
                </Button>
                {show && (
                  <PreviewModel
                    handleClose={handleClose}
                    show={show}
                    innerRef={printRef}
                    isSaved={isSaved}
                    handleIsSaved={handleIsSaved}
                    issueId={issueId}
                    handleIssueId={handleIssueId}
                    paper={
                      <div className="page">
                        <div className="content">
                          <Elements
                            register={register}
                            language={language}
                            elements={templateWithPreviewData?.values}
                          />
                        </div>
                      </div>
                    }
                    files={files}
                    previewFormData={previewFormData}
                    previewData={previewData}
                    wardAdmin={wardAdmin}
                    paperExtra={{
                      ...props.paperExtra,
                      fiscal_year: fiscal_year,
                    }}
                    handlePrint={handlePrint}
                    handleTemplateSave={handleTemplateSave}
                    config={templateWithPreviewData.config}
                    meta={templateWithPreviewData.meta}
                    paperId={params.paperId}
                    isEnglish={
                      templateWithPreviewData.category_title ===
                      "English Format"
                    }
                    isCitizenTemplate={
                      params?.citizen && params?.citizen === "citizen"
                        ? true
                        : false
                    }
                    documents={paperData?.documents}
                    darta_number={dartaNumber}
                    templateHeader={props?.data?.header}
                  />
                )}
              </Fragment>
            )}
          </div>
        </form>
      </Card.Body>
    </Card>
  );
}

export default connect(null, { setConvertedDates })(FormElements);
